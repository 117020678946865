.q-editor
  border: 1px solid $editor-border-color
  border-radius: $generic-border-radius
  background-color: #fff

  &.disabled
    border-style: dashed

  > div:first-child,
  &__toolbars-container,
  &__toolbars-container > div:first-child
    border-top-left-radius: inherit
    border-top-right-radius: inherit

  &__content
    outline: 0
    padding: $editor-content-padding
    min-height: $editor-content-min-height
    border-bottom-left-radius: inherit
    border-bottom-right-radius: inherit
    overflow: auto
    max-width: 100%

    pre
      white-space: pre-wrap

    hr
      border: 0
      outline: 0
      margin: 1px
      height: 1px
      background: $editor-hr-color

    &:empty:not(:focus):before
      content: attr(placeholder)
      opacity: .7

  &__toolbar
    border-bottom: 1px solid $editor-border-color
    min-height: 32px

  &__toolbars-container
    max-width: 100%

  .q-btn
    margin: $editor-button-gutter

  &__toolbar-group
    position: relative
    margin: 0 $editor-button-gutter
    & + &
      &:before
        content: ''
        position: absolute
        left: (- $editor-button-gutter)
        top: $editor-button-gutter
        bottom: $editor-button-gutter
        width: 1px
        background: $editor-border-color

  &__link-input
    color: inherit
    text-decoration: none
    text-transform: none
    border: none
    border-radius: 0
    background: none
    outline: 0

  &--flat
    &, .q-editor__toolbar
      border: 0

  &--dense
    .q-editor__toolbar-group
      display: flex
      align-items: center
      flex-wrap: nowrap

  &--dark
    border-color: $editor-border-dark-color

    .q-editor

      &__content hr
        background: $editor-hr-dark-color

      &__toolbar
        border-color: $editor-border-dark-color

    .q-editor__toolbar-group + .q-editor__toolbar-group:before
      background: $editor-border-dark-color
