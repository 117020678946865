.q-select
  &--without-input
    .q-field__control
      cursor: pointer
  &--with-input
    .q-field__control
      cursor: text

  .q-field__input
    min-width: 50px !important
    cursor: text

    &--padding
      padding-left: 4px

  &__focus-target,
  &__autocomplete-input
    position: absolute
    outline: 0 !important
    width: 1px // Safari scrolls on top if 0
    height: 1px // Safari scrolls on top if 0
    padding: 0
    border: 0
    opacity: 0

  &__dropdown-icon
    cursor: pointer
    transition: transform .28s

  &.q-field--readonly
    .q-field__control, .q-select__dropdown-icon
      cursor: default

  &__dialog
    width: 90vw !important
    max-width: 90vw !important
    max-height: calc(100vh - 70px) !important
    background: #fff
    display: flex
    flex-direction: column

    > .scroll
      position: relative
      background: inherit

// addressbar always gets shown, so need to slim this down
body.mobile:not(.native-mobile) .q-select__dialog
  max-height: calc(100vh - 108px) !important

body.platform-android

  &.native-mobile .q-dialog__inner--top .q-select__dialog
    max-height: calc(100vh - 24px) !important

  &:not(.native-mobile) .q-dialog__inner--top .q-select__dialog
    max-height: calc(100vh - 80px) !important

body.platform-ios

  &.native-mobile .q-dialog__inner--top
    > div
      border-radius: $generic-border-radius

    .q-select__dialog--focused
      max-height: 47vh !important

  &:not(.native-mobile) .q-dialog__inner--top .q-select__dialog--focused
    max-height: 50vh !important
