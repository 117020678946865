.q-layout
  width: 100%
  outline: 0

.q-layout-container
  position: relative
  width: 100%
  height: 100%

  .q-layout
    min-height: 100%
  > div
    transform: translate3d(0, 0, 0)
    > div
      min-height: 0
      max-height: 100%

.q-layout__shadow
  width: 100%
  &:after
    content: ''
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    box-shadow: $layout-shadow

.q-layout__section--marginal
  background-color: var(--q-primary)
  color: #fff

.q-header

  &--hidden
    transform: translateY(-110%)
  &--bordered
    border-bottom: $layout-border
  .q-layout__shadow
    bottom: -10px
    &:after
      bottom: 10px

.q-footer

  &--hidden
    transform: translateY(110%)
  &--bordered
    border-top: $layout-border
  .q-layout__shadow
    top: -10px
    &:after
      top: 10px

.q-header, .q-footer
  z-index: $z-marginals

.q-drawer
  position: absolute
  top: 0
  bottom: 0
  background: #fff
  z-index: $z-side

  &--on-top
    z-index: $z-fixed-drawer

  &--left
    left: 0
    transform: translateX(-100%)
    &.q-drawer--bordered
      border-right: $layout-border
    .q-layout__shadow
      left: 10px
      right: -10px
      &:after
        right: 10px

  &--right
    right: 0
    transform: translateX(100%)
    &.q-drawer--bordered
      border-left: $layout-border

    .q-layout__shadow
      left: -10px
      &:after
        left: 10px

  &-container:not(&--mini-animate) &--mini
    padding: 0 !important
    .q-item, .q-item__section
      text-align: center
      justify-content: center
      padding-left: 0
      padding-right: 0
      min-width: 0
    .q-item__label, .q-item__section--main, .q-item__section--side ~ .q-item__section--side
      display: none
  &--mini
    .q-mini-drawer-hide, .q-expansion-item__content
      display: none
  &--mini-animate &__content
    overflow-x: hidden !important
    white-space: nowrap
  &--standard
    .q-mini-drawer-only
      display: none
  &--mobile
    .q-mini-drawer-only, .q-mini-drawer-hide
      display: none

  &__backdrop
    z-index: ($z-fixed-drawer - 1) !important
    will-change: background-color

  &__opener
    z-index: ($z-marginals + 1)
    height: 100%
    width: 15px
    user-select: none

.q-layout, .q-header, .q-footer, .q-page
  position: relative

.q-page-sticky--shrink
  pointer-events: none
  > div
    display: inline-block
    pointer-events: auto

body.q-ios-padding
  .q-layout--standard .q-header > .q-toolbar:nth-child(1),
  .q-layout--standard .q-header > .q-tabs:nth-child(1) .q-tabs-head,
  .q-layout--standard .q-drawer--top-padding .q-drawer__content
    padding-top: $ios-statusbar-height
    min-height: ($toolbar-min-height + $ios-statusbar-height)
    padding-top: env(safe-area-inset-top)
    min-height: calc(env(safe-area-inset-top) + #{$toolbar-min-height})
  .q-layout--standard .q-footer > .q-toolbar:last-child,
  .q-layout--standard .q-footer > .q-tabs:last-child .q-tabs-head,
  .q-layout--standard .q-drawer--top-padding .q-drawer__content
    padding-bottom: env(safe-area-inset-bottom)
    min-height: calc(env(safe-area-inset-bottom) + #{$toolbar-min-height})

.q-body--layout-animate
  .q-drawer__backdrop
    transition: background-color .12s !important
  .q-drawer
    transition: transform .12s, width .12s, top .12s, bottom .12s !important
  .q-layout__section--marginal
    transition: transform .12s, left .12s, right .12s !important
  .q-page-container
    transition: padding-top .12s, padding-right .12s, padding-bottom .12s, padding-left .12s !important
  .q-page-sticky
    transition: transform .12s, left .12s, right .12s, top .12s, bottom .12s !important

body:not(.q-body--layout-animate)
  .q-layout--prevent-focus
    visibility: hidden

.q-body--drawer-toggle
  overflow-x: hidden !important

.q-layout-padding
  @media (max-width: $breakpoint-xs-max)
    padding: 8px
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max)
    padding: 16px
  @media (min-width: $breakpoint-lg-min)
    padding: 24px

body.body--dark
  .q-header, .q-footer, .q-drawer
    border-color: $separator-dark-color
  .q-layout__shadow:after
    box-shadow: $layout-shadow-dark

body.platform-ios
  .q-layout--containerized
    // https://github.com/quasarframework/quasar/issues/4127
    // Fixes scroll issues with containerized Layout inside Dialog on iOS;
    // QDrawer needs to be forced into fixed position
    position: unset !important
