.q-tooltip--style
  font-size: $tooltip-fontsize
  color: $tooltip-color
  background: $tooltip-background
  border-radius: $tooltip-border-radius
  text-transform: none
  font-weight: normal

.q-tooltip
  z-index: $z-tooltip
  position: fixed !important
  overflow-y: auto
  overflow-x: hidden
  padding: $tooltip-padding

  @media (max-width: $breakpoint-xs-max)
    font-size: $tooltip-mobile-fontsize
    padding: $tooltip-mobile-padding
