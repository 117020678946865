.q-linear-progress
  --q-linear-progress-speed: .3s
  position: relative
  width: 100%
  overflow: hidden
  font-size: 4px
  height: 1em
  color: var(--q-primary)
  transform: scale3d(1, 1, 1)

  &__model, &__track
    transform-origin: 0 0

    &--with-transition
      transition: transform var(--q-linear-progress-speed)

  &--reverse
    .q-linear-progress
      &__model, &__track
        transform-origin: 0 100%

  &__model

    &--determinate
      background: currentColor

    &--indeterminate, &--query
      transition: none

      &:before, &:after
        background: currentColor
        content: ''
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        transform-origin: 0 0

      &:before
        animation: q-linear-progress--indeterminate 2.1s cubic-bezier(.65,.815,.735,.395) infinite
      &:after
        transform: translate3d(-101%, 0, 0) scale3d(1, 1, 1)
        animation: q-linear-progress--indeterminate-short 2.1s cubic-bezier(.165,.84,.44,1) infinite
        animation-delay: 1.15s

  &__track
    opacity: .4
    &--light
      background: rgba(0,0,0,.26)
    &--dark
      background: rgba(255,255,255,.6)

  &__stripe
    background-image: linear-gradient(45deg, rgba(255,255,255,.15) 25%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, rgba(255,255,255,0) 75%, rgba(255,255,255,0)) !important
    background-size: 40px 40px !important

    &--with-transition
      transition: width var(--q-linear-progress-speed)

@keyframes q-linear-progress--indeterminate
  0%
    transform: translate3d(-35%, 0, 0) scale3d(.35, 1, 1)
  60%
    transform: translate3d(100%, 0, 0) scale3d(.9, 1, 1)
  100%
    transform: translate3d(100%, 0, 0) scale3d(.9, 1, 1)

@keyframes q-linear-progress--indeterminate-short
  0%
    transform: translate3d(-101%, 0, 0) scale3d(1, 1, 1)
  60%
    transform: translate3d(107%, 0, 0) scale3d(.01, 1, 1)
  100%
    transform: translate3d(107%, 0, 0) scale3d(.01, 1, 1)
