.q-tab
  padding: 0 16px
  min-height: 48px
  transition: color .3s, background-color .3s

  text-transform: uppercase
  white-space: nowrap

  // for route tabs
  color: inherit
  text-decoration: none

  &--full
    min-height: 72px

  &--no-caps
    text-transform: none

  &__content
    height: inherit
    padding: 4px 0
    min-width: 40px

    &--inline
      .q-tab__icon + .q-tab__label
        padding-left: 8px

    .q-chip--floating
      top: 0
      right: -16px

  &__icon
    width: 24px
    height: 24px
    font-size: 24px

  &__label
    font-size: $button-font-size
    line-height: $button-line-height
    font-weight: $button-font-weight

  .q-badge
    top: 3px
    right: -12px

  &__alert, &__alert-icon
    position: absolute

  &__alert
    top: 7px
    right: -9px
    height: 10px
    width: 10px
    border-radius: 50%
    background: currentColor

  &__alert-icon
    top: 2px
    right: -12px
    font-size: 18px

  &__indicator
    opacity: 0
    height: 2px
    background: currentColor

  &--active .q-tab__indicator
    opacity: 1
    transform-origin: left #{"/* rtl:ignore */"}

  &--inactive
    opacity: .85

.q-tabs
  position: relative
  transition: color .3s, background-color .3s

  &--scrollable
    &.q-tabs__arrows--outside
      &.q-tabs--horizontal
        padding-left: 36px
        padding-right: 36px

      &.q-tabs--vertical
        padding-top: 36px
        padding-bottom: 36px

      .q-tabs__arrow--faded
        opacity: 0.3
        pointer-events: none

    &.q-tabs__arrows--inside
      .q-tabs__arrow--faded
        display: none

  &--not-scrollable,
  body.mobile &--scrollable.q-tabs--mobile-without-arrows
    &.q-tabs__arrows--outside
      padding-left: 0
      padding-right: 0
    .q-tabs__arrow
      display: none
    .q-tabs__content
      border-radius: inherit

  &__arrow
    cursor: pointer
    font-size: 32px
    min-width: 36px
    text-shadow: 0 0 3px #fff, 0 0 1px #fff, 0 0 1px #000
    transition: opacity .3s

  &__content
    overflow: hidden
    flex: 1 1 auto

    &--align

      &-center
        justify-content: center

      &-right
        justify-content: flex-end

      &-justify .q-tab
        flex: 1 1 auto

  &__offset
    display: none

  &--horizontal .q-tabs__arrow
    height: 100%

    &--left
      top: 0
      left: 0 #{"/* rtl:ignore */"}
      bottom: 0

    &--right
      top: 0
      right: 0 #{"/* rtl:ignore */"}
      bottom: 0

  &--vertical
    display: block !important
    height: 100%

    .q-tabs__content
      display: block !important
      height: 100%

    .q-tabs__arrow
      width: 100%
      height: 36px
      text-align: center

      &--left
        top: 0
        left: 0
        right: 0

      &--right
        left: 0
        right: 0
        bottom: 0

    .q-tab
      padding: 0 8px

    .q-tab__indicator
      height: unset
      width: 2px

  &--vertical.q-tabs--not-scrollable
    .q-tabs__content
      height: 100%

  &--vertical.q-tabs--dense
    .q-tab__content
      min-width: 24px


  &--dense
    .q-tab
      min-height: 36px
      &--full
        min-height: 52px
