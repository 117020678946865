.q-pull-to-refresh
  position: relative

  &__puller
    border-radius: 50%
    width: 40px
    height: 40px
    color: var(--q-primary)
    background: #fff
    box-shadow: 0 0 4px 0 rgba(0,0,0,.3)

    &--animating
      transition: transform .3s, opacity .3s
