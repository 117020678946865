\:root
  --q-primary:      #{$primary}
  --q-secondary:    #{$secondary}
  --q-accent:       #{$accent}
  --q-positive:     #{$positive}
  --q-negative:     #{$negative}
  --q-info:         #{$info}
  --q-warning:      #{$warning}
  --q-dark:         #{$dark}
  --q-dark-page:    #{$dark-page}

.text-dark
  color: var(--q-dark) !important
.bg-dark
  background: var(--q-dark) !important

.text-primary
  color: var(--q-primary) !important
.bg-primary
  background: var(--q-primary) !important

.text-secondary
  color: var(--q-secondary) !important
.bg-secondary
  background: var(--q-secondary) !important

.text-accent
  color: var(--q-accent) !important
.bg-accent
  background: var(--q-accent) !important

.text-positive
  color: var(--q-positive) !important
.bg-positive
  background: var(--q-positive) !important

.text-negative
  color: var(--q-negative) !important
.bg-negative
  background: var(--q-negative) !important

.text-info
  color: var(--q-info) !important
.bg-info
  background: var(--q-info) !important

.text-warning
  color: var(--q-warning) !important
.bg-warning
  background: var(--q-warning) !important

.text-white
  color: #fff !important
.bg-white
  background: #fff !important

.text-black
  color: #000 !important
.bg-black
  background: #000 !important

.text-transparent
  color: transparent !important
.bg-transparent
  background: transparent !important

.text-separator
  color: $separator-color !important
.bg-separator
  background: $separator-color !important

.text-dark-separator
  color: $separator-dark-color !important
.bg-dark-separator
  background: $separator-dark-color !important

// @stylint off

.text-red
  color: $red !important

.text-red-1
  color: $red-1 !important

.text-red-2
  color: $red-2 !important

.text-red-3
  color: $red-3 !important

.text-red-4
  color: $red-4 !important

.text-red-5
  color: $red-5 !important

.text-red-6
  color: $red-6 !important

.text-red-7
  color: $red-7 !important

.text-red-8
  color: $red-8 !important

.text-red-9
  color: $red-9 !important

.text-red-10
  color: $red-10 !important

.text-red-11
  color: $red-11 !important

.text-red-12
  color: $red-12 !important

.text-red-13
  color: $red-13 !important

.text-red-14
  color: $red-14 !important

.text-pink
  color: $pink !important

.text-pink-1
  color: $pink-1 !important

.text-pink-2
  color: $pink-2 !important

.text-pink-3
  color: $pink-3 !important

.text-pink-4
  color: $pink-4 !important

.text-pink-5
  color: $pink-5 !important

.text-pink-6
  color: $pink-6 !important

.text-pink-7
  color: $pink-7 !important

.text-pink-8
  color: $pink-8 !important

.text-pink-9
  color: $pink-9 !important

.text-pink-10
  color: $pink-10 !important

.text-pink-11
  color: $pink-11 !important

.text-pink-12
  color: $pink-12 !important

.text-pink-13
  color: $pink-13 !important

.text-pink-14
  color: $pink-14 !important

.text-purple
  color: $purple !important

.text-purple-1
  color: $purple-1 !important

.text-purple-2
  color: $purple-2 !important

.text-purple-3
  color: $purple-3 !important

.text-purple-4
  color: $purple-4 !important

.text-purple-5
  color: $purple-5 !important

.text-purple-6
  color: $purple-6 !important

.text-purple-7
  color: $purple-7 !important

.text-purple-8
  color: $purple-8 !important

.text-purple-9
  color: $purple-9 !important

.text-purple-10
  color: $purple-10 !important

.text-purple-11
  color: $purple-11 !important

.text-purple-12
  color: $purple-12 !important

.text-purple-13
  color: $purple-13 !important

.text-purple-14
  color: $purple-14 !important

.text-deep-purple
  color: $deep-purple !important

.text-deep-purple-1
  color: $deep-purple-1 !important

.text-deep-purple-2
  color: $deep-purple-2 !important

.text-deep-purple-3
  color: $deep-purple-3 !important

.text-deep-purple-4
  color: $deep-purple-4 !important

.text-deep-purple-5
  color: $deep-purple-5 !important

.text-deep-purple-6
  color: $deep-purple-6 !important

.text-deep-purple-7
  color: $deep-purple-7 !important

.text-deep-purple-8
  color: $deep-purple-8 !important

.text-deep-purple-9
  color: $deep-purple-9 !important

.text-deep-purple-10
  color: $deep-purple-10 !important

.text-deep-purple-11
  color: $deep-purple-11 !important

.text-deep-purple-12
  color: $deep-purple-12 !important

.text-deep-purple-13
  color: $deep-purple-13 !important

.text-deep-purple-14
  color: $deep-purple-14 !important

.text-indigo
  color: $indigo !important

.text-indigo-1
  color: $indigo-1 !important

.text-indigo-2
  color: $indigo-2 !important

.text-indigo-3
  color: $indigo-3 !important

.text-indigo-4
  color: $indigo-4 !important

.text-indigo-5
  color: $indigo-5 !important

.text-indigo-6
  color: $indigo-6 !important

.text-indigo-7
  color: $indigo-7 !important

.text-indigo-8
  color: $indigo-8 !important

.text-indigo-9
  color: $indigo-9 !important

.text-indigo-10
  color: $indigo-10 !important

.text-indigo-11
  color: $indigo-11 !important

.text-indigo-12
  color: $indigo-12 !important

.text-indigo-13
  color: $indigo-13 !important

.text-indigo-14
  color: $indigo-14 !important

.text-blue
  color: $blue !important

.text-blue-1
  color: $blue-1 !important

.text-blue-2
  color: $blue-2 !important

.text-blue-3
  color: $blue-3 !important

.text-blue-4
  color: $blue-4 !important

.text-blue-5
  color: $blue-5 !important

.text-blue-6
  color: $blue-6 !important

.text-blue-7
  color: $blue-7 !important

.text-blue-8
  color: $blue-8 !important

.text-blue-9
  color: $blue-9 !important

.text-blue-10
  color: $blue-10 !important

.text-blue-11
  color: $blue-11 !important

.text-blue-12
  color: $blue-12 !important

.text-blue-13
  color: $blue-13 !important

.text-blue-14
  color: $blue-14 !important

.text-light-blue
  color: $light-blue !important

.text-light-blue-1
  color: $light-blue-1 !important

.text-light-blue-2
  color: $light-blue-2 !important

.text-light-blue-3
  color: $light-blue-3 !important

.text-light-blue-4
  color: $light-blue-4 !important

.text-light-blue-5
  color: $light-blue-5 !important

.text-light-blue-6
  color: $light-blue-6 !important

.text-light-blue-7
  color: $light-blue-7 !important

.text-light-blue-8
  color: $light-blue-8 !important

.text-light-blue-9
  color: $light-blue-9 !important

.text-light-blue-10
  color: $light-blue-10 !important

.text-light-blue-11
  color: $light-blue-11 !important

.text-light-blue-12
  color: $light-blue-12 !important

.text-light-blue-13
  color: $light-blue-13 !important

.text-light-blue-14
  color: $light-blue-14 !important

.text-cyan
  color: $cyan !important

.text-cyan-1
  color: $cyan-1 !important

.text-cyan-2
  color: $cyan-2 !important

.text-cyan-3
  color: $cyan-3 !important

.text-cyan-4
  color: $cyan-4 !important

.text-cyan-5
  color: $cyan-5 !important

.text-cyan-6
  color: $cyan-6 !important

.text-cyan-7
  color: $cyan-7 !important

.text-cyan-8
  color: $cyan-8 !important

.text-cyan-9
  color: $cyan-9 !important

.text-cyan-10
  color: $cyan-10 !important

.text-cyan-11
  color: $cyan-11 !important

.text-cyan-12
  color: $cyan-12 !important

.text-cyan-13
  color: $cyan-13 !important

.text-cyan-14
  color: $cyan-14 !important

.text-teal
  color: $teal !important

.text-teal-1
  color: $teal-1 !important

.text-teal-2
  color: $teal-2 !important

.text-teal-3
  color: $teal-3 !important

.text-teal-4
  color: $teal-4 !important

.text-teal-5
  color: $teal-5 !important

.text-teal-6
  color: $teal-6 !important

.text-teal-7
  color: $teal-7 !important

.text-teal-8
  color: $teal-8 !important

.text-teal-9
  color: $teal-9 !important

.text-teal-10
  color: $teal-10 !important

.text-teal-11
  color: $teal-11 !important

.text-teal-12
  color: $teal-12 !important

.text-teal-13
  color: $teal-13 !important

.text-teal-14
  color: $teal-14 !important

.text-green
  color: $green !important

.text-green-1
  color: $green-1 !important

.text-green-2
  color: $green-2 !important

.text-green-3
  color: $green-3 !important

.text-green-4
  color: $green-4 !important

.text-green-5
  color: $green-5 !important

.text-green-6
  color: $green-6 !important

.text-green-7
  color: $green-7 !important

.text-green-8
  color: $green-8 !important

.text-green-9
  color: $green-9 !important

.text-green-10
  color: $green-10 !important

.text-green-11
  color: $green-11 !important

.text-green-12
  color: $green-12 !important

.text-green-13
  color: $green-13 !important

.text-green-14
  color: $green-14 !important

.text-light-green
  color: $light-green !important

.text-light-green-1
  color: $light-green-1 !important

.text-light-green-2
  color: $light-green-2 !important

.text-light-green-3
  color: $light-green-3 !important

.text-light-green-4
  color: $light-green-4 !important

.text-light-green-5
  color: $light-green-5 !important

.text-light-green-6
  color: $light-green-6 !important

.text-light-green-7
  color: $light-green-7 !important

.text-light-green-8
  color: $light-green-8 !important

.text-light-green-9
  color: $light-green-9 !important

.text-light-green-10
  color: $light-green-10 !important

.text-light-green-11
  color: $light-green-11 !important

.text-light-green-12
  color: $light-green-12 !important

.text-light-green-13
  color: $light-green-13 !important

.text-light-green-14
  color: $light-green-14 !important

.text-lime
  color: $lime !important

.text-lime-1
  color: $lime-1 !important

.text-lime-2
  color: $lime-2 !important

.text-lime-3
  color: $lime-3 !important

.text-lime-4
  color: $lime-4 !important

.text-lime-5
  color: $lime-5 !important

.text-lime-6
  color: $lime-6 !important

.text-lime-7
  color: $lime-7 !important

.text-lime-8
  color: $lime-8 !important

.text-lime-9
  color: $lime-9 !important

.text-lime-10
  color: $lime-10 !important

.text-lime-11
  color: $lime-11 !important

.text-lime-12
  color: $lime-12 !important

.text-lime-13
  color: $lime-13 !important

.text-lime-14
  color: $lime-14 !important

.text-yellow
  color: $yellow !important

.text-yellow-1
  color: $yellow-1 !important

.text-yellow-2
  color: $yellow-2 !important

.text-yellow-3
  color: $yellow-3 !important

.text-yellow-4
  color: $yellow-4 !important

.text-yellow-5
  color: $yellow-5 !important

.text-yellow-6
  color: $yellow-6 !important

.text-yellow-7
  color: $yellow-7 !important

.text-yellow-8
  color: $yellow-8 !important

.text-yellow-9
  color: $yellow-9 !important

.text-yellow-10
  color: $yellow-10 !important

.text-yellow-11
  color: $yellow-11 !important

.text-yellow-12
  color: $yellow-12 !important

.text-yellow-13
  color: $yellow-13 !important

.text-yellow-14
  color: $yellow-14 !important

.text-amber
  color: $amber !important

.text-amber-1
  color: $amber-1 !important

.text-amber-2
  color: $amber-2 !important

.text-amber-3
  color: $amber-3 !important

.text-amber-4
  color: $amber-4 !important

.text-amber-5
  color: $amber-5 !important

.text-amber-6
  color: $amber-6 !important

.text-amber-7
  color: $amber-7 !important

.text-amber-8
  color: $amber-8 !important

.text-amber-9
  color: $amber-9 !important

.text-amber-10
  color: $amber-10 !important

.text-amber-11
  color: $amber-11 !important

.text-amber-12
  color: $amber-12 !important

.text-amber-13
  color: $amber-13 !important

.text-amber-14
  color: $amber-14 !important

.text-orange
  color: $orange !important

.text-orange-1
  color: $orange-1 !important

.text-orange-2
  color: $orange-2 !important

.text-orange-3
  color: $orange-3 !important

.text-orange-4
  color: $orange-4 !important

.text-orange-5
  color: $orange-5 !important

.text-orange-6
  color: $orange-6 !important

.text-orange-7
  color: $orange-7 !important

.text-orange-8
  color: $orange-8 !important

.text-orange-9
  color: $orange-9 !important

.text-orange-10
  color: $orange-10 !important

.text-orange-11
  color: $orange-11 !important

.text-orange-12
  color: $orange-12 !important

.text-orange-13
  color: $orange-13 !important

.text-orange-14
  color: $orange-14 !important

.text-deep-orange
  color: $deep-orange !important

.text-deep-orange-1
  color: $deep-orange-1 !important

.text-deep-orange-2
  color: $deep-orange-2 !important

.text-deep-orange-3
  color: $deep-orange-3 !important

.text-deep-orange-4
  color: $deep-orange-4 !important

.text-deep-orange-5
  color: $deep-orange-5 !important

.text-deep-orange-6
  color: $deep-orange-6 !important

.text-deep-orange-7
  color: $deep-orange-7 !important

.text-deep-orange-8
  color: $deep-orange-8 !important

.text-deep-orange-9
  color: $deep-orange-9 !important

.text-deep-orange-10
  color: $deep-orange-10 !important

.text-deep-orange-11
  color: $deep-orange-11 !important

.text-deep-orange-12
  color: $deep-orange-12 !important

.text-deep-orange-13
  color: $deep-orange-13 !important

.text-deep-orange-14
  color: $deep-orange-14 !important

.text-brown
  color: $brown !important

.text-brown-1
  color: $brown-1 !important

.text-brown-2
  color: $brown-2 !important

.text-brown-3
  color: $brown-3 !important

.text-brown-4
  color: $brown-4 !important

.text-brown-5
  color: $brown-5 !important

.text-brown-6
  color: $brown-6 !important

.text-brown-7
  color: $brown-7 !important

.text-brown-8
  color: $brown-8 !important

.text-brown-9
  color: $brown-9 !important

.text-brown-10
  color: $brown-10 !important

.text-brown-11
  color: $brown-11 !important

.text-brown-12
  color: $brown-12 !important

.text-brown-13
  color: $brown-13 !important

.text-brown-14
  color: $brown-14 !important

.text-grey
  color: $grey !important

.text-grey-1
  color: $grey-1 !important

.text-grey-2
  color: $grey-2 !important

.text-grey-3
  color: $grey-3 !important

.text-grey-4
  color: $grey-4 !important

.text-grey-5
  color: $grey-5 !important

.text-grey-6
  color: $grey-6 !important

.text-grey-7
  color: $grey-7 !important

.text-grey-8
  color: $grey-8 !important

.text-grey-9
  color: $grey-9 !important

.text-grey-10
  color: $grey-10 !important

.text-grey-11
  color: $grey-11 !important

.text-grey-12
  color: $grey-12 !important

.text-grey-13
  color: $grey-13 !important

.text-grey-14
  color: $grey-14 !important

.text-blue-grey
  color: $blue-grey !important

.text-blue-grey-1
  color: $blue-grey-1 !important

.text-blue-grey-2
  color: $blue-grey-2 !important

.text-blue-grey-3
  color: $blue-grey-3 !important

.text-blue-grey-4
  color: $blue-grey-4 !important

.text-blue-grey-5
  color: $blue-grey-5 !important

.text-blue-grey-6
  color: $blue-grey-6 !important

.text-blue-grey-7
  color: $blue-grey-7 !important

.text-blue-grey-8
  color: $blue-grey-8 !important

.text-blue-grey-9
  color: $blue-grey-9 !important

.text-blue-grey-10
  color: $blue-grey-10 !important

.text-blue-grey-11
  color: $blue-grey-11 !important

.text-blue-grey-12
  color: $blue-grey-12 !important

.text-blue-grey-13
  color: $blue-grey-13 !important

.text-blue-grey-14
  color: $blue-grey-14 !important


.bg-red
  background: $red !important

.bg-red-1
  background: $red-1 !important

.bg-red-2
  background: $red-2 !important

.bg-red-3
  background: $red-3 !important

.bg-red-4
  background: $red-4 !important

.bg-red-5
  background: $red-5 !important

.bg-red-6
  background: $red-6 !important

.bg-red-7
  background: $red-7 !important

.bg-red-8
  background: $red-8 !important

.bg-red-9
  background: $red-9 !important

.bg-red-10
  background: $red-10 !important

.bg-red-11
  background: $red-11 !important

.bg-red-12
  background: $red-12 !important

.bg-red-13
  background: $red-13 !important

.bg-red-14
  background: $red-14 !important

.bg-pink
  background: $pink !important

.bg-pink-1
  background: $pink-1 !important

.bg-pink-2
  background: $pink-2 !important

.bg-pink-3
  background: $pink-3 !important

.bg-pink-4
  background: $pink-4 !important

.bg-pink-5
  background: $pink-5 !important

.bg-pink-6
  background: $pink-6 !important

.bg-pink-7
  background: $pink-7 !important

.bg-pink-8
  background: $pink-8 !important

.bg-pink-9
  background: $pink-9 !important

.bg-pink-10
  background: $pink-10 !important

.bg-pink-11
  background: $pink-11 !important

.bg-pink-12
  background: $pink-12 !important

.bg-pink-13
  background: $pink-13 !important

.bg-pink-14
  background: $pink-14 !important

.bg-purple
  background: $purple !important

.bg-purple-1
  background: $purple-1 !important

.bg-purple-2
  background: $purple-2 !important

.bg-purple-3
  background: $purple-3 !important

.bg-purple-4
  background: $purple-4 !important

.bg-purple-5
  background: $purple-5 !important

.bg-purple-6
  background: $purple-6 !important

.bg-purple-7
  background: $purple-7 !important

.bg-purple-8
  background: $purple-8 !important

.bg-purple-9
  background: $purple-9 !important

.bg-purple-10
  background: $purple-10 !important

.bg-purple-11
  background: $purple-11 !important

.bg-purple-12
  background: $purple-12 !important

.bg-purple-13
  background: $purple-13 !important

.bg-purple-14
  background: $purple-14 !important

.bg-deep-purple
  background: $deep-purple !important

.bg-deep-purple-1
  background: $deep-purple-1 !important

.bg-deep-purple-2
  background: $deep-purple-2 !important

.bg-deep-purple-3
  background: $deep-purple-3 !important

.bg-deep-purple-4
  background: $deep-purple-4 !important

.bg-deep-purple-5
  background: $deep-purple-5 !important

.bg-deep-purple-6
  background: $deep-purple-6 !important

.bg-deep-purple-7
  background: $deep-purple-7 !important

.bg-deep-purple-8
  background: $deep-purple-8 !important

.bg-deep-purple-9
  background: $deep-purple-9 !important

.bg-deep-purple-10
  background: $deep-purple-10 !important

.bg-deep-purple-11
  background: $deep-purple-11 !important

.bg-deep-purple-12
  background: $deep-purple-12 !important

.bg-deep-purple-13
  background: $deep-purple-13 !important

.bg-deep-purple-14
  background: $deep-purple-14 !important

.bg-indigo
  background: $indigo !important

.bg-indigo-1
  background: $indigo-1 !important

.bg-indigo-2
  background: $indigo-2 !important

.bg-indigo-3
  background: $indigo-3 !important

.bg-indigo-4
  background: $indigo-4 !important

.bg-indigo-5
  background: $indigo-5 !important

.bg-indigo-6
  background: $indigo-6 !important

.bg-indigo-7
  background: $indigo-7 !important

.bg-indigo-8
  background: $indigo-8 !important

.bg-indigo-9
  background: $indigo-9 !important

.bg-indigo-10
  background: $indigo-10 !important

.bg-indigo-11
  background: $indigo-11 !important

.bg-indigo-12
  background: $indigo-12 !important

.bg-indigo-13
  background: $indigo-13 !important

.bg-indigo-14
  background: $indigo-14 !important

.bg-blue
  background: $blue !important

.bg-blue-1
  background: $blue-1 !important

.bg-blue-2
  background: $blue-2 !important

.bg-blue-3
  background: $blue-3 !important

.bg-blue-4
  background: $blue-4 !important

.bg-blue-5
  background: $blue-5 !important

.bg-blue-6
  background: $blue-6 !important

.bg-blue-7
  background: $blue-7 !important

.bg-blue-8
  background: $blue-8 !important

.bg-blue-9
  background: $blue-9 !important

.bg-blue-10
  background: $blue-10 !important

.bg-blue-11
  background: $blue-11 !important

.bg-blue-12
  background: $blue-12 !important

.bg-blue-13
  background: $blue-13 !important

.bg-blue-14
  background: $blue-14 !important

.bg-light-blue
  background: $light-blue !important

.bg-light-blue-1
  background: $light-blue-1 !important

.bg-light-blue-2
  background: $light-blue-2 !important

.bg-light-blue-3
  background: $light-blue-3 !important

.bg-light-blue-4
  background: $light-blue-4 !important

.bg-light-blue-5
  background: $light-blue-5 !important

.bg-light-blue-6
  background: $light-blue-6 !important

.bg-light-blue-7
  background: $light-blue-7 !important

.bg-light-blue-8
  background: $light-blue-8 !important

.bg-light-blue-9
  background: $light-blue-9 !important

.bg-light-blue-10
  background: $light-blue-10 !important

.bg-light-blue-11
  background: $light-blue-11 !important

.bg-light-blue-12
  background: $light-blue-12 !important

.bg-light-blue-13
  background: $light-blue-13 !important

.bg-light-blue-14
  background: $light-blue-14 !important

.bg-cyan
  background: $cyan !important

.bg-cyan-1
  background: $cyan-1 !important

.bg-cyan-2
  background: $cyan-2 !important

.bg-cyan-3
  background: $cyan-3 !important

.bg-cyan-4
  background: $cyan-4 !important

.bg-cyan-5
  background: $cyan-5 !important

.bg-cyan-6
  background: $cyan-6 !important

.bg-cyan-7
  background: $cyan-7 !important

.bg-cyan-8
  background: $cyan-8 !important

.bg-cyan-9
  background: $cyan-9 !important

.bg-cyan-10
  background: $cyan-10 !important

.bg-cyan-11
  background: $cyan-11 !important

.bg-cyan-12
  background: $cyan-12 !important

.bg-cyan-13
  background: $cyan-13 !important

.bg-cyan-14
  background: $cyan-14 !important

.bg-teal
  background: $teal !important

.bg-teal-1
  background: $teal-1 !important

.bg-teal-2
  background: $teal-2 !important

.bg-teal-3
  background: $teal-3 !important

.bg-teal-4
  background: $teal-4 !important

.bg-teal-5
  background: $teal-5 !important

.bg-teal-6
  background: $teal-6 !important

.bg-teal-7
  background: $teal-7 !important

.bg-teal-8
  background: $teal-8 !important

.bg-teal-9
  background: $teal-9 !important

.bg-teal-10
  background: $teal-10 !important

.bg-teal-11
  background: $teal-11 !important

.bg-teal-12
  background: $teal-12 !important

.bg-teal-13
  background: $teal-13 !important

.bg-teal-14
  background: $teal-14 !important

.bg-green
  background: $green !important

.bg-green-1
  background: $green-1 !important

.bg-green-2
  background: $green-2 !important

.bg-green-3
  background: $green-3 !important

.bg-green-4
  background: $green-4 !important

.bg-green-5
  background: $green-5 !important

.bg-green-6
  background: $green-6 !important

.bg-green-7
  background: $green-7 !important

.bg-green-8
  background: $green-8 !important

.bg-green-9
  background: $green-9 !important

.bg-green-10
  background: $green-10 !important

.bg-green-11
  background: $green-11 !important

.bg-green-12
  background: $green-12 !important

.bg-green-13
  background: $green-13 !important

.bg-green-14
  background: $green-14 !important

.bg-light-green
  background: $light-green !important

.bg-light-green-1
  background: $light-green-1 !important

.bg-light-green-2
  background: $light-green-2 !important

.bg-light-green-3
  background: $light-green-3 !important

.bg-light-green-4
  background: $light-green-4 !important

.bg-light-green-5
  background: $light-green-5 !important

.bg-light-green-6
  background: $light-green-6 !important

.bg-light-green-7
  background: $light-green-7 !important

.bg-light-green-8
  background: $light-green-8 !important

.bg-light-green-9
  background: $light-green-9 !important

.bg-light-green-10
  background: $light-green-10 !important

.bg-light-green-11
  background: $light-green-11 !important

.bg-light-green-12
  background: $light-green-12 !important

.bg-light-green-13
  background: $light-green-13 !important

.bg-light-green-14
  background: $light-green-14 !important

.bg-lime
  background: $lime !important

.bg-lime-1
  background: $lime-1 !important

.bg-lime-2
  background: $lime-2 !important

.bg-lime-3
  background: $lime-3 !important

.bg-lime-4
  background: $lime-4 !important

.bg-lime-5
  background: $lime-5 !important

.bg-lime-6
  background: $lime-6 !important

.bg-lime-7
  background: $lime-7 !important

.bg-lime-8
  background: $lime-8 !important

.bg-lime-9
  background: $lime-9 !important

.bg-lime-10
  background: $lime-10 !important

.bg-lime-11
  background: $lime-11 !important

.bg-lime-12
  background: $lime-12 !important

.bg-lime-13
  background: $lime-13 !important

.bg-lime-14
  background: $lime-14 !important

.bg-yellow
  background: $yellow !important

.bg-yellow-1
  background: $yellow-1 !important

.bg-yellow-2
  background: $yellow-2 !important

.bg-yellow-3
  background: $yellow-3 !important

.bg-yellow-4
  background: $yellow-4 !important

.bg-yellow-5
  background: $yellow-5 !important

.bg-yellow-6
  background: $yellow-6 !important

.bg-yellow-7
  background: $yellow-7 !important

.bg-yellow-8
  background: $yellow-8 !important

.bg-yellow-9
  background: $yellow-9 !important

.bg-yellow-10
  background: $yellow-10 !important

.bg-yellow-11
  background: $yellow-11 !important

.bg-yellow-12
  background: $yellow-12 !important

.bg-yellow-13
  background: $yellow-13 !important

.bg-yellow-14
  background: $yellow-14 !important

.bg-amber
  background: $amber !important

.bg-amber-1
  background: $amber-1 !important

.bg-amber-2
  background: $amber-2 !important

.bg-amber-3
  background: $amber-3 !important

.bg-amber-4
  background: $amber-4 !important

.bg-amber-5
  background: $amber-5 !important

.bg-amber-6
  background: $amber-6 !important

.bg-amber-7
  background: $amber-7 !important

.bg-amber-8
  background: $amber-8 !important

.bg-amber-9
  background: $amber-9 !important

.bg-amber-10
  background: $amber-10 !important

.bg-amber-11
  background: $amber-11 !important

.bg-amber-12
  background: $amber-12 !important

.bg-amber-13
  background: $amber-13 !important

.bg-amber-14
  background: $amber-14 !important

.bg-orange
  background: $orange !important

.bg-orange-1
  background: $orange-1 !important

.bg-orange-2
  background: $orange-2 !important

.bg-orange-3
  background: $orange-3 !important

.bg-orange-4
  background: $orange-4 !important

.bg-orange-5
  background: $orange-5 !important

.bg-orange-6
  background: $orange-6 !important

.bg-orange-7
  background: $orange-7 !important

.bg-orange-8
  background: $orange-8 !important

.bg-orange-9
  background: $orange-9 !important

.bg-orange-10
  background: $orange-10 !important

.bg-orange-11
  background: $orange-11 !important

.bg-orange-12
  background: $orange-12 !important

.bg-orange-13
  background: $orange-13 !important

.bg-orange-14
  background: $orange-14 !important

.bg-deep-orange
  background: $deep-orange !important

.bg-deep-orange-1
  background: $deep-orange-1 !important

.bg-deep-orange-2
  background: $deep-orange-2 !important

.bg-deep-orange-3
  background: $deep-orange-3 !important

.bg-deep-orange-4
  background: $deep-orange-4 !important

.bg-deep-orange-5
  background: $deep-orange-5 !important

.bg-deep-orange-6
  background: $deep-orange-6 !important

.bg-deep-orange-7
  background: $deep-orange-7 !important

.bg-deep-orange-8
  background: $deep-orange-8 !important

.bg-deep-orange-9
  background: $deep-orange-9 !important

.bg-deep-orange-10
  background: $deep-orange-10 !important

.bg-deep-orange-11
  background: $deep-orange-11 !important

.bg-deep-orange-12
  background: $deep-orange-12 !important

.bg-deep-orange-13
  background: $deep-orange-13 !important

.bg-deep-orange-14
  background: $deep-orange-14 !important

.bg-brown
  background: $brown !important

.bg-brown-1
  background: $brown-1 !important

.bg-brown-2
  background: $brown-2 !important

.bg-brown-3
  background: $brown-3 !important

.bg-brown-4
  background: $brown-4 !important

.bg-brown-5
  background: $brown-5 !important

.bg-brown-6
  background: $brown-6 !important

.bg-brown-7
  background: $brown-7 !important

.bg-brown-8
  background: $brown-8 !important

.bg-brown-9
  background: $brown-9 !important

.bg-brown-10
  background: $brown-10 !important

.bg-brown-11
  background: $brown-11 !important

.bg-brown-12
  background: $brown-12 !important

.bg-brown-13
  background: $brown-13 !important

.bg-brown-14
  background: $brown-14 !important

.bg-grey
  background: $grey !important

.bg-grey-1
  background: $grey-1 !important

.bg-grey-2
  background: $grey-2 !important

.bg-grey-3
  background: $grey-3 !important

.bg-grey-4
  background: $grey-4 !important

.bg-grey-5
  background: $grey-5 !important

.bg-grey-6
  background: $grey-6 !important

.bg-grey-7
  background: $grey-7 !important

.bg-grey-8
  background: $grey-8 !important

.bg-grey-9
  background: $grey-9 !important

.bg-grey-10
  background: $grey-10 !important

.bg-grey-11
  background: $grey-11 !important

.bg-grey-12
  background: $grey-12 !important

.bg-grey-13
  background: $grey-13 !important

.bg-grey-14
  background: $grey-14 !important

.bg-blue-grey
  background: $blue-grey !important

.bg-blue-grey-1
  background: $blue-grey-1 !important

.bg-blue-grey-2
  background: $blue-grey-2 !important

.bg-blue-grey-3
  background: $blue-grey-3 !important

.bg-blue-grey-4
  background: $blue-grey-4 !important

.bg-blue-grey-5
  background: $blue-grey-5 !important

.bg-blue-grey-6
  background: $blue-grey-6 !important

.bg-blue-grey-7
  background: $blue-grey-7 !important

.bg-blue-grey-8
  background: $blue-grey-8 !important

.bg-blue-grey-9
  background: $blue-grey-9 !important

.bg-blue-grey-10
  background: $blue-grey-10 !important

.bg-blue-grey-11
  background: $blue-grey-11 !important

.bg-blue-grey-12
  background: $blue-grey-12 !important

.bg-blue-grey-13
  background: $blue-grey-13 !important

.bg-blue-grey-14
  background: $blue-grey-14 !important
