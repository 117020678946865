.q-message-name, .q-message-stamp, .q-message-label
  font-size: small

.q-message-label
  margin: (3 * $chat-message-distance) 0
  text-align: center

.q-message-stamp
  color: inherit
  margin-top: 4px
  opacity: .6
  display: none

.q-message-avatar
  border-radius: 50%
  width: $chat-message-avatar-size
  height: $chat-message-avatar-size
  min-width: $chat-message-avatar-size

.q-message
  margin-bottom: $chat-message-distance
  &:first-child .q-message-label
    margin-top: 0

.q-message-avatar--received
  margin-right: 8px
.q-message-text--received
  color: $chat-message-received-bg
  border-radius: $chat-message-border-radius $chat-message-border-radius $chat-message-border-radius 0
  &:last-child:before
    right: 100%
    border-right: 0 solid transparent
    border-left: 8px solid transparent
    border-bottom: 8px solid currentColor
.q-message-text-content--received
  color: $chat-message-received-color

.q-message-name--sent
  text-align: right
.q-message-avatar--sent
  margin-left: 8px
.q-message-container--sent
  flex-direction: row-reverse
.q-message-text--sent
  color: $chat-message-sent-bg
  border-radius: $chat-message-border-radius $chat-message-border-radius 0 $chat-message-border-radius
  &:last-child:before
    left: 100%
    border-left: 0 solid transparent
    border-right: 8px solid transparent
    border-bottom: 8px solid currentColor
.q-message-text-content--sent
  color: $chat-message-sent-color

.q-message-text
  background: currentColor
  padding: $chat-message-text-padding
  line-height: max(1.2, $min-line-height)
  word-break: break-word
  position: relative

  & + &
    margin-top: 3px

  &:last-child
    min-height: $chat-message-avatar-size

    .q-message-stamp
      display: block
    &:before
      content: ''
      position: absolute
      bottom: 0
      width: 0
      height: 0
