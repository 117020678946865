.shadow-transition
  transition: $shadow-transition !important

@for $z from 1 through 24
  .shadow-#{$z}
    box-shadow: nth($shadows, $z)
  .shadow-up-#{$z}
    box-shadow: nth($shadows-up, $z)

.inset-shadow
  box-shadow: $inset-shadow
.inset-shadow-down
  box-shadow: $inset-shadow-down

body.body--dark
  @for $z from 1 through 24
    .shadow-#{$z}
      box-shadow: nth($dark-shadows, $z)
    .shadow-up-#{$z}
      box-shadow: nth($dark-shadows-up, $z)

  .inset-shadow
    box-shadow: $inset-dark-shadow
  .inset-shadow-down
    box-shadow: $inset-dark-shadow-down

.no-shadow, .shadow-0
  box-shadow: none !important

.z-marginals
  z-index: $z-marginals

.z-notify
  z-index: $z-notify

.z-fullscreen
  z-index: $z-fullscreen

.z-inherit
  z-index: inherit !important
