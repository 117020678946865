.q-loading
  color: #000

  // override q-transition--fade's absolute position
  position: fixed !important

  &__backdrop
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    opacity: .5
    z-index: -1
    background-color: #000
    transition: background-color .28s

  &__box
    border-radius: $generic-border-radius
    padding: 18px
    color: #fff
    max-width: 450px

  &__message
    margin: 40px 20px 0
    text-align: center
