.q-bottom-sheet
  padding-bottom: 8px

  &__avatar
    border-radius: 50%

  &--list
    width: 400px

    .q-icon, img
      font-size: 24px
      width: 24px
      height: 24px

  &--grid
    width: 700px

    .q-bottom-sheet__item
      padding: 8px
      text-align: center
      min-width: 100px

    .q-icon, img, .q-bottom-sheet__empty-icon
      font-size: 48px
      width: 48px
      height: 48px
      margin-bottom: 8px

    .q-separator
      margin: 12px 0

  &__item
    flex: 0 0 33.3333%

@media (min-width: $breakpoint-sm-min)
  .q-bottom-sheet__item
    flex: 0 0 25%
