.q-virtual-scroll
  &:focus
    outline: 0

  &__content
    outline: none
    contain: content

    > *
      overflow-anchor: none

    > [data-q-vs-anchor]
      overflow-anchor: auto

  &__padding
    background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0) 20%, rgba(128, 128, 128, .03) 20%, rgba(128, 128, 128, .08) 50%, rgba(128, 128, 128, .03) 80%, rgba(255,255,255,0) 80%, rgba(255,255,255,0)) #{"/* rtl:ignore */"}
    background-size: var(--q-virtual-scroll-item-width, 100%) var(--q-virtual-scroll-item-height, 50px) #{"/* rtl:ignore */"}

    .q-table &
      tr
        height: 0 !important
      td
        padding: 0 !important

  &--horizontal
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: stretch

    .q-virtual-scroll
      &__content
        display: flex
        flex-direction: row
        flex-wrap: nowrap

      &__padding,
      &__content,
      &__content > *
        flex: 0 0 auto

      &__padding
        background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,0) 20%, rgba(128, 128, 128, .03) 20%, rgba(128, 128, 128, .08) 50%, rgba(128, 128, 128, .03) 80%, rgba(255,255,255,0) 80%, rgba(255,255,255,0)) #{"/* rtl:ignore */"}
        background-size: var(--q-virtual-scroll-item-width, 50px) var(--q-virtual-scroll-item-height, 100%) #{"/* rtl:ignore */"}
