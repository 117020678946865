.q-menu
  position: fixed !important
  display: inline-block
  max-width: $menu-max-width

  box-shadow: $menu-box-shadow
  background: $menu-background
  border-radius: $generic-border-radius
  overflow-y: auto
  overflow-x: hidden
  outline: 0
  max-height: 65vh
  z-index: $z-menu

  &--square
    border-radius: 0

  &--dark
    box-shadow: $menu-box-shadow-dark
