.q-notifications__list
  z-index: $z-notify
  pointer-events: none
  left: 0
  right: 0
  margin-bottom: 10px
  position: relative

  &--center
    top: 0
    bottom: 0
  &--top
    top: 0
  &--bottom
    bottom: 0

body.q-ios-padding .q-notifications__list
  &--center, &--top
    top: $ios-statusbar-height
    top: env(safe-area-inset-top)
  &--center, &--bottom
    bottom: env(safe-area-inset-bottom)

.q-notification
  box-shadow: $shadow-2
  border-radius: $generic-border-radius
  pointer-events: all
  display: inline-flex
  margin: 10px 10px 0
  transition: transform 1s, opacity 1s
  z-index: $z-notify
  flex-shrink: 0

  max-width: 95vw

  background: #323232
  color: #fff

  font-size: 14px

  &__icon
    font-size: 24px
    flex: 0 0 1em
    &--additional
      margin-right: 16px

  &__avatar
    font-size: 32px
    &--additional
      margin-right: 8px

  &__spinner
    font-size: 32px
    &--additional
      margin-right: 8px

  &__message
    padding: 8px 0

  &__caption
    font-size: 0.9em
    opacity: 0.7

  &__actions
    color: var(--q-primary)

  &__badge
    animation: q-notif-badge .42s
    padding: 4px 8px
    position: absolute
    box-shadow: $shadow-1
    background-color: var(--q-negative)
    color: #fff
    border-radius: $generic-border-radius
    font-size: 12px
    line-height: 12px

    &--top-left,
    &--top-right
      top: -6px

    &--bottom-left,
    &--bottom-right
      bottom: -6px

    &--top-left,
    &--bottom-left
      left: -22px

    &--top-right,
    &--bottom-right
      right: -22px

  &__progress
    z-index: -1
    position: absolute
    height: 3px
    bottom: 0
    left: -10px
    right: -10px
    animation: q-notif-progress linear
    background: currentColor
    opacity: .3
    border-radius: $generic-border-radius $generic-border-radius 0 0
    transform-origin: 0 50%
    transform: scaleX(0)

  &--standard
    padding: 0 16px
    min-height: 48px

    .q-notification__actions
      padding: 6px 0 6px 8px
      margin-right: -8px

  &--multi-line
    min-height: 68px
    padding: 8px 16px

    .q-notification__badge
      &--top-left,
      &--top-right
        top: -15px

      &--bottom-left,
      &--bottom-right
        bottom: -15px

    .q-notification__progress
      bottom: -8px

    .q-notification__actions
      padding: 0

      &--with-media
        padding-left: 25px

  &--top-left-enter-from, &--top-left-leave-to,
  &--top-enter-from, &--top-leave-to,
  &--top-right-enter-from, &--top-right-leave-to
    opacity: 0
    transform: translateY(-50px)
    z-index: ($z-notify - 1)

  &--left-enter-from, &--left-leave-to,
  &--center-enter-from, &--center-leave-to,
  &--right-enter-from, &--right-leave-to
    opacity: 0
    transform: rotateX(90deg)
    z-index: ($z-notify - 1)

  &--bottom-left-enter-from, &--bottom-left-leave-to,
  &--bottom-enter-from, &--bottom-leave-to,
  &--bottom-right-enter-from, &--bottom-right-leave-to
    opacity: 0
    transform: translateY(50px)
    z-index: ($z-notify - 1)

  &--top-left-leave-active,
  &--top-leave-active,
  &--top-right-leave-active,
  &--left-leave-active,
  &--center-leave-active,
  &--right-leave-active,
  &--bottom-left-leave-active,
  &--bottom-leave-active,
  &--bottom-right-leave-active
    position: absolute
    z-index: ($z-notify - 1)
    margin-left: 0
    margin-right: 0

  &--top-leave-active,
  &--center-leave-active
    top: 0

  &--bottom-left-leave-active,
  &--bottom-leave-active,
  &--bottom-right-leave-active
    bottom: 0

@media (min-width: $breakpoint-sm-min)
  .q-notification
    max-width: 65vw

@keyframes q-notif-badge
  15%
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)

  30%
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)

  45%
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)

  60%
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)

  75%
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)

@keyframes q-notif-progress
  0%
    transform: scaleX(1)
  100%
    transform: scaleX(0)
