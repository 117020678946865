.q-separator
  border: 0
  background: $separator-color
  margin: 0
  transition: background .3s, opacity .3s
  flex-shrink: 0

  &--dark
    background: $separator-dark-color

  &--horizontal
    display: block
    height: 1px

    &-inset
      margin-left: 16px
      margin-right: 16px
    &-item-inset
      margin-left: 72px
      margin-right: 0
    &-item-thumbnail-inset
      margin-left: 116px
      margin-right: 0

  &--vertical
    width: 1px
    height: auto
    align-self: stretch

    &-inset
      margin-top: 8px
      margin-bottom: 8px
