.q-knob

  font-size: 48px

  &--editable
    cursor: pointer
    outline: 0

    &:before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: 50%
      box-shadow: none
      transition: box-shadow .24s ease-in-out

    &:focus:before
      box-shadow: $shadow-2

body.body--dark .q-knob--editable:focus:before
  box-shadow: $dark-shadow-2
