.q-splitter

  &__panel
    position: relative
    z-index: 0

    > .q-splitter
      width: 100%
      height: 100%

  &__separator
    background-color: $separator-color
    user-select: none
    position: relative
    z-index: 1

  &__separator-area > *
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &--dark
    .q-splitter__separator
      background-color: $separator-dark-color

  &--vertical
    > .q-splitter__panel
      height: 100%
    &.q-splitter--active
      cursor: col-resize
    > .q-splitter__separator
      width: 1px
      > div
        left: -6px
        right: -6px
    &.q-splitter--workable > .q-splitter__separator
      cursor: col-resize

  &--horizontal
    > .q-splitter__panel
      width: 100%
    &.q-splitter--active
      cursor: row-resize
    > .q-splitter__separator
      height: 1px
      > div
        top: -6px
        bottom: -6px
    &.q-splitter--workable > .q-splitter__separator
      cursor: row-resize

  &__before, &__after
    overflow: auto
