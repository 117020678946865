.q-card
  box-shadow: $shadow-2
  border-radius: $generic-border-radius
  vertical-align: top
  background: #fff
  position: relative

  > div,
  > img
    &:first-child
      border-top: 0
      border-top-left-radius: inherit
      border-top-right-radius: inherit
  > div,
  > img
    &:last-child
      border-bottom: 0
      border-bottom-left-radius: inherit
      border-bottom-right-radius: inherit
  > div,
  > img
    &:not(:first-child)
      border-top-left-radius: 0
      border-top-right-radius: 0
  > div,
  > img
    &:not(:last-child)
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
  > div
    border-left: 0
    border-right: 0
    box-shadow: none

  &--bordered
    border: 1px solid $separator-color

  &--dark
    border-color: $separator-dark-color
    box-shadow: $dark-shadow-2

  &__section
    position: relative

    &--vert
      padding: 16px

    &--horiz
      > div,
      > img
        &:first-child
          border-top-left-radius: inherit
          border-bottom-left-radius: inherit
        &:last-child
          border-top-right-radius: inherit
          border-bottom-right-radius: inherit
        &:not(:first-child)
          border-top-left-radius: 0
          border-bottom-left-radius: 0
        &:not(:last-child)
          border-top-right-radius: 0
          border-bottom-right-radius: 0

      > div
        border-top: 0
        border-bottom: 0
        box-shadow: none

  &__actions
    padding: 8px
    align-items: center

    .q-btn--rectangle
      padding: 0 8px

    &--horiz
      > .q-btn-item + .q-btn-item,
      > .q-btn-group + .q-btn-item,
      > .q-btn-item + .q-btn-group
        margin-left: 8px

    &--vert
      > .q-btn-item.q-btn--round
        align-self: center
      > .q-btn-item + .q-btn-item,
      > .q-btn-group + .q-btn-item,
      > .q-btn-item + .q-btn-group
        margin-top: 4px

      > .q-btn-group > .q-btn-item
        flex-grow: 1

  > img
    display: block
    width: 100%
    max-width: 100%
    border: 0
