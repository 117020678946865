.q-dialog

  &__title
    font-size: 1.25rem
    font-weight: 500
    line-height: 2rem
    letter-spacing: .0125em

  &__progress
    font-size: 4rem

  &__inner
    outline: 0

    > div
      pointer-events: all
      overflow: auto
      -webkit-overflow-scrolling: touch
      will-change: scroll-position
      border-radius: $generic-border-radius

    &--square > div
      border-radius: 0 !important

    > .q-card
      > .q-card__actions .q-btn--rectangle
        min-width: 64px

    &--minimized
      padding: 24px
      > div
        max-height: calc(100vh - 48px)

    &--maximized
      > div
        height: 100%
        width: 100%
        max-height: 100vh
        max-width: 100vw
        border-radius: 0 !important
        top: 0 !important
        left: 0 !important

    &--top, &--bottom
      padding-top: 0 !important
      padding-bottom: 0 !important
    &--right, &--left
      padding-right: 0 !important
      padding-left: 0 !important

    &--left, &--top
      &:not(.q-dialog__inner--animating) > div
        border-top-left-radius: 0
    &--right, &--top
      &:not(.q-dialog__inner--animating) > div
        border-top-right-radius: 0
    &--left, &--bottom
      &:not(.q-dialog__inner--animating) > div
        border-bottom-left-radius: 0
    &--right, &--bottom
      &:not(.q-dialog__inner--animating) > div
        border-bottom-right-radius: 0

    &--fullwidth
      > div
        width: 100% !important
        max-width: 100% !important
    &--fullheight
      > div
        height: 100% !important
        max-height: 100% !important

  &__backdrop
    z-index: -1
    pointer-events: all
    outline: 0
    background: $dimmed-background

// addressbar always gets shown, so need to slim this down
body.platform-ios, body.platform-android:not(.native-mobile)
  .q-dialog__inner--minimized > div
    max-height: calc(100vh - 108px)

body.q-ios-padding .q-dialog__inner
  padding-top: $ios-statusbar-height !important
  padding-top: env(safe-area-inset-top) !important
  padding-bottom: env(safe-area-inset-bottom) !important
  > div
    max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important

@media (max-width: $breakpoint-xs-max)
  .q-dialog__inner
    &--top, &--bottom
      padding-left: 0
      padding-right: 0

      > div
        width: 100% !important

@media (min-width: $breakpoint-sm-min)
  .q-dialog__inner--minimized > div
    max-width: 560px

.q-body--dialog
  overflow: hidden
