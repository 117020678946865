@import url("https://fonts.cdnfonts.com/css/karla");

body {
  background-color: rgb(255, 255, 255);
  /* font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif; */
  font-family: "Karla", sans-serif;
}

:root {
  --color-1: rgba(252, 221, 189, 0.5);
  --color-2: rgb(252, 220, 189);
  --select-bg-color: var(--color-1);
  --border-color: #0000001f;

  --answer-bg-color: var(--color-1);
  --answer-text-color: black;
  --answer-shadow-bg-color: rgba(177, 174, 174, 0.598);

  --answer-selected-bg-color: var(--color-2);
  --answer-selected-text-color: black;
  --answer-selected-border-color: teal;

  --answer-hover-text-color: black;
  --answer-hover-bg-color: var(--color-2);

  --table-row-hover-bg-color: var(--color-1);
  --table-strip-bg-color: var(--color-1);
}
