.q-badge
  background-color: var(--q-primary)
  color: #fff
  padding: 2px 6px
  border-radius: $generic-border-radius
  font-size: 12px
  line-height: 12px
  min-height: 12px
  font-weight: normal
  vertical-align: baseline

  &--single-line
    white-space: nowrap
  &--multi-line
    word-break: break-all
    word-wrap: break-word

  &--floating
    position: absolute
    top: -4px
    right: -3px
    cursor: inherit

  &--transparent
    opacity: .8

  &--outline
    background-color: transparent
    border: 1px solid currentColor

  &--rounded
    border-radius: 1em
