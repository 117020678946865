$toggle-transition: .22s cubic-bezier(.4,0,.2,1)

.q-toggle
  vertical-align: middle

  &__native
    width: 1px
    height: 1px

  &__track
    height: .35em
    border-radius: .175em
    opacity: .38
    background: currentColor

  &__thumb
    top: .25em
    left: .25em
    width: .5em
    height: .5em
    transition: left $toggle-transition
    user-select: none
    z-index: 0

    &:after
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: 50%
      background: #fff
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)

    .q-icon
      font-size: .3em
      min-width: 1em
      color: #000
      opacity: .54
      z-index: 1

  &__inner
    font-size: 40px
    width: 1.4em
    min-width: 1.4em
    height: 1em
    padding: .325em .3em
    -webkit-print-color-adjust: exact

    &--indet
      .q-toggle__thumb
        left: .45em

    &--truthy
      color: var(--q-primary)
      .q-toggle__track
        opacity: .54
      .q-toggle__thumb
        left: .65em

        &:after
          background-color: currentColor

        .q-icon
          color: #fff
          opacity: 1

  &.disabled
    opacity: .75 !important

  &--dark
    .q-toggle__inner
      color: #fff
      &--truthy
        color: var(--q-primary)
    .q-toggle__thumb
      &:after
        box-shadow: none
      &:before
        opacity: .32 !important

  &--dense

    .q-toggle__inner
      width: .8em
      min-width: .8em
      height: .5em
      padding: .07625em 0

    .q-toggle__thumb
      top: 0
      left: 0

    .q-toggle__inner--indet
      .q-toggle__thumb
        left: .15em
    .q-toggle__inner--truthy
      .q-toggle__thumb
        left: .3em

    .q-toggle__label
      padding-left: .5em

    &.reverse .q-toggle__label
      padding-left: 0
      padding-right: .5em

body.desktop

  .q-toggle:not(.disabled)
    .q-toggle__thumb:before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: 50%
      background: currentColor
      opacity: .12
      transform: scale3d(0, 0, 1)
      transition: transform $option-focus-transition

    &:focus,
    &:hover
      .q-toggle__thumb:before
        transform: scale3d(2, 2, 1)

  .q-toggle--dense:not(.disabled)
    &:focus,
    &:hover
      .q-toggle__thumb:before
        transform: scale3d(1.5, 1.5, 1)
