.q-file

  .q-field__native
    word-break: break-all
    overflow: hidden

  .q-field__input
    opacity: 0 !important

    &::-webkit-file-upload-button
      cursor: pointer

  &__filler
    visibility: hidden
    width: 100%
    border: none
    padding: 0

  &__dnd
    outline: 1px dashed currentColor
    outline-offset: -4px
