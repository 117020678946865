.rounded-borders
  border-radius: $generic-border-radius
.border-radius-inherit
  border-radius: inherit

.no-transition
  transition: none !important

.transition-0
  transition: 0s !important

.glossy
  background-image: linear-gradient(to bottom, rgba(#fff, .3), rgba(#fff, 0) 50%, rgba(#000, .12) 51%, rgba(#000, .04)) !important

.q-placeholder
  &::-webkit-input-placeholder
    color: inherit
    opacity: .7
  &::-moz-placeholder
    color: inherit
    opacity: .7
  &:-ms-input-placeholder
    color: inherit !important
    opacity: .7 !important
  &::-ms-input-placeholder
    color: inherit
    opacity: .7
  &::placeholder
    color: inherit
    opacity: .7

/* body */
.q-body--fullscreen-mixin, .q-body--prevent-scroll
  position: fixed !important

.q-body--force-scrollbar-x
  overflow-x: scroll
.q-body--force-scrollbar-y
  overflow-y: scroll

.q-no-input-spinner
  -moz-appearance: textfield !important
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

.q-link
  outline: 0
  text-decoration: none

  &--focusable:focus-visible
    text-decoration: underline dashed currentColor 1px

body.electron
  .q-electron-drag
    -webkit-user-select: none
    -webkit-app-region: drag
  .q-electron-drag .q-btn-item, .q-electron-drag--exception
    -webkit-app-region: no-drag

img.responsive
  max-width: 100%
  height: auto
