.q-breadcrumbs

  &__el
    color: inherit

  &__el-icon
    font-size: 125%

    &--with-label
      margin-right: 8px

[dir=rtl] .q-breadcrumbs__separator .q-icon
  transform: scaleX(-1) #{"/* rtl:ignore */"}
