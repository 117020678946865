img.responsive
  max-width: 100%
  height: auto

.q-video
  position: relative
  overflow: hidden
  border-radius: inherit

  iframe,
  object,
  embed
    width: 100%
    height: 100%

  &--responsive
    height: 0

    iframe,
    object,
    embed
      position: absolute
      top: 0
      left: 0
