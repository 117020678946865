@import '../../css/helpers/math.sass'

.q-time
  box-shadow: $shadow-2
  border-radius: $generic-border-radius
  background: #fff
  outline: 0
  width: 290px
  min-width: 290px
  max-width: 100%

  &--bordered
    border: 1px solid $separator-color

  &__header
    border-top-left-radius: inherit
    color: #fff
    background-color: var(--q-primary)
    padding: 16px
    font-weight: 300

  &__actions
    padding: 0 16px 16px

  &__header-label
    font-size: 28px
    line-height: 1
    letter-spacing: -.00833em

    > div + div
      margin-left: 4px

  &__link
    opacity: .56
    outline: 0
    transition: opacity .3s ease-out
    &--active, &:hover, &:focus
      opacity: 1

  &__header-ampm
    font-size: 16px
    letter-spacing: .1em

  &__content
    padding: 16px

    &:before
      content: ''
      display: block
      padding-bottom: 100%

  &__container-parent
    padding: 16px

  &__container-child
    border-radius: 50%
    background: rgba(0,0,0,.12)

  &__clock
    padding: 24px
    width: 100%
    height: 100%
    max-width: 100%
    max-height: 100%
    font-size: 14px

  &__clock-circle
    position: relative

  &__clock-center
    height: 6px
    width: 6px
    margin: auto
    border-radius: 50%
    min-height: 0
    background: currentColor

  &__clock-pointer
    width: 2px
    height: 50%
    transform-origin: 0 0 #{"/* rtl:ignore */"}
    min-height: 0
    position: absolute
    left: 50%
    right: 0
    bottom: 0
    color: var(--q-primary)
    background: currentColor
    transform: translateX(-50%)

    &:before, &:after
      content: ''
      position: absolute
      left: 50%
      border-radius: 50%
      background: currentColor
      transform: translateX(-50%)
    &:before
      bottom: -4px
      width: 8px
      height: 8px
    &:after
      top: -3px
      height: 6px
      width: 6px

  &__clock-position
    position: absolute
    min-height: 32px
    width: 32px
    height: 32px
    font-size: 12px
    line-height: 32px
    margin: 0
    padding: 0
    transform: translate(-50%, -50%) #{"/* rtl:ignore */"}
    border-radius: 50%

    &--disable
      opacity: .4

    &--active
      background-color: var(--q-primary)
      color: #fff

  @for $pos from 0 through 11
    $angle: (270 + 30 * $pos)
    $top: (1 + sin($angle))
    $left: (1 + cos($angle))

    &__clock-pos-#{$pos}
      top: toFixed(percentage($top * 0.5), 100)
      left: toFixed(percentage($left * 0.5), 100) #{"/* rtl:ignore */"}

  @for $pos from 12 through 23
    $angle: (270 + 30 * $pos)
    $top: (1 + sin($angle))
    $left: (1 + cos($angle))

    &__clock-pos-#{$pos}
      top: toFixed(percentage(.15 + $top * .35), 100)
      left: toFixed(percentage(.15 + $left * .35), 100) #{"/* rtl:ignore */"}

  &__now-button
    background-color: var(--q-primary)
    color: #fff
    top: 12px
    right: 12px

  &.disabled, &--readonly
    .q-time__header-ampm, .q-time__content
      pointer-events: none

  &--portrait
    display: inline-flex
    flex-direction: column

    .q-time__header
      border-top-right-radius: inherit
      min-height: 86px

    .q-time__header-ampm
      margin-left: 12px

    &.q-time--bordered .q-time__content
      margin: 1px 0

  &--landscape
    display: inline-flex
    align-items: stretch
    min-width: 420px

    > div
      display: flex
      flex-direction: column
      justify-content: center

    .q-time__header
      border-bottom-left-radius: inherit
      min-width: 156px

    .q-time__header-ampm
      margin-top: 12px

  &--dark
    border-color: $separator-dark-color
    box-shadow: $dark-shadow-2
