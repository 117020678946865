.q-panel
  height: 100%
  width: 100%

  > div
    height: 100%
    width: 100%

.q-panel-parent
  overflow: hidden
  position: relative
