.q-dialog-plugin
  width: 400px

  &__form
    max-height: 50vh

  .q-card__section + .q-card__section
    padding-top: 0

  &--progress
    text-align: center
