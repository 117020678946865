.q-bar
  background: rgba(0,0,0,.2)

  > .q-icon
    margin-left: 2px
  > div, > div + .q-icon
    margin-left: 8px
  > .q-btn
    margin-left: 2px

  > .q-icon:first-child, > .q-btn:first-child, > div:first-child
    margin-left: 0

  &--standard
    padding: 0 12px
    height: 32px
    font-size: 18px
    > div
      font-size: 16px
    .q-btn
      font-size: 11px

  &--dense
    padding: 0 8px
    height: 24px
    font-size: 14px
    .q-btn
      font-size: 8px

  &--dark
    background: rgba(255,255,255,.15)
