.q-carousel
  background-color: #fff // needed for fullscreen
  height: 400px

  &__slide
    min-height: 100%
    background-size: cover
    background-position: 50%

  &__slide, .q-carousel--padding
    padding: 16px

  &__slides-container
    height: 100%

  &__control
    color: #fff

  &__arrow
    pointer-events: none

    .q-icon
      font-size: 28px
    .q-btn
      pointer-events: all

  &__prev-arrow--horizontal,
  &__next-arrow--horizontal
    top: 16px
    bottom: 16px
  &__prev-arrow--horizontal
    left: 16px
  &__next-arrow--horizontal
    right: 16px

  &__prev-arrow--vertical,
  &__next-arrow--vertical
    left: 16px
    right: 16px
  &__prev-arrow--vertical
    top: 16px
  &__next-arrow--vertical
    bottom: 16px

  &__navigation

    &--top,
    &--bottom
      left: 16px
      right: 16px
      overflow-x: auto
      overflow-y: hidden

    &--top
      top: 16px

    &--bottom
      bottom: 16px

    &--left,
    &--right
      top: 16px
      bottom: 16px
      overflow-x: hidden
      overflow-y: auto

      > .q-carousel__navigation-inner
        flex-direction: column

    &--left
      left: 16px

    &--right
      right: 16px

    &-inner
      flex: 1 1 auto

    .q-btn
      margin: 6px 4px
      padding: 5px

  &__navigation-icon--inactive
    opacity: .7

  .q-carousel__thumbnail
    margin: 2px
    height: 50px
    width: auto
    display: inline-block
    cursor: pointer
    border: 1px solid transparent
    border-radius: $generic-border-radius
    vertical-align: middle
    opacity: .7
    transition: opacity .3s

  .q-carousel__thumbnail:hover,
  .q-carousel__thumbnail--active
    opacity: 1
  .q-carousel__thumbnail--active
    border-color: currentColor
    cursor: default

  &--navigation-top,
  &--arrows-vertical
    &.q-carousel--with-padding .q-carousel__slide, .q-carousel--padding
      padding-top: 60px

  &--navigation-bottom,
  &--arrows-vertical
    &.q-carousel--with-padding .q-carousel__slide, .q-carousel--padding
      padding-bottom: 60px

  &--navigation-left,
  &--arrows-horizontal
    &.q-carousel--with-padding .q-carousel__slide, .q-carousel--padding
      padding-left: 60px

  &--navigation-right,
  &--arrows-horizontal
    &.q-carousel--with-padding .q-carousel__slide, .q-carousel--padding
      padding-right: 60px

  &.fullscreen
    height: 100%
