.q-avatar
  position: relative
  vertical-align: middle
  display: inline-block

  border-radius: 50%

  font-size: 48px
  height: 1em
  width: 1em

  &__content
    font-size: .5em
    line-height: .5em

  &__content, img:not(.q-icon):not(.q-img__image)
    border-radius: inherit
    height: inherit
    width: inherit

  &--square
    border-radius: 0
