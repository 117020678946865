.q-icon
  line-height: 1
  width: 1em
  height: 1em
  flex-shrink: 0
  letter-spacing: normal
  text-transform: none
  white-space: nowrap
  word-wrap: normal
  direction: ltr
  text-align: center
  position: relative
  box-sizing: content-box
  fill: currentColor // for svg paths

  &:before, &:after
    width: 100%
    height: 100%
    display: flex !important
    align-items: center
    justify-content: center

  > svg,
  > img
    width: 100%
    height: 100%

.q-icon,
.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp,
.material-symbols-outlined,
.material-symbols-rounded,
.material-symbols-sharp
  user-select: none
  cursor: inherit
  font-size: inherit
  display: inline-flex
  align-items: center
  justify-content: center
  vertical-align: middle
