@import "https://fonts.cdnfonts.com/css/karla";
body {
  background-color: #fff;
  font-family: Karla, sans-serif;
}

:root {
  --color-1: #fcddbd80;
  --color-2: #fcdcbd;
  --select-bg-color: var(--color-1);
  --border-color: #0000001f;
  --answer-bg-color: var(--color-1);
  --answer-text-color: black;
  --answer-shadow-bg-color: #b1aeae98;
  --answer-selected-bg-color: var(--color-2);
  --answer-selected-text-color: black;
  --answer-selected-border-color: teal;
  --answer-hover-text-color: black;
  --answer-hover-bg-color: var(--color-2);
  --table-row-hover-bg-color: var(--color-1);
  --table-strip-bg-color: var(--color-1);
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.10edc7fe.woff2") format("woff2"), url("flUhRq6tzZclQEJ-Vdg-IuiaDsNa.130ad2eb.woff") format("woff");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

*, :before, :after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

html, body, #q-app {
  direction: ltr;
  width: 100%;
}

body.platform-ios.within-iframe, body.platform-ios.within-iframe #q-app {
  width: 100px;
  min-width: 100%;
}

html, body {
  box-sizing: border-box;
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

img {
  border-style: none;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font: inherit;
  margin: 0;
  font-family: inherit;
}

optgroup {
  font-weight: bold;
}

button, input, select {
  text-transform: none;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

:is(button:-moz-focusring, input:-moz-focusring) {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.q-icon {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-align: center;
  box-sizing: content-box;
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  line-height: 1;
  position: relative;
}

.q-icon:before, .q-icon:after {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex !important;
}

.q-icon > svg, .q-icon > img {
  width: 100%;
  height: 100%;
}

.q-icon, .material-icons, .material-icons-outlined, .material-icons-round, .material-icons-sharp, .material-symbols-outlined, .material-symbols-rounded, .material-symbols-sharp {
  -webkit-user-select: none;
  user-select: none;
  cursor: inherit;
  font-size: inherit;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.q-panel, .q-panel > div {
  width: 100%;
  height: 100%;
}

.q-panel-parent {
  position: relative;
  overflow: hidden;
}

.q-loading-bar {
  z-index: 9998;
  background: #f44336;
  transition: transform .5s cubic-bezier(0, 0, .2, 1), opacity .5s;
  position: fixed;
}

.q-loading-bar--top {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.q-loading-bar--bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.q-loading-bar--right {
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

.q-loading-bar--left {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}

.q-avatar {
  vertical-align: middle;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  font-size: 48px;
  display: inline-block;
  position: relative;
}

.q-avatar__content {
  font-size: .5em;
  line-height: .5em;
}

.q-avatar__content, .q-avatar img:not(.q-icon):not(.q-img__image) {
  border-radius: inherit;
  height: inherit;
  width: inherit;
}

.q-avatar--square {
  border-radius: 0;
}

.q-badge {
  background-color: var(--q-primary);
  color: #fff;
  vertical-align: baseline;
  border-radius: 4px;
  min-height: 12px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
}

.q-badge--single-line {
  white-space: nowrap;
}

.q-badge--multi-line {
  word-break: break-all;
  word-wrap: break-word;
}

.q-badge--floating {
  cursor: inherit;
  position: absolute;
  top: -4px;
  right: -3px;
}

.q-badge--transparent {
  opacity: .8;
}

.q-badge--outline {
  background-color: #0000;
  border: 1px solid;
}

.q-badge--rounded {
  border-radius: 1em;
}

.q-banner {
  background: #fff;
  min-height: 54px;
  padding: 8px 16px;
}

.q-banner--top-padding {
  padding-top: 14px;
}

.q-banner__avatar {
  min-width: 1px !important;
}

.q-banner__avatar > .q-avatar {
  font-size: 46px;
}

.q-banner__avatar > .q-icon {
  font-size: 40px;
}

.q-banner__avatar:not(:empty) + .q-banner__content, .q-banner__actions.col-auto {
  padding-left: 16px;
}

.q-banner__actions.col-all .q-btn-item {
  margin: 4px 0 0 4px;
}

.q-banner--dense {
  min-height: 32px;
  padding: 8px;
}

.q-banner--dense.q-banner--top-padding {
  padding-top: 12px;
}

.q-banner--dense .q-banner__avatar > .q-avatar, .q-banner--dense .q-banner__avatar > .q-icon {
  font-size: 28px;
}

.q-banner--dense .q-banner__avatar:not(:empty) + .q-banner__content, .q-banner--dense .q-banner__actions.col-auto {
  padding-left: 8px;
}

.q-bar {
  background: #0003;
}

.q-bar > .q-icon {
  margin-left: 2px;
}

.q-bar > div, .q-bar > div + .q-icon {
  margin-left: 8px;
}

.q-bar > .q-btn {
  margin-left: 2px;
}

.q-bar > .q-icon:first-child, .q-bar > .q-btn:first-child, .q-bar > div:first-child {
  margin-left: 0;
}

.q-bar--standard {
  height: 32px;
  padding: 0 12px;
  font-size: 18px;
}

.q-bar--standard > div {
  font-size: 16px;
}

.q-bar--standard .q-btn {
  font-size: 11px;
}

.q-bar--dense {
  height: 24px;
  padding: 0 8px;
  font-size: 14px;
}

.q-bar--dense .q-btn {
  font-size: 8px;
}

.q-bar--dark {
  background: #ffffff26;
}

.q-breadcrumbs__el {
  color: inherit;
}

.q-breadcrumbs__el-icon {
  font-size: 125%;
}

.q-breadcrumbs__el-icon--with-label {
  margin-right: 8px;
}

[dir="rtl"] .q-breadcrumbs__separator .q-icon {
  transform: scaleX(-1);
}

.q-btn {
  vertical-align: middle;
  color: inherit;
  text-transform: uppercase;
  text-align: center;
  cursor: default;
  background: none;
  border: 0;
  outline: 0;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  height: auto;
  min-height: 2.572em;
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.715em;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.q-btn .q-icon, .q-btn .q-spinner {
  font-size: 1.715em;
}

.q-btn.disabled {
  opacity: .7 !important;
}

.q-btn:before {
  content: "";
  border-radius: inherit;
  display: block;
  position: absolute;
  inset: 0;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-btn--actionable {
  cursor: pointer;
}

.q-btn--actionable.q-btn--standard:before {
  transition: box-shadow .3s cubic-bezier(.25, .8, .5, 1);
}

.q-btn--actionable.q-btn--standard:active:before, .q-btn--actionable.q-btn--standard.q-btn--active:before {
  box-shadow: 0 3px 5px -1px #0003, 0 5px 8px #00000024, 0 1px 14px #0000001f;
}

.q-btn--no-uppercase {
  text-transform: none;
}

.q-btn--rectangle {
  border-radius: 3px;
}

.q-btn--outline {
  background: none !important;
}

.q-btn--outline:before {
  border: 1px solid;
}

.q-btn--push {
  border-radius: 7px;
}

.q-btn--push:before {
  border-bottom: 3px solid #00000026;
}

.q-btn--push.q-btn--actionable {
  transition: transform .3s cubic-bezier(.25, .8, .5, 1);
}

.q-btn--push.q-btn--actionable:before {
  transition: border-width .3s cubic-bezier(.25, .8, .5, 1);
}

.q-btn--push.q-btn--actionable:active, .q-btn--push.q-btn--actionable.q-btn--active {
  transform: translateY(2px);
}

.q-btn--push.q-btn--actionable:active:before, .q-btn--push.q-btn--actionable.q-btn--active:before {
  border-bottom-width: 0;
}

.q-btn--rounded {
  border-radius: 28px;
}

.q-btn--round {
  border-radius: 50%;
  min-width: 3em;
  min-height: 3em;
  padding: 0;
}

.q-btn--square {
  border-radius: 0;
}

.q-btn--flat:before, .q-btn--outline:before, .q-btn--unelevated:before {
  box-shadow: none;
}

.q-btn--dense {
  min-height: 2em;
  padding: .285em;
}

.q-btn--dense.q-btn--round {
  min-width: 2.4em;
  min-height: 2.4em;
  padding: 0;
}

.q-btn--dense .on-left {
  margin-right: 6px;
}

.q-btn--dense .on-right {
  margin-left: 6px;
}

.q-btn--fab .q-icon, .q-btn--fab-mini .q-icon {
  font-size: 24px;
}

.q-btn--fab {
  min-width: 56px;
  min-height: 56px;
  padding: 16px;
}

.q-btn--fab .q-icon {
  margin: auto;
}

.q-btn--fab-mini {
  min-width: 40px;
  min-height: 40px;
  padding: 8px;
}

.q-btn__content {
  z-index: 0;
  transition: opacity .3s;
}

.q-btn__content--hidden {
  opacity: 0;
  pointer-events: none;
}

.q-btn__progress {
  border-radius: inherit;
  z-index: 0;
}

.q-btn__progress-indicator {
  z-index: -1;
  background: #ffffff40;
  transform: translateX(-100%);
}

.q-btn__progress--dark .q-btn__progress-indicator {
  background: #0003;
}

.q-btn--flat .q-btn__progress-indicator, .q-btn--outline .q-btn__progress-indicator {
  opacity: .2;
  background: currentColor;
}

.q-btn-dropdown--split .q-btn-dropdown__arrow-container {
  padding: 0 4px;
}

.q-btn-dropdown--split .q-btn-dropdown__arrow-container.q-btn--outline {
  border-left: 1px solid;
}

.q-btn-dropdown--split .q-btn-dropdown__arrow-container:not(.q-btn--outline) {
  border-left: 1px solid #ffffff4d;
}

.q-btn-dropdown--simple * + .q-btn-dropdown__arrow {
  margin-left: 8px;
}

.q-btn-dropdown__arrow {
  transition: transform .28s;
}

.q-btn-dropdown--current {
  flex-grow: 1;
}

.q-btn-group {
  vertical-align: middle;
  border-radius: 3px;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-btn-group > .q-btn-item {
  border-radius: inherit;
  align-self: stretch;
}

.q-btn-group > .q-btn-item:before {
  box-shadow: none;
}

.q-btn-group > .q-btn-item .q-badge--floating {
  right: 0;
}

.q-btn-group > .q-btn-group {
  box-shadow: none;
}

.q-btn-group > .q-btn-group:first-child > .q-btn:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.q-btn-group > .q-btn-group:last-child > .q-btn:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.q-btn-group > .q-btn-group:not(:first-child) > .q-btn:first-child:before {
  border-left: 0;
}

.q-btn-group > .q-btn-group:not(:last-child) > .q-btn:last-child:before {
  border-right: 0;
}

.q-btn-group > .q-btn-item:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.q-btn-group > .q-btn-item:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.q-btn-group > .q-btn-item.q-btn--standard:before {
  z-index: -1;
}

.q-btn-group--push {
  border-radius: 7px;
}

.q-btn-group--push > .q-btn--push.q-btn--actionable {
  transform: none;
}

.q-btn-group--push > .q-btn--push.q-btn--actionable .q-btn__content {
  transition: margin-top .3s cubic-bezier(.25, .8, .5, 1), margin-bottom .3s cubic-bezier(.25, .8, .5, 1);
}

.q-btn-group--push > .q-btn--push.q-btn--actionable:active .q-btn__content, .q-btn-group--push > .q-btn--push.q-btn--actionable.q-btn--active .q-btn__content {
  margin-top: 2px;
  margin-bottom: -2px;
}

.q-btn-group--rounded {
  border-radius: 28px;
}

.q-btn-group--square {
  border-radius: 0;
}

.q-btn-group--flat, .q-btn-group--outline, .q-btn-group--unelevated {
  box-shadow: none;
}

.q-btn-group--outline > .q-separator {
  display: none;
}

.q-btn-group--outline > .q-btn-item + .q-btn-item:before {
  border-left: 0;
}

.q-btn-group--outline > .q-btn-item:not(:last-child):before {
  border-right: 0;
}

.q-btn-group--stretch {
  border-radius: 0;
  align-self: stretch;
}

.q-btn-group--glossy > .q-btn-item {
  background-image: linear-gradient(#ffffff4d, #fff0 50%, #0000001f 51%, #0000000a) !important;
}

.q-btn-group--spread > .q-btn-group {
  display: flex !important;
}

.q-btn-group--spread > .q-btn-item, .q-btn-group--spread > .q-btn-group > .q-btn-item:not(.q-btn-dropdown__arrow-container) {
  flex: 10000;
  width: auto;
  min-width: 0;
  max-width: 100%;
}

.q-btn-toggle {
  position: relative;
}

.q-card {
  vertical-align: top;
  background: #fff;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-card > div:first-child, .q-card > img:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-top: 0;
}

.q-card > div:last-child, .q-card > img:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom: 0;
}

.q-card > div:not(:first-child), .q-card > img:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.q-card > div:not(:last-child), .q-card > img:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.q-card > div {
  box-shadow: none;
  border-left: 0;
  border-right: 0;
}

.q-card--bordered {
  border: 1px solid #0000001f;
}

.q-card--dark {
  border-color: #ffffff47;
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-card__section {
  position: relative;
}

.q-card__section--vert {
  padding: 16px;
}

.q-card__section--horiz > div:first-child, .q-card__section--horiz > img:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.q-card__section--horiz > div:last-child, .q-card__section--horiz > img:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.q-card__section--horiz > div:not(:first-child), .q-card__section--horiz > img:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.q-card__section--horiz > div:not(:last-child), .q-card__section--horiz > img:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.q-card__section--horiz > div {
  box-shadow: none;
  border-top: 0;
  border-bottom: 0;
}

.q-card__actions {
  align-items: center;
  padding: 8px;
}

.q-card__actions .q-btn--rectangle {
  padding: 0 8px;
}

.q-card__actions--horiz > .q-btn-item + .q-btn-item, .q-card__actions--horiz > .q-btn-group + .q-btn-item, .q-card__actions--horiz > .q-btn-item + .q-btn-group {
  margin-left: 8px;
}

.q-card__actions--vert > .q-btn-item.q-btn--round {
  align-self: center;
}

.q-card__actions--vert > .q-btn-item + .q-btn-item, .q-card__actions--vert > .q-btn-group + .q-btn-item, .q-card__actions--vert > .q-btn-item + .q-btn-group {
  margin-top: 4px;
}

.q-card__actions--vert > .q-btn-group > .q-btn-item {
  flex-grow: 1;
}

.q-card > img {
  border: 0;
  width: 100%;
  max-width: 100%;
  display: block;
}

.q-carousel {
  background-color: #fff;
  height: 400px;
}

.q-carousel__slide {
  background-position: 50%;
  background-size: cover;
  min-height: 100%;
}

.q-carousel__slide, .q-carousel .q-carousel--padding {
  padding: 16px;
}

.q-carousel__slides-container {
  height: 100%;
}

.q-carousel__control {
  color: #fff;
}

.q-carousel__arrow {
  pointer-events: none;
}

.q-carousel__arrow .q-icon {
  font-size: 28px;
}

.q-carousel__arrow .q-btn {
  pointer-events: all;
}

.q-carousel__prev-arrow--horizontal, .q-carousel__next-arrow--horizontal {
  top: 16px;
  bottom: 16px;
}

.q-carousel__prev-arrow--horizontal {
  left: 16px;
}

.q-carousel__next-arrow--horizontal {
  right: 16px;
}

.q-carousel__prev-arrow--vertical, .q-carousel__next-arrow--vertical {
  left: 16px;
  right: 16px;
}

.q-carousel__prev-arrow--vertical {
  top: 16px;
}

.q-carousel__next-arrow--vertical {
  bottom: 16px;
}

.q-carousel__navigation--top, .q-carousel__navigation--bottom {
  left: 16px;
  right: 16px;
  overflow-x: auto;
  overflow-y: hidden;
}

.q-carousel__navigation--top {
  top: 16px;
}

.q-carousel__navigation--bottom {
  bottom: 16px;
}

.q-carousel__navigation--left, .q-carousel__navigation--right {
  top: 16px;
  bottom: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.q-carousel__navigation--left > .q-carousel__navigation-inner, .q-carousel__navigation--right > .q-carousel__navigation-inner {
  flex-direction: column;
}

.q-carousel__navigation--left {
  left: 16px;
}

.q-carousel__navigation--right {
  right: 16px;
}

.q-carousel__navigation-inner {
  flex: auto;
}

.q-carousel__navigation .q-btn {
  margin: 6px 4px;
  padding: 5px;
}

.q-carousel__navigation-icon--inactive {
  opacity: .7;
}

.q-carousel .q-carousel__thumbnail {
  cursor: pointer;
  vertical-align: middle;
  opacity: .7;
  border: 1px solid #0000;
  border-radius: 4px;
  width: auto;
  height: 50px;
  margin: 2px;
  transition: opacity .3s;
  display: inline-block;
}

.q-carousel .q-carousel__thumbnail:hover, .q-carousel .q-carousel__thumbnail--active {
  opacity: 1;
}

.q-carousel .q-carousel__thumbnail--active {
  cursor: default;
  border-color: currentColor;
}

.q-carousel--navigation-top.q-carousel--with-padding .q-carousel__slide, .q-carousel--navigation-top .q-carousel--padding, .q-carousel--arrows-vertical.q-carousel--with-padding .q-carousel__slide, .q-carousel--arrows-vertical .q-carousel--padding {
  padding-top: 60px;
}

.q-carousel--navigation-bottom.q-carousel--with-padding .q-carousel__slide, .q-carousel--navigation-bottom .q-carousel--padding, .q-carousel--arrows-vertical.q-carousel--with-padding .q-carousel__slide, .q-carousel--arrows-vertical .q-carousel--padding {
  padding-bottom: 60px;
}

.q-carousel--navigation-left.q-carousel--with-padding .q-carousel__slide, .q-carousel--navigation-left .q-carousel--padding, .q-carousel--arrows-horizontal.q-carousel--with-padding .q-carousel__slide, .q-carousel--arrows-horizontal .q-carousel--padding {
  padding-left: 60px;
}

.q-carousel--navigation-right.q-carousel--with-padding .q-carousel__slide, .q-carousel--navigation-right .q-carousel--padding, .q-carousel--arrows-horizontal.q-carousel--with-padding .q-carousel__slide, .q-carousel--arrows-horizontal .q-carousel--padding {
  padding-right: 60px;
}

.q-carousel.fullscreen {
  height: 100%;
}

.q-message-name, .q-message-stamp, .q-message-label {
  font-size: small;
}

.q-message-label {
  text-align: center;
  margin: 24px 0;
}

.q-message-stamp {
  color: inherit;
  opacity: .6;
  margin-top: 4px;
  display: none;
}

.q-message-avatar {
  border-radius: 50%;
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.q-message {
  margin-bottom: 8px;
}

.q-message:first-child .q-message-label {
  margin-top: 0;
}

.q-message-avatar--received {
  margin-right: 8px;
}

.q-message-text--received {
  color: #81c784;
  border-radius: 4px 4px 4px 0;
}

.q-message-text--received:last-child:before {
  border-bottom: 8px solid;
  border-left: 8px solid #0000;
  border-right: 0 solid #0000;
  right: 100%;
}

.q-message-text-content--received {
  color: #000;
}

.q-message-name--sent {
  text-align: right;
}

.q-message-avatar--sent {
  margin-left: 8px;
}

.q-message-container--sent {
  flex-direction: row-reverse;
}

.q-message-text--sent {
  color: #e0e0e0;
  border-radius: 4px 4px 0;
}

.q-message-text--sent:last-child:before {
  border-bottom: 8px solid;
  border-left: 0 solid #0000;
  border-right: 8px solid #0000;
  left: 100%;
}

.q-message-text-content--sent {
  color: #000;
}

.q-message-text {
  word-break: break-word;
  background: currentColor;
  padding: 8px;
  line-height: 1.2;
  position: relative;
}

.q-message-text + .q-message-text {
  margin-top: 3px;
}

.q-message-text:last-child {
  min-height: 48px;
}

.q-message-text:last-child .q-message-stamp {
  display: block;
}

.q-message-text:last-child:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
}

.q-checkbox {
  vertical-align: middle;
}

.q-checkbox__native {
  width: 1px;
  height: 1px;
}

.q-checkbox__bg, .q-checkbox__icon-container {
  -webkit-user-select: none;
  user-select: none;
}

.q-checkbox__bg {
  -webkit-print-color-adjust: exact;
  border: 2px solid;
  border-radius: 2px;
  width: 50%;
  height: 50%;
  transition: background .22s cubic-bezier(0, 0, .2, 1);
  top: 25%;
  left: 25%;
}

.q-checkbox__icon {
  color: currentColor;
  font-size: .5em;
}

.q-checkbox__svg {
  color: #fff;
}

.q-checkbox__truthy {
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833px;
  stroke-dasharray: 29.7833;
}

.q-checkbox__indet {
  fill: currentColor;
  transform-origin: 50%;
  transform: rotate(-280deg)scale(0);
}

.q-checkbox__inner {
  color: #0000008a;
  border-radius: 50%;
  outline: 0;
  width: 1em;
  min-width: 1em;
  height: 1em;
  font-size: 40px;
}

.q-checkbox__inner--truthy, .q-checkbox__inner--indet {
  color: var(--q-primary);
}

.q-checkbox__inner--truthy .q-checkbox__bg, .q-checkbox__inner--indet .q-checkbox__bg {
  background: currentColor;
}

.q-checkbox__inner--truthy path {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset .18s cubic-bezier(.4, 0, .6, 1);
}

.q-checkbox__inner--indet .q-checkbox__indet {
  transition: transform .22s cubic-bezier(0, 0, .2, 1);
  transform: rotate(0)scale(1);
}

.q-checkbox.disabled {
  opacity: .75 !important;
}

.q-checkbox--dark .q-checkbox__inner {
  color: #ffffffb3;
}

.q-checkbox--dark .q-checkbox__inner:before {
  opacity: .32 !important;
}

.q-checkbox--dark .q-checkbox__inner--truthy, .q-checkbox--dark .q-checkbox__inner--indet {
  color: var(--q-primary);
}

.q-checkbox--dense .q-checkbox__inner {
  width: .5em;
  min-width: .5em;
  height: .5em;
}

.q-checkbox--dense .q-checkbox__bg {
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
}

.q-checkbox--dense .q-checkbox__label {
  padding-left: .5em;
}

.q-checkbox--dense.reverse .q-checkbox__label {
  padding-left: 0;
  padding-right: .5em;
}

body.desktop .q-checkbox:not(.disabled) .q-checkbox__inner:before {
  content: "";
  opacity: .12;
  background: currentColor;
  border-radius: 50%;
  transition: transform .22s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  inset: 0;
  transform: scale3d(0, 0, 1);
}

body.desktop .q-checkbox:not(.disabled):focus .q-checkbox__inner:before, body.desktop .q-checkbox:not(.disabled):hover .q-checkbox__inner:before {
  transform: scale3d(1, 1, 1);
}

body.desktop .q-checkbox--dense:not(.disabled):focus .q-checkbox__inner:before, body.desktop .q-checkbox--dense:not(.disabled):hover .q-checkbox__inner:before {
  transform: scale3d(1.4, 1.4, 1);
}

.q-chip {
  vertical-align: middle;
  color: #000000de;
  background: #e0e0e0;
  border-radius: 16px;
  outline: 0;
  max-width: 100%;
  height: 2em;
  margin: 4px;
  padding: .5em .9em;
  font-size: 14px;
  position: relative;
}

.q-chip--colored .q-chip__icon, .q-chip--dark .q-chip__icon {
  color: inherit;
}

.q-chip--outline {
  border: 1px solid;
  background: none !important;
}

.q-chip .q-avatar {
  border-radius: 16px;
  margin-left: -.45em;
  margin-right: .2em;
  font-size: 2em;
}

.q-chip--selected .q-avatar {
  display: none;
}

.q-chip__icon {
  color: #0000008a;
  margin: -.2em;
  font-size: 1.5em;
}

.q-chip__icon--left {
  margin-right: .2em;
}

.q-chip__icon--right {
  margin-left: .2em;
}

.q-chip__icon--remove {
  opacity: .6;
  outline: 0;
  margin-left: .1em;
  margin-right: -.5em;
}

.q-chip__icon--remove:hover, .q-chip__icon--remove:focus {
  opacity: 1;
}

.q-chip__content {
  white-space: nowrap;
}

.q-chip--dense {
  border-radius: 12px;
  height: 1.5em;
  padding: 0 .4em;
}

.q-chip--dense .q-avatar {
  border-radius: 12px;
  margin-left: -.27em;
  margin-right: .1em;
  font-size: 1.5em;
}

.q-chip--dense .q-chip__icon {
  font-size: 1.25em;
}

.q-chip--dense .q-chip__icon--left {
  margin-right: .195em;
}

.q-chip--dense .q-chip__icon--remove {
  margin-right: -.25em;
}

.q-chip--square {
  border-radius: 4px;
}

.q-chip--square .q-avatar {
  border-radius: 3px 0 0 3px;
}

body.desktop .q-chip--clickable:focus {
  box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
}

body.desktop.body--dark .q-chip--clickable:focus {
  box-shadow: 0 1px 3px #fff3, 0 1px 1px #ffffff24, 0 2px 1px -1px #ffffff1f;
}

.q-circular-progress {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.q-circular-progress.q-focusable {
  border-radius: 50%;
}

.q-circular-progress__svg {
  width: 100%;
  height: 100%;
}

.q-circular-progress__text {
  font-size: .25em;
}

.q-circular-progress--indeterminate .q-circular-progress__svg {
  transform-origin: 50%;
  animation: 2s linear infinite q-spin;
}

.q-circular-progress--indeterminate .q-circular-progress__circle {
  stroke-dasharray: 1 400;
  stroke-dashoffset: 0;
  animation: 1.5s ease-in-out infinite q-circular-progress-circle;
}

@keyframes q-circular-progress-circle {
  0% {
    stroke-dasharray: 1 400;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 400 400;
    stroke-dashoffset: -100px;
  }

  100% {
    stroke-dasharray: 400 400;
    stroke-dashoffset: -300px;
  }
}

.q-color-picker {
  vertical-align: top;
  background: #fff;
  border-radius: 4px;
  min-width: 180px;
  max-width: 350px;
  overflow: hidden;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-color-picker .q-tab {
  padding: 0 !important;
}

.q-color-picker--bordered {
  border: 1px solid #0000001f;
}

.q-color-picker__header-tabs {
  height: 32px;
}

.q-color-picker__header input {
  border: 0;
  line-height: 24px;
}

.q-color-picker__header .q-tab {
  height: 32px !important;
  min-height: 32px !important;
}

.q-color-picker__header .q-tab--inactive {
  background: linear-gradient(to top, #0000004d 0%, #00000026 25%, #0000001a);
}

.q-color-picker__error-icon {
  opacity: 0;
  font-size: 24px;
  transition: opacity .3s ease-in;
  bottom: 2px;
  right: 2px;
}

.q-color-picker__header-content {
  background: #fff;
  position: relative;
}

.q-color-picker__header-content--light {
  color: #000;
}

.q-color-picker__header-content--dark {
  color: #fff;
}

.q-color-picker__header-content--dark .q-tab--inactive:before {
  content: "";
  background: #fff3;
  position: absolute;
  inset: 0;
}

.q-color-picker__header-banner {
  height: 36px;
}

.q-color-picker__header-bg {
  background: #fff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAH0lEQVQoU2NkYGAwZkAFZ5G5jPRRgOYEVDeB3EBjBQBOZwTVugIGyAAAAABJRU5ErkJggg==") !important;
}

.q-color-picker__footer {
  height: 36px;
}

.q-color-picker__footer .q-tab {
  height: 36px !important;
  min-height: 36px !important;
}

.q-color-picker__footer .q-tab--inactive {
  background: linear-gradient(#0000004d 0%, #00000026 25%, #0000001a);
}

.q-color-picker__spectrum {
  width: 100%;
  height: 100%;
}

.q-color-picker__spectrum-tab {
  padding: 0 !important;
}

.q-color-picker__spectrum-white {
  background: linear-gradient(to right, #fff, #fff0);
}

.q-color-picker__spectrum-black {
  background: linear-gradient(to top, #000, #0000);
}

.q-color-picker__spectrum-circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-5px, -5px);
  box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px #0000004d, 0 0 1px 2px #0006;
}

.q-color-picker__hue .q-slider__track {
  opacity: 1;
  background: linear-gradient(to right, red 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%) !important;
}

.q-color-picker__alpha .q-slider__track-container {
  padding-top: 0;
}

.q-color-picker__alpha .q-slider__track:before {
  content: "";
  border-radius: inherit;
  background: linear-gradient(90deg, #fff0, #757575);
  position: absolute;
  inset: 0;
}

.q-color-picker__sliders {
  padding: 0 16px;
}

.q-color-picker__sliders .q-slider__thumb {
  color: #424242;
}

.q-color-picker__sliders .q-slider__thumb path {
  stroke-width: 2px;
  fill: #0000;
}

.q-color-picker__sliders .q-slider--active path {
  stroke-width: 3px;
}

.q-color-picker__tune-tab .q-slider {
  margin-left: 18px;
  margin-right: 18px;
}

.q-color-picker__tune-tab input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 3.5em;
  font-size: 11px;
}

.q-color-picker__palette-tab {
  padding: 0 !important;
}

.q-color-picker__palette-rows--editable .q-color-picker__cube {
  cursor: pointer;
}

.q-color-picker__cube {
  padding-bottom: 10%;
  width: 10% !important;
}

.q-color-picker input {
  color: inherit;
  text-align: center;
  background: none;
  outline: 0;
}

.q-color-picker .q-tabs {
  overflow: hidden;
}

.q-color-picker .q-tab--active {
  box-shadow: 0 0 14px 3px #0003;
}

.q-color-picker .q-tab--active .q-focus-helper, .q-color-picker .q-tab__indicator {
  display: none;
}

.q-color-picker .q-tab-panels {
  background: inherit;
}

.q-color-picker--dark {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-color-picker--dark .q-color-picker__tune-tab input {
  border: 1px solid #ffffff4d;
}

.q-color-picker--dark .q-slider__thumb {
  color: #fafafa;
}

.q-date {
  background: #fff;
  border-radius: 4px;
  width: 290px;
  min-width: 290px;
  max-width: 100%;
  display: inline-flex;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-date--bordered {
  border: 1px solid #0000001f;
}

.q-date__header {
  border-top-left-radius: inherit;
  color: #fff;
  background-color: var(--q-primary);
  padding: 16px;
}

.q-date__actions {
  padding: 0 16px 16px;
}

.q-date__content, .q-date__main {
  outline: 0;
}

.q-date__content .q-btn {
  font-weight: normal;
}

.q-date__header-link {
  opacity: .64;
  outline: 0;
  transition: opacity .3s ease-out;
}

.q-date__header-link--active, .q-date__header-link:hover, .q-date__header-link:focus {
  opacity: 1;
}

.q-date__header-subtitle {
  letter-spacing: .00938em;
  font-size: 14px;
  line-height: 1.75;
}

.q-date__header-title-label {
  letter-spacing: .00735em;
  font-size: 24px;
  line-height: 1.2;
}

.q-date__view {
  width: 100%;
  height: 100%;
  min-height: 290px;
  padding: 16px;
}

.q-date__navigation {
  height: 12.5%;
}

.q-date__navigation > div:first-child {
  justify-content: flex-end;
  width: 8%;
  min-width: 24px;
}

.q-date__navigation > div:last-child {
  justify-content: flex-start;
  width: 8%;
  min-width: 24px;
}

.q-date__calendar-weekdays {
  height: 12.5%;
}

.q-date__calendar-weekdays > div {
  opacity: .38;
  font-size: 12px;
}

.q-date__calendar-item {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  padding: 1px;
  display: inline-flex;
  position: relative;
  width: 14.285% !important;
  height: 12.5% !important;
}

.q-date__calendar-item:after {
  content: "";
  pointer-events: none;
  border: 1px dashed #0000;
  position: absolute;
  inset: 1px 0;
}

.q-date__calendar-item > div, .q-date__calendar-item button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.q-date__calendar-item > div {
  text-align: center;
  line-height: 30px;
}

.q-date__calendar-item > button {
  line-height: 22px;
}

.q-date__calendar-item--out {
  opacity: .18;
}

.q-date__calendar-item--fill {
  visibility: hidden;
}

.q-date__range:before, .q-date__range-from:before, .q-date__range-to:before {
  content: "";
  opacity: .3;
  background-color: currentColor;
  position: absolute;
  inset: 1px 0;
}

.q-date__range:nth-child(7n-6):before, .q-date__range-from:nth-child(7n-6):before, .q-date__range-to:nth-child(7n-6):before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.q-date__range:nth-child(7n):before, .q-date__range-from:nth-child(7n):before, .q-date__range-to:nth-child(7n):before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.q-date__range-from:before {
  left: 50%;
}

.q-date__range-to:before {
  right: 50%;
}

.q-date__edit-range:after {
  border-color: currentColor #0000;
}

.q-date__edit-range:nth-child(7n-6):after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.q-date__edit-range:nth-child(7n):after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.q-date__edit-range-from:after, .q-date__edit-range-from-to:after {
  border-top-color: currentColor;
  border-bottom-color: currentColor;
  border-left-color: currentColor;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  left: 4px;
}

.q-date__edit-range-to:after, .q-date__edit-range-from-to:after {
  border-top-color: currentColor;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  right: 4px;
}

.q-date__calendar-days-container {
  height: 75%;
  min-height: 192px;
}

.q-date__calendar-days > div {
  height: 16.66% !important;
}

.q-date__event {
  background-color: var(--q-secondary);
  border-radius: 5px;
  width: 8px;
  height: 5px;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.q-date__today {
  box-shadow: 0 0 1px;
}

.q-date__years-content {
  padding: 0 8px;
}

.q-date__years-item, .q-date__months-item {
  flex: 0 0 33.3333%;
}

.q-date.disabled .q-date__header, .q-date.disabled .q-date__content, .q-date--readonly .q-date__header, .q-date--readonly .q-date__content {
  pointer-events: none;
}

.q-date--readonly .q-date__navigation {
  display: none;
}

.q-date--portrait {
  flex-direction: column;
}

.q-date--portrait-standard .q-date__content {
  height: calc(100% - 86px);
}

.q-date--portrait-standard .q-date__header {
  border-top-right-radius: inherit;
  height: 86px;
}

.q-date--portrait-standard .q-date__header-title {
  align-items: center;
  height: 30px;
}

.q-date--portrait-minimal .q-date__content {
  height: 100%;
}

.q-date--landscape {
  flex-direction: row;
  align-items: stretch;
  min-width: 420px;
}

.q-date--landscape > div {
  flex-direction: column;
  display: flex;
}

.q-date--landscape .q-date__content {
  height: 100%;
}

.q-date--landscape-standard {
  min-width: 420px;
}

.q-date--landscape-standard .q-date__header {
  border-bottom-left-radius: inherit;
  width: 110px;
  min-width: 110px;
}

.q-date--landscape-standard .q-date__header-title {
  flex-direction: column;
}

.q-date--landscape-standard .q-date__header-today {
  margin-top: 12px;
  margin-left: -8px;
}

.q-date--landscape-minimal {
  width: 310px;
}

.q-date--dark {
  border-color: #ffffff47;
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-dialog__title {
  letter-spacing: .0125em;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

.q-dialog__progress {
  font-size: 4rem;
}

.q-dialog__inner {
  outline: 0;
}

.q-dialog__inner > div {
  pointer-events: all;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  border-radius: 4px;
  overflow: auto;
}

.q-dialog__inner--square > div {
  border-radius: 0 !important;
}

.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  min-width: 64px;
}

.q-dialog__inner--minimized {
  padding: 24px;
}

.q-dialog__inner--minimized > div {
  max-height: calc(100vh - 48px);
}

.q-dialog__inner--maximized > div {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  border-radius: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.q-dialog__inner--top, .q-dialog__inner--bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.q-dialog__inner--right, .q-dialog__inner--left {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.q-dialog__inner--left:not(.q-dialog__inner--animating) > div, .q-dialog__inner--top:not(.q-dialog__inner--animating) > div {
  border-top-left-radius: 0;
}

.q-dialog__inner--right:not(.q-dialog__inner--animating) > div, .q-dialog__inner--top:not(.q-dialog__inner--animating) > div {
  border-top-right-radius: 0;
}

.q-dialog__inner--left:not(.q-dialog__inner--animating) > div, .q-dialog__inner--bottom:not(.q-dialog__inner--animating) > div {
  border-bottom-left-radius: 0;
}

.q-dialog__inner--right:not(.q-dialog__inner--animating) > div, .q-dialog__inner--bottom:not(.q-dialog__inner--animating) > div {
  border-bottom-right-radius: 0;
}

.q-dialog__inner--fullwidth > div {
  width: 100% !important;
  max-width: 100% !important;
}

.q-dialog__inner--fullheight > div {
  height: 100% !important;
  max-height: 100% !important;
}

.q-dialog__backdrop {
  z-index: -1;
  pointer-events: all;
  background: #0006;
  outline: 0;
}

body.platform-ios .q-dialog__inner--minimized > div, body.platform-android:not(.native-mobile) .q-dialog__inner--minimized > div {
  max-height: calc(100vh - 108px);
}

body.q-ios-padding .q-dialog__inner {
  padding-top: 20px !important;
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

body.q-ios-padding .q-dialog__inner > div {
  max-height: calc(100vh - env(safe-area-inset-top)  - env(safe-area-inset-bottom)) !important;
}

@media (width <= 599.98px) {
  .q-dialog__inner--top, .q-dialog__inner--bottom {
    padding-left: 0;
    padding-right: 0;
  }

  .q-dialog__inner--top > div, .q-dialog__inner--bottom > div {
    width: 100% !important;
  }
}

@media (width >= 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
  }
}

.q-body--dialog {
  overflow: hidden;
}

.q-bottom-sheet {
  padding-bottom: 8px;
}

.q-bottom-sheet__avatar {
  border-radius: 50%;
}

.q-bottom-sheet--list {
  width: 400px;
}

.q-bottom-sheet--list .q-icon, .q-bottom-sheet--list img {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.q-bottom-sheet--grid {
  width: 700px;
}

.q-bottom-sheet--grid .q-bottom-sheet__item {
  text-align: center;
  min-width: 100px;
  padding: 8px;
}

.q-bottom-sheet--grid .q-icon, .q-bottom-sheet--grid img, .q-bottom-sheet--grid .q-bottom-sheet__empty-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
  font-size: 48px;
}

.q-bottom-sheet--grid .q-separator {
  margin: 12px 0;
}

.q-bottom-sheet__item {
  flex: 0 0 33.3333%;
}

@media (width >= 600px) {
  .q-bottom-sheet__item {
    flex: 0 0 25%;
  }
}

.q-dialog-plugin {
  width: 400px;
}

.q-dialog-plugin__form {
  max-height: 50vh;
}

.q-dialog-plugin .q-card__section + .q-card__section {
  padding-top: 0;
}

.q-dialog-plugin--progress {
  text-align: center;
}

.q-editor {
  background-color: #fff;
  border: 1px solid #0000001f;
  border-radius: 4px;
}

.q-editor.disabled {
  border-style: dashed;
}

.q-editor > div:first-child, .q-editor__toolbars-container, .q-editor__toolbars-container > div:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.q-editor__content {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  outline: 0;
  max-width: 100%;
  min-height: 10em;
  padding: 10px;
  overflow: auto;
}

.q-editor__content pre {
  white-space: pre-wrap;
}

.q-editor__content hr {
  background: #0000001f;
  border: 0;
  outline: 0;
  height: 1px;
  margin: 1px;
}

.q-editor__content:empty:not(:focus):before {
  content: attr(placeholder);
  opacity: .7;
}

.q-editor__toolbar {
  border-bottom: 1px solid #0000001f;
  min-height: 32px;
}

.q-editor__toolbars-container {
  max-width: 100%;
}

.q-editor .q-btn {
  margin: 4px;
}

.q-editor__toolbar-group {
  margin: 0 4px;
  position: relative;
}

.q-editor__toolbar-group + .q-editor__toolbar-group:before {
  content: "";
  background: #0000001f;
  width: 1px;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: -4px;
}

.q-editor__link-input {
  color: inherit;
  text-transform: none;
  background: none;
  border: none;
  border-radius: 0;
  outline: 0;
  text-decoration: none;
}

.q-editor--flat, .q-editor--flat .q-editor__toolbar {
  border: 0;
}

.q-editor--dense .q-editor__toolbar-group {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.q-editor--dark {
  border-color: #ffffff47;
}

.q-editor--dark .q-editor__content hr {
  background: #ffffff47;
}

.q-editor--dark .q-editor__toolbar {
  border-color: #ffffff47;
}

.q-editor--dark .q-editor__toolbar-group + .q-editor__toolbar-group:before {
  background: #ffffff47;
}

.q-expansion-item__border {
  opacity: 0;
}

.q-expansion-item__toggle-icon {
  transition: transform .3s;
  position: relative;
}

.q-expansion-item__toggle-icon--rotated {
  transform: rotate(180deg);
}

.q-expansion-item__toggle-focus {
  width: 1em !important;
  height: 1em !important;
  position: relative !important;
}

.q-expansion-item__toggle-focus + .q-expansion-item__toggle-icon {
  margin-top: -1em;
}

.q-expansion-item--standard.q-expansion-item--expanded > div > .q-expansion-item__border {
  opacity: 1;
}

.q-expansion-item--popup {
  transition: padding .5s;
}

.q-expansion-item--popup > .q-expansion-item__container {
  border: 1px solid #0000001f;
}

.q-expansion-item--popup > .q-expansion-item__container > .q-separator {
  display: none;
}

.q-expansion-item--popup.q-expansion-item--collapsed {
  padding: 0 15px;
}

.q-expansion-item--popup.q-expansion-item--expanded {
  padding: 15px 0;
}

.q-expansion-item--popup.q-expansion-item--expanded + .q-expansion-item--popup.q-expansion-item--expanded {
  padding-top: 0;
}

.q-expansion-item--popup.q-expansion-item--collapsed:not(:first-child) > .q-expansion-item__container {
  border-top-width: 0;
}

.q-expansion-item--popup.q-expansion-item--expanded + .q-expansion-item--popup.q-expansion-item--collapsed > .q-expansion-item__container {
  border-top-width: 1px;
}

.q-expansion-item__content > .q-card {
  box-shadow: none;
  border-radius: 0;
}

.q-expansion-item:first-child > div > .q-expansion-item__border--top, .q-expansion-item:last-child > div > .q-expansion-item__border--bottom, .q-expansion-item--expanded + .q-expansion-item--expanded > div > .q-expansion-item__border--top {
  opacity: 0;
}

.q-expansion-item--expanded .q-textarea--autogrow textarea {
  animation: q-expansion-done;
}

@keyframes q-expansion-done {
  0% {
    --q-exp-done: 1;
  }
}

.z-fab {
  z-index: 990;
}

.q-fab {
  vertical-align: middle;
  position: relative;
}

.q-fab > .q-btn {
  width: 100%;
}

.q-fab--form-rounded {
  border-radius: 28px;
}

.q-fab--form-square {
  border-radius: 4px;
}

.q-fab__icon, .q-fab__active-icon {
  transition: opacity .4s, transform .4s;
}

.q-fab__icon {
  opacity: 1;
  transform: rotate(0);
}

.q-fab__active-icon {
  opacity: 0;
  transform: rotate(-180deg);
}

.q-fab__label--external {
  padding: 0 8px;
  transition: opacity .18s cubic-bezier(.65, .815, .735, .395);
  position: absolute;
}

.q-fab__label--external-hidden {
  opacity: 0;
  pointer-events: none;
}

.q-fab__label--external-left {
  top: 50%;
  left: -12px;
  transform: translate(-100%, -50%);
}

.q-fab__label--external-right {
  top: 50%;
  right: -12px;
  transform: translate(100%, -50%);
}

.q-fab__label--external-bottom {
  bottom: -12px;
  left: 50%;
  transform: translate(-50%, 100%);
}

.q-fab__label--external-top {
  top: -12px;
  left: 50%;
  transform: translate(-50%, -100%);
}

.q-fab__label--internal {
  max-height: 30px;
  padding: 0;
  transition: font-size .12s cubic-bezier(.65, .815, .735, .395), max-height .12s cubic-bezier(.65, .815, .735, .395), opacity 70ms cubic-bezier(.65, .815, .735, .395);
}

.q-fab__label--internal-hidden {
  opacity: 0;
  font-size: 0;
}

.q-fab__label--internal-top {
  padding-bottom: .12em;
}

.q-fab__label--internal-bottom {
  padding-top: .12em;
}

.q-fab__label--internal-top.q-fab__label--internal-hidden, .q-fab__label--internal-bottom.q-fab__label--internal-hidden {
  max-height: 0;
}

.q-fab__label--internal-left {
  padding-left: .285em;
  padding-right: .571em;
}

.q-fab__label--internal-right {
  padding-left: .571em;
  padding-right: .285em;
}

.q-fab__icon-holder {
  min-width: 24px;
  min-height: 24px;
  position: relative;
}

.q-fab__icon-holder--opened .q-fab__icon {
  opacity: 0;
  transform: rotate(180deg);
}

.q-fab__icon-holder--opened .q-fab__active-icon {
  opacity: 1;
  transform: rotate(0);
}

.q-fab__actions {
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 3px;
  transition: transform .18s ease-in, opacity .18s ease-in;
  position: absolute;
}

.q-fab__actions .q-btn {
  margin: 5px;
}

.q-fab__actions--right {
  transform-origin: 0;
  height: 56px;
  margin-left: 9px;
  left: 100%;
  transform: scale(.4)translateX(-62px);
}

.q-fab__actions--left {
  transform-origin: 100%;
  flex-direction: row-reverse;
  height: 56px;
  margin-right: 9px;
  right: 100%;
  transform: scale(.4)translateX(62px);
}

.q-fab__actions--up {
  transform-origin: 50% 100%;
  flex-direction: column-reverse;
  width: 56px;
  margin-bottom: 9px;
  bottom: 100%;
  transform: scale(.4)translateY(62px);
}

.q-fab__actions--down {
  transform-origin: 50% 0;
  flex-direction: column;
  width: 56px;
  margin-top: 9px;
  top: 100%;
  transform: scale(.4)translateY(-62px);
}

.q-fab__actions--up, .q-fab__actions--down {
  margin-left: -28px;
  left: 50%;
}

.q-fab__actions--opened {
  opacity: 1;
  pointer-events: all;
  transform: scale(1)translate(.1px);
}

.q-fab--align-left > .q-fab__actions--up, .q-fab--align-left > .q-fab__actions--down {
  align-items: flex-start;
  left: 28px;
}

.q-fab--align-right > .q-fab__actions--up, .q-fab--align-right > .q-fab__actions--down {
  align-items: flex-end;
  left: auto;
  right: 0;
}

.q-field {
  font-size: 14px;
}

.q-field ::-ms-clear {
  display: none;
}

.q-field ::-ms-reveal {
  display: none;
}

.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__marginal {
  color: #0000008a;
  height: 56px;
  font-size: 24px;
}

.q-field__marginal > * + * {
  margin-left: 2px;
}

.q-field__marginal .q-avatar {
  font-size: 32px;
}

.q-field__before, .q-field__prepend {
  padding-right: 12px;
}

.q-field__after, .q-field__append {
  padding-left: 12px;
}

.q-field__after:empty, .q-field__append:empty {
  display: none;
}

.q-field__append + .q-field__append {
  padding-left: 2px;
}

.q-field__inner {
  text-align: left;
}

.q-field__bottom {
  color: #0000008a;
  backface-visibility: hidden;
  min-height: 20px;
  padding: 8px 12px 0;
  font-size: 12px;
  line-height: 1;
}

.q-field__bottom--animated {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.q-field__messages {
  line-height: 1;
}

.q-field__messages > div {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.q-field__messages > div + div {
  margin-top: 4px;
}

.q-field__counter {
  padding-left: 8px;
  line-height: 1;
}

.q-field--item-aligned {
  padding: 8px 16px;
}

.q-field--item-aligned .q-field__before {
  min-width: 56px;
}

.q-field__control-container {
  height: inherit;
}

.q-field__control {
  color: var(--q-primary);
  outline: none;
  max-width: 100%;
  height: 56px;
}

.q-field__control:before, .q-field__control:after {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.q-field__control:before {
  border-radius: inherit;
}

.q-field__shadow {
  opacity: 0;
  white-space: pre-wrap;
  transition: opacity .36s cubic-bezier(.4, 0, .2, 1);
  top: 8px;
  overflow: hidden;
}

.q-field__shadow + .q-field__native::placeholder {
  transition: opacity .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field__shadow + .q-field__native:focus::placeholder {
  opacity: 0;
}

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input {
  letter-spacing: .00937em;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-transform: inherit;
  color: #000000de;
  background: none;
  border: none;
  border-radius: 0;
  outline: 0;
  padding: 6px 0;
  font-weight: 400;
  line-height: 28px;
}

.q-field__native, .q-field__input {
  -webkit-user-select: auto;
  user-select: auto;
  width: 100%;
  min-width: 0;
  outline: 0 !important;
}

:-webkit-any(.q-field__native:-webkit-autofill, .q-field__input:-webkit-autofill) {
  -webkit-animation-name: q-autofill;
  -webkit-animation-fill-mode: both;
}

.q-field__native:-webkit-autofill + .q-field__label, .q-field__input:-webkit-autofill + .q-field__label, .q-field__native[type="color"] + .q-field__label, .q-field__native[type="date"] + .q-field__label, .q-field__native[type="datetime-local"] + .q-field__label, .q-field__native[type="month"] + .q-field__label, .q-field__native[type="time"] + .q-field__label, .q-field__native[type="week"] + .q-field__label, .q-field__input[type="color"] + .q-field__label, .q-field__input[type="date"] + .q-field__label, .q-field__input[type="datetime-local"] + .q-field__label, .q-field__input[type="month"] + .q-field__label, .q-field__input[type="time"] + .q-field__label, .q-field__input[type="week"] + .q-field__label {
  transform: translateY(-40%)scale(.75);
}

.q-field__native:invalid, .q-field__input:invalid {
  box-shadow: none;
}

.q-field__native[type="file"] {
  line-height: 1em;
}

.q-field__input {
  height: 0;
  min-height: 24px;
  padding: 0;
  line-height: 24px;
}

.q-field__prefix, .q-field__suffix {
  white-space: nowrap;
  transition: opacity .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field__prefix {
  padding-right: 4px;
}

.q-field__suffix {
  padding-left: 4px;
}

.q-field--readonly .q-placeholder, .q-field--disabled .q-placeholder {
  opacity: 1 !important;
}

.q-field--readonly.q-field--labeled .q-field__native, .q-field--readonly.q-field--labeled .q-field__input {
  cursor: default;
}

.q-field--readonly.q-field--float .q-field__native, .q-field--readonly.q-field--float .q-field__input {
  cursor: text;
}

.q-field--disabled .q-field__inner {
  cursor: not-allowed;
}

.q-field--disabled .q-field__control {
  pointer-events: none;
}

.q-field--disabled .q-field__control > div {
  opacity: .6 !important;
}

.q-field--disabled .q-field__control > div, .q-field--disabled .q-field__control > div * {
  outline: 0 !important;
}

.q-field__label {
  color: #0009;
  letter-spacing: .00937em;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-transform: inherit;
  transform-origin: 0 0;
  backface-visibility: hidden;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: transform .36s cubic-bezier(.4, 0, .2, 1), max-width .324s cubic-bezier(.4, 0, .2, 1);
  top: 18px;
  left: 0;
}

.q-field--float .q-field__label {
  max-width: 133%;
  transition: transform .36s cubic-bezier(.4, 0, .2, 1), max-width .396s cubic-bezier(.4, 0, .2, 1);
  transform: translateY(-40%)scale(.75);
}

.q-field--highlighted .q-field__label {
  color: currentColor;
}

.q-field--highlighted .q-field__shadow {
  opacity: .5;
}

.q-field--filled .q-field__control {
  background: #0000000d;
  border-radius: 4px 4px 0 0;
  padding: 0 12px;
}

.q-field--filled .q-field__control:before {
  opacity: 0;
  background: #0000000d;
  border-bottom: 1px solid #0000006b;
  transition: opacity .36s cubic-bezier(.4, 0, .2, 1), background .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field--filled .q-field__control:hover:before {
  opacity: 1;
}

.q-field--filled .q-field__control:after {
  transform-origin: bottom;
  background: currentColor;
  height: 2px;
  transition: transform .36s cubic-bezier(.4, 0, .2, 1);
  top: auto;
  transform: scale3d(0, 1, 1);
}

.q-field--filled.q-field--rounded .q-field__control {
  border-radius: 28px 28px 0 0;
}

.q-field--filled.q-field--highlighted .q-field__control:before {
  opacity: 1;
  background: #0000001f;
}

.q-field--filled.q-field--highlighted .q-field__control:after {
  transform: scale3d(1, 1, 1);
}

.q-field--filled.q-field--dark .q-field__control, .q-field--filled.q-field--dark .q-field__control:before {
  background: #ffffff12;
}

.q-field--filled.q-field--dark.q-field--highlighted .q-field__control:before {
  background: #ffffff1a;
}

.q-field--filled.q-field--readonly .q-field__control:before {
  opacity: 1;
  background: none;
  border-bottom-style: dashed;
}

.q-field--outlined .q-field__control {
  border-radius: 4px;
  padding: 0 12px;
}

.q-field--outlined .q-field__control:before {
  border: 1px solid #0000003d;
  transition: border-color .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field--outlined .q-field__control:hover:before {
  border-color: #000;
}

.q-field--outlined .q-field__control:after {
  height: inherit;
  border-radius: inherit;
  border: 2px solid #0000;
  transition: border-color .36s cubic-bezier(.4, 0, .2, 1);
}

:-webkit-any(.q-field--outlined .q-field__native:-webkit-autofill, .q-field--outlined .q-field__input:-webkit-autofill) {
  margin-top: 1px;
  margin-bottom: 1px;
}

.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 28px;
}

.q-field--outlined.q-field--highlighted .q-field__control:hover:before {
  border-color: #0000;
}

.q-field--outlined.q-field--highlighted .q-field__control:after {
  border-width: 2px;
  border-color: currentColor;
  transform: scale3d(1, 1, 1);
}

.q-field--outlined.q-field--readonly .q-field__control:before {
  border-style: dashed;
}

.q-field--standard .q-field__control:before {
  border-bottom: 1px solid #0000003d;
  transition: border-color .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field--standard .q-field__control:hover:before {
  border-color: #000;
}

.q-field--standard .q-field__control:after {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  transform-origin: bottom;
  background: currentColor;
  height: 2px;
  transition: transform .36s cubic-bezier(.4, 0, .2, 1);
  top: auto;
  transform: scale3d(0, 1, 1);
}

.q-field--standard.q-field--highlighted .q-field__control:after {
  transform: scale3d(1, 1, 1);
}

.q-field--standard.q-field--readonly .q-field__control:before {
  border-bottom-style: dashed;
}

.q-field--dark .q-field__control:before {
  border-color: #fff9;
}

.q-field--dark .q-field__control:hover:before {
  border-color: #fff;
}

.q-field--dark .q-field__native, .q-field--dark .q-field__prefix, .q-field--dark .q-field__suffix, .q-field--dark .q-field__input {
  color: #fff;
}

.q-field--dark:not(.q-field--highlighted) .q-field__label, .q-field--dark .q-field__marginal, .q-field--dark .q-field__bottom {
  color: #ffffffb3;
}

.q-field--standout .q-field__control {
  background: #0000000d;
  border-radius: 4px;
  padding: 0 12px;
  transition: box-shadow .36s cubic-bezier(.4, 0, .2, 1), background-color .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field--standout .q-field__control:before {
  opacity: 0;
  background: #00000012;
  transition: opacity .36s cubic-bezier(.4, 0, .2, 1), background .36s cubic-bezier(.4, 0, .2, 1);
}

.q-field--standout .q-field__control:hover:before {
  opacity: 1;
}

.q-field--standout.q-field--rounded .q-field__control {
  border-radius: 28px;
}

.q-field--standout.q-field--highlighted .q-field__control {
  background: #000;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-field--standout.q-field--highlighted .q-field__native, .q-field--standout.q-field--highlighted .q-field__prefix, .q-field--standout.q-field--highlighted .q-field__suffix, .q-field--standout.q-field--highlighted .q-field__prepend, .q-field--standout.q-field--highlighted .q-field__append, .q-field--standout.q-field--highlighted .q-field__input {
  color: #fff;
}

.q-field--standout.q-field--readonly .q-field__control:before {
  opacity: 1;
  background: none;
  border: 1px dashed #0000003d;
}

.q-field--standout.q-field--dark .q-field__control, .q-field--standout.q-field--dark .q-field__control:before {
  background: #ffffff12;
}

.q-field--standout.q-field--dark.q-field--highlighted .q-field__control {
  background: #fff;
}

.q-field--standout.q-field--dark.q-field--highlighted .q-field__native, .q-field--standout.q-field--dark.q-field--highlighted .q-field__prefix, .q-field--standout.q-field--dark.q-field--highlighted .q-field__suffix, .q-field--standout.q-field--dark.q-field--highlighted .q-field__prepend, .q-field--standout.q-field--dark.q-field--highlighted .q-field__append, .q-field--standout.q-field--dark.q-field--highlighted .q-field__input {
  color: #000;
}

.q-field--standout.q-field--dark.q-field--readonly .q-field__control:before {
  border-color: #ffffff3d;
}

.q-field--labeled .q-field__native, .q-field--labeled .q-field__prefix, .q-field--labeled .q-field__suffix {
  padding-top: 24px;
  padding-bottom: 8px;
  line-height: 24px;
}

.q-field--labeled .q-field__shadow {
  top: 0;
}

.q-field--labeled:not(.q-field--float) .q-field__prefix, .q-field--labeled:not(.q-field--float) .q-field__suffix {
  opacity: 0;
}

.q-field--labeled:not(.q-field--float) .q-field__native::-webkit-input-placeholder {
  color: #0000;
}

.q-field--labeled:not(.q-field--float) .q-field__input::-webkit-input-placeholder {
  color: #0000;
}

.q-field--labeled:not(.q-field--float) .q-field__native::-moz-placeholder {
  color: #0000;
}

.q-field--labeled:not(.q-field--float) .q-field__input::-moz-placeholder {
  color: #0000;
}

:is(.q-field--labeled:not(.q-field--float) .q-field__native:-ms-input-placeholder, .q-field--labeled:not(.q-field--float) .q-field__input:-ms-input-placeholder) {
  color: #0000 !important;
}

.q-field--labeled:not(.q-field--float) .q-field__native::-moz-placeholder {
  color: #0000;
}

.q-field--labeled:not(.q-field--float) .q-field__input::-moz-placeholder {
  color: #0000;
}

.q-field--labeled:not(.q-field--float) .q-field__native::placeholder, .q-field--labeled:not(.q-field--float) .q-field__input::placeholder {
  color: #0000;
}

.q-field--labeled.q-field--dense .q-field__native, .q-field--labeled.q-field--dense .q-field__prefix, .q-field--labeled.q-field--dense .q-field__suffix {
  padding-top: 14px;
  padding-bottom: 2px;
}

.q-field--dense .q-field__shadow {
  top: 0;
}

.q-field--dense .q-field__control, .q-field--dense .q-field__marginal {
  height: 40px;
}

.q-field--dense .q-field__bottom {
  font-size: 11px;
}

.q-field--dense .q-field__label {
  font-size: 14px;
  top: 10px;
}

.q-field--dense .q-field__before, .q-field--dense .q-field__prepend {
  padding-right: 6px;
}

.q-field--dense .q-field__after, .q-field--dense .q-field__append {
  padding-left: 6px;
}

.q-field--dense .q-field__append + .q-field__append {
  padding-left: 2px;
}

.q-field--dense .q-field__marginal .q-avatar {
  font-size: 24px;
}

.q-field--dense.q-field--float .q-field__label, .q-field--dense .q-field__native:-webkit-autofill + .q-field__label, .q-field--dense .q-field__input:-webkit-autofill + .q-field__label, .q-field--dense .q-field__native[type="color"] + .q-field__label, .q-field--dense .q-field__native[type="date"] + .q-field__label, .q-field--dense .q-field__native[type="datetime-local"] + .q-field__label, .q-field--dense .q-field__native[type="month"] + .q-field__label, .q-field--dense .q-field__native[type="time"] + .q-field__label, .q-field--dense .q-field__native[type="week"] + .q-field__label, .q-field--dense .q-field__input[type="color"] + .q-field__label, .q-field--dense .q-field__input[type="date"] + .q-field__label, .q-field--dense .q-field__input[type="datetime-local"] + .q-field__label, .q-field--dense .q-field__input[type="month"] + .q-field__label, .q-field--dense .q-field__input[type="time"] + .q-field__label, .q-field--dense .q-field__input[type="week"] + .q-field__label {
  transform: translateY(-30%)scale(.75);
}

.q-field--borderless .q-field__bottom, .q-field--borderless.q-field--dense .q-field__control, .q-field--standard .q-field__bottom, .q-field--standard.q-field--dense .q-field__control {
  padding-left: 0;
  padding-right: 0;
}

.q-field--error .q-field__label {
  animation: .36s q-field-label;
}

.q-field--error .q-field__bottom {
  color: var(--q-negative);
}

.q-field__focusable-action {
  opacity: .6;
  cursor: pointer;
  color: inherit;
  background: none;
  border: 0;
  padding: 0;
  outline: 0 !important;
}

.q-field__focusable-action:hover, .q-field__focusable-action:focus {
  opacity: 1;
}

.q-field--auto-height .q-field__control {
  height: auto;
}

.q-field--auto-height .q-field__control, .q-field--auto-height .q-field__native {
  min-height: 56px;
}

.q-field--auto-height .q-field__native {
  align-items: center;
}

.q-field--auto-height .q-field__control-container {
  padding-top: 0;
}

.q-field--auto-height .q-field__native, .q-field--auto-height .q-field__prefix, .q-field--auto-height .q-field__suffix {
  line-height: 18px;
}

.q-field--auto-height.q-field--labeled .q-field__control-container {
  padding-top: 24px;
}

.q-field--auto-height.q-field--labeled .q-field__shadow {
  top: 24px;
}

.q-field--auto-height.q-field--labeled .q-field__native, .q-field--auto-height.q-field--labeled .q-field__prefix, .q-field--auto-height.q-field--labeled .q-field__suffix {
  padding-top: 0;
}

.q-field--auto-height.q-field--labeled .q-field__native {
  min-height: 24px;
}

.q-field--auto-height.q-field--dense .q-field__control, .q-field--auto-height.q-field--dense .q-field__native {
  min-height: 40px;
}

.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container {
  padding-top: 14px;
}

.q-field--auto-height.q-field--dense.q-field--labeled .q-field__shadow {
  top: 14px;
}

.q-field--auto-height.q-field--dense.q-field--labeled .q-field__native {
  min-height: 24px;
}

.q-field--square .q-field__control {
  border-radius: 0 !important;
}

.q-transition--field-message-enter-active, .q-transition--field-message-leave-active {
  transition: transform .6s cubic-bezier(.86, 0, .07, 1), opacity .6s cubic-bezier(.86, 0, .07, 1);
}

.q-transition--field-message-enter-from, .q-transition--field-message-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.q-transition--field-message-leave-from, .q-transition--field-message-leave-active {
  position: absolute;
}

@keyframes q-field-label {
  40% {
    margin-left: 2px;
  }

  60%, 80% {
    margin-left: -2px;
  }

  70%, 90% {
    margin-left: 2px;
  }
}

@keyframes q-autofill {
  to {
    color: inherit;
    background: none;
  }
}

.q-file .q-field__native {
  word-break: break-all;
  overflow: hidden;
}

.q-file .q-field__input {
  opacity: 0 !important;
}

.q-file .q-field__input::-webkit-file-upload-button {
  cursor: pointer;
}

.q-file__filler {
  visibility: hidden;
  border: none;
  width: 100%;
  padding: 0;
}

.q-file__dnd {
  outline-offset: -4px;
  outline: 1px dashed;
}

.q-form {
  position: relative;
}

.q-img {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.q-img__loading .q-spinner {
  font-size: 50px;
}

.q-img__container {
  border-radius: inherit;
  font-size: 0;
}

.q-img__image {
  border-radius: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.q-img__image--with-transition {
  transition: opacity .28s ease-in;
}

.q-img__image--loaded {
  opacity: 1;
}

.q-img__content {
  border-radius: inherit;
  pointer-events: none;
}

.q-img__content > div {
  pointer-events: all;
  color: #fff;
  background: #00000078;
  padding: 16px;
  position: absolute;
}

.q-img--no-menu .q-img__image, .q-img--no-menu .q-img__placeholder {
  pointer-events: none;
}

.q-inner-loading {
  background: #fff9;
}

.q-inner-loading--dark {
  background: #0006;
}

.q-inner-loading__label {
  margin-top: 8px;
}

.q-textarea .q-field__control {
  height: auto;
  min-height: 56px;
}

.q-textarea .q-field__control-container {
  padding-top: 2px;
  padding-bottom: 2px;
}

.q-textarea .q-field__shadow {
  top: 2px;
  bottom: 2px;
}

.q-textarea .q-field__native, .q-textarea .q-field__prefix, .q-textarea .q-field__suffix {
  line-height: 18px;
}

.q-textarea .q-field__native {
  resize: vertical;
  min-height: 52px;
  padding-top: 17px;
}

.q-textarea.q-field--labeled .q-field__control-container {
  padding-top: 26px;
}

.q-textarea.q-field--labeled .q-field__shadow {
  top: 26px;
}

.q-textarea.q-field--labeled .q-field__native, .q-textarea.q-field--labeled .q-field__prefix, .q-textarea.q-field--labeled .q-field__suffix {
  padding-top: 0;
}

.q-textarea.q-field--labeled .q-field__native {
  min-height: 26px;
  padding-top: 1px;
}

.q-textarea--autogrow .q-field__native {
  resize: none;
}

.q-textarea.q-field--dense .q-field__control, .q-textarea.q-field--dense .q-field__native {
  min-height: 36px;
}

.q-textarea.q-field--dense .q-field__native {
  padding-top: 9px;
}

.q-textarea.q-field--dense.q-field--labeled .q-field__control-container {
  padding-top: 14px;
}

.q-textarea.q-field--dense.q-field--labeled .q-field__shadow {
  top: 14px;
}

.q-textarea.q-field--dense.q-field--labeled .q-field__native {
  min-height: 24px;
  padding-top: 3px;
}

.q-textarea.q-field--dense.q-field--labeled .q-field__prefix, .q-textarea.q-field--dense.q-field--labeled .q-field__suffix {
  padding-top: 2px;
}

body.mobile .q-textarea .q-field__native, .q-textarea.disabled .q-field__native {
  resize: none;
}

.q-intersection {
  position: relative;
}

.q-item {
  color: inherit;
  min-height: 48px;
  padding: 8px 16px;
  transition: color .3s, background-color .3s;
}

.q-item__section--side {
  color: #757575;
  align-items: flex-start;
  width: auto;
  min-width: 0;
  max-width: 100%;
  padding-right: 16px;
}

.q-item__section--side > .q-icon {
  font-size: 24px;
}

.q-item__section--side > .q-avatar {
  font-size: 40px;
}

.q-item__section--avatar {
  color: inherit;
  min-width: 56px;
}

.q-item__section--thumbnail img {
  width: 100px;
  height: 56px;
}

.q-item__section--nowrap {
  white-space: nowrap;
}

.q-item > .q-item__section--thumbnail:first-child, .q-item > .q-focus-helper + .q-item__section--thumbnail {
  margin-left: -16px;
}

.q-item > .q-item__section--thumbnail:last-of-type {
  margin-right: -16px;
}

.q-item__label {
  max-width: 100%;
  line-height: 1.2em !important;
}

.q-item__label--overline {
  color: #000000b3;
}

.q-item__label--caption {
  color: #0000008a;
}

.q-item__label--header {
  color: #757575;
  letter-spacing: .01786em;
  padding: 16px;
  font-size: .875rem;
  line-height: 1.25rem;
}

.q-separator--spaced + .q-item__label--header, .q-list--padding .q-item__label--header {
  padding-top: 8px;
}

.q-item__label + .q-item__label {
  margin-top: 4px;
}

.q-item__section--main {
  flex: 10000;
  width: auto;
  min-width: 0;
  max-width: 100%;
}

.q-item__section--main + .q-item__section--main {
  margin-left: 8px;
}

.q-item__section--main ~ .q-item__section--side {
  align-items: flex-end;
  padding-left: 16px;
  padding-right: 0;
}

.q-item__section--main.q-item__section--thumbnail {
  margin-left: 0;
  margin-right: -16px;
}

.q-list--bordered {
  border: 1px solid #0000001f;
}

.q-list--separator > .q-item-type + .q-item-type, .q-list--separator > .q-virtual-scroll__content > .q-item-type + .q-item-type {
  border-top: 1px solid #0000001f;
}

.q-list--padding {
  padding: 8px 0;
}

.q-list--dense > .q-item, .q-item--dense {
  min-height: 32px;
  padding: 2px 16px;
}

.q-list--dark.q-list--separator > .q-item-type + .q-item-type, .q-list--dark.q-list--separator > .q-virtual-scroll__content > .q-item-type + .q-item-type {
  border-top-color: #ffffff47;
}

.q-list--dark, .q-item--dark {
  color: #fff;
  border-color: #ffffff47;
}

.q-list--dark .q-item__section--side:not(.q-item__section--avatar), .q-item--dark .q-item__section--side:not(.q-item__section--avatar) {
  color: #ffffffb3;
}

.q-list--dark .q-item__label--header, .q-item--dark .q-item__label--header {
  color: #ffffffa3;
}

.q-list--dark .q-item__label--overline, .q-list--dark .q-item__label--caption, .q-item--dark .q-item__label--overline, .q-item--dark .q-item__label--caption {
  color: #fffc;
}

.q-item {
  position: relative;
}

.q-item.q-router-link--active, .q-item--active {
  color: var(--q-primary);
}

.q-knob {
  font-size: 48px;
}

.q-knob--editable {
  cursor: pointer;
  outline: 0;
}

.q-knob--editable:before {
  content: "";
  box-shadow: none;
  border-radius: 50%;
  transition: box-shadow .24s ease-in-out;
  position: absolute;
  inset: 0;
}

.q-knob--editable:focus:before {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

body.body--dark .q-knob--editable:focus:before {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-layout {
  outline: 0;
  width: 100%;
}

.q-layout-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.q-layout-container .q-layout {
  min-height: 100%;
}

.q-layout-container > div {
  transform: translate3d(0, 0, 0);
}

.q-layout-container > div > div {
  min-height: 0;
  max-height: 100%;
}

.q-layout__shadow {
  width: 100%;
}

.q-layout__shadow:after {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px #0003, 0 0 10px #0000003d;
}

.q-layout__section--marginal {
  background-color: var(--q-primary);
  color: #fff;
}

.q-header--hidden {
  transform: translateY(-110%);
}

.q-header--bordered {
  border-bottom: 1px solid #0000001f;
}

.q-header .q-layout__shadow {
  bottom: -10px;
}

.q-header .q-layout__shadow:after {
  bottom: 10px;
}

.q-footer--hidden {
  transform: translateY(110%);
}

.q-footer--bordered {
  border-top: 1px solid #0000001f;
}

.q-footer .q-layout__shadow {
  top: -10px;
}

.q-footer .q-layout__shadow:after {
  top: 10px;
}

.q-header, .q-footer {
  z-index: 2000;
}

.q-drawer {
  z-index: 1000;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
}

.q-drawer--on-top {
  z-index: 3000;
}

.q-drawer--left {
  left: 0;
  transform: translateX(-100%);
}

.q-drawer--left.q-drawer--bordered {
  border-right: 1px solid #0000001f;
}

.q-drawer--left .q-layout__shadow {
  left: 10px;
  right: -10px;
}

.q-drawer--left .q-layout__shadow:after {
  right: 10px;
}

.q-drawer--right {
  right: 0;
  transform: translateX(100%);
}

.q-drawer--right.q-drawer--bordered {
  border-left: 1px solid #0000001f;
}

.q-drawer--right .q-layout__shadow {
  left: -10px;
}

.q-drawer--right .q-layout__shadow:after {
  left: 10px;
}

.q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini {
  padding: 0 !important;
}

.q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini .q-item, .q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini .q-item__section {
  text-align: center;
  justify-content: center;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}

.q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini .q-item__label, .q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini .q-item__section--main, .q-drawer-container:not(.q-drawer--mini-animate) .q-drawer--mini .q-item__section--side ~ .q-item__section--side, .q-drawer--mini .q-mini-drawer-hide, .q-drawer--mini .q-expansion-item__content {
  display: none;
}

.q-drawer--mini-animate .q-drawer__content {
  white-space: nowrap;
  overflow-x: hidden !important;
}

.q-drawer--standard .q-mini-drawer-only, .q-drawer--mobile .q-mini-drawer-only, .q-drawer--mobile .q-mini-drawer-hide {
  display: none;
}

.q-drawer__backdrop {
  will-change: background-color;
  z-index: 2999 !important;
}

.q-drawer__opener {
  z-index: 2001;
  -webkit-user-select: none;
  user-select: none;
  width: 15px;
  height: 100%;
}

.q-layout, .q-header, .q-footer, .q-page {
  position: relative;
}

.q-page-sticky--shrink {
  pointer-events: none;
}

.q-page-sticky--shrink > div {
  pointer-events: auto;
  display: inline-block;
}

body.q-ios-padding .q-layout--standard .q-header > .q-toolbar:first-child, body.q-ios-padding .q-layout--standard .q-header > .q-tabs:first-child .q-tabs-head, body.q-ios-padding .q-layout--standard .q-drawer--top-padding .q-drawer__content {
  padding-top: 20px;
  padding-top: env(safe-area-inset-top);
  min-height: calc(env(safe-area-inset-top)  + 50px);
  min-height: 70px;
}

body.q-ios-padding .q-layout--standard .q-footer > .q-toolbar:last-child, body.q-ios-padding .q-layout--standard .q-footer > .q-tabs:last-child .q-tabs-head, body.q-ios-padding .q-layout--standard .q-drawer--top-padding .q-drawer__content {
  padding-bottom: env(safe-area-inset-bottom);
  min-height: calc(env(safe-area-inset-bottom)  + 50px);
}

.q-body--layout-animate .q-drawer__backdrop {
  transition: background-color .12s !important;
}

.q-body--layout-animate .q-drawer {
  transition: transform .12s, width .12s, top .12s, bottom .12s !important;
}

.q-body--layout-animate .q-layout__section--marginal {
  transition: transform .12s, left .12s, right .12s !important;
}

.q-body--layout-animate .q-page-container {
  transition: padding-top .12s, padding-right .12s, padding-bottom .12s, padding-left .12s !important;
}

.q-body--layout-animate .q-page-sticky {
  transition: transform .12s, left .12s, right .12s, top .12s, bottom .12s !important;
}

body:not(.q-body--layout-animate) .q-layout--prevent-focus {
  visibility: hidden;
}

.q-body--drawer-toggle {
  overflow-x: hidden !important;
}

@media (width <= 599.98px) {
  .q-layout-padding {
    padding: 8px;
  }
}

@media (width >= 600px) and (width <= 1439.98px) {
  .q-layout-padding {
    padding: 16px;
  }
}

@media (width >= 1440px) {
  .q-layout-padding {
    padding: 24px;
  }
}

body.body--dark .q-header, body.body--dark .q-footer, body.body--dark .q-drawer {
  border-color: #ffffff47;
}

body.body--dark .q-layout__shadow:after {
  box-shadow: 0 0 10px 2px #fff3, 0 0 10px #ffffff3d;
}

body.platform-ios .q-layout--containerized {
  position: unset !important;
}

.q-linear-progress {
  --q-linear-progress-speed: .3s;
  color: var(--q-primary);
  width: 100%;
  height: 1em;
  font-size: 4px;
  position: relative;
  overflow: hidden;
  transform: scale3d(1, 1, 1);
}

.q-linear-progress__model, .q-linear-progress__track {
  transform-origin: 0 0;
}

.q-linear-progress__model--with-transition, .q-linear-progress__track--with-transition {
  transition: transform var(--q-linear-progress-speed);
}

.q-linear-progress--reverse .q-linear-progress__model, .q-linear-progress--reverse .q-linear-progress__track {
  transform-origin: 0 100%;
}

.q-linear-progress__model--determinate {
  background: currentColor;
}

.q-linear-progress__model--indeterminate, .q-linear-progress__model--query {
  transition: none;
}

.q-linear-progress__model--indeterminate:before, .q-linear-progress__model--indeterminate:after, .q-linear-progress__model--query:before, .q-linear-progress__model--query:after {
  content: "";
  transform-origin: 0 0;
  background: currentColor;
  position: absolute;
  inset: 0;
}

.q-linear-progress__model--indeterminate:before, .q-linear-progress__model--query:before {
  animation: 2.1s cubic-bezier(.65, .815, .735, .395) infinite q-linear-progress--indeterminate;
}

.q-linear-progress__model--indeterminate:after, .q-linear-progress__model--query:after {
  animation: 2.1s cubic-bezier(.165, .84, .44, 1) 1.15s infinite q-linear-progress--indeterminate-short;
  transform: translate3d(-101%, 0, 0)scale3d(1, 1, 1);
}

.q-linear-progress__track {
  opacity: .4;
}

.q-linear-progress__track--light {
  background: #00000042;
}

.q-linear-progress__track--dark {
  background: #fff9;
}

.q-linear-progress__stripe {
  background-image: linear-gradient(45deg, #ffffff26 25%, #fff0 25% 50%, #ffffff26 50% 75%, #fff0 75%, #fff0) !important;
  background-size: 40px 40px !important;
}

.q-linear-progress__stripe--with-transition {
  transition: width var(--q-linear-progress-speed);
}

@keyframes q-linear-progress--indeterminate {
  0% {
    transform: translate3d(-35%, 0, 0)scale3d(.35, 1, 1);
  }

  60% {
    transform: translate3d(100%, 0, 0)scale3d(.9, 1, 1);
  }

  100% {
    transform: translate3d(100%, 0, 0)scale3d(.9, 1, 1);
  }
}

@keyframes q-linear-progress--indeterminate-short {
  0% {
    transform: translate3d(-101%, 0, 0)scale3d(1, 1, 1);
  }

  60% {
    transform: translate3d(107%, 0, 0)scale3d(.01, 1, 1);
  }

  100% {
    transform: translate3d(107%, 0, 0)scale3d(.01, 1, 1);
  }
}

.q-menu {
  z-index: 6000;
  background: #fff;
  border-radius: 4px;
  outline: 0;
  max-width: 95vw;
  max-height: 65vh;
  display: inline-block;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
  position: fixed !important;
}

.q-menu--square {
  border-radius: 0;
}

.q-menu--dark {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-option-group--inline > div {
  display: inline-block;
}

.q-pagination input {
  text-align: center;
  -moz-appearance: textfield;
}

.q-pagination input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.q-pagination input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.q-pagination__content {
  --q-pagination-gutter-parent: -2px;
  --q-pagination-gutter-child: 2px;
  margin-top: var(--q-pagination-gutter-parent);
  margin-left: var(--q-pagination-gutter-parent);
}

.q-pagination__content > .q-btn, .q-pagination__content > .q-input, .q-pagination__middle > .q-btn {
  margin-top: var(--q-pagination-gutter-child);
  margin-left: var(--q-pagination-gutter-child);
}

.q-parallax {
  border-radius: inherit;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.q-parallax__media > img, .q-parallax__media > video {
  will-change: transform;
  min-width: 100%;
  min-height: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.q-popup-edit {
  padding: 8px 16px;
}

.q-popup-edit__buttons {
  margin-top: 8px;
}

.q-popup-edit__buttons .q-btn + .q-btn {
  margin-left: 8px;
}

.q-pull-to-refresh {
  position: relative;
}

.q-pull-to-refresh__puller {
  color: var(--q-primary);
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 4px #0000004d;
}

.q-pull-to-refresh__puller--animating {
  transition: transform .3s, opacity .3s;
}

.q-radio {
  vertical-align: middle;
}

.q-radio__native {
  width: 1px;
  height: 1px;
}

.q-radio__bg, .q-radio__icon-container {
  -webkit-user-select: none;
  user-select: none;
}

.q-radio__bg {
  -webkit-print-color-adjust: exact;
  width: 50%;
  height: 50%;
  top: 25%;
  left: 25%;
}

.q-radio__bg path {
  fill: currentColor;
}

.q-radio__icon {
  color: currentColor;
  font-size: .5em;
}

.q-radio__check {
  transform-origin: 50%;
  transition: transform .22s cubic-bezier(0, 0, .2, 1);
  transform: scale3d(0, 0, 1);
}

.q-radio__inner {
  color: #0000008a;
  border-radius: 50%;
  outline: 0;
  width: 1em;
  min-width: 1em;
  height: 1em;
  font-size: 40px;
}

.q-radio__inner--truthy {
  color: var(--q-primary);
}

.q-radio__inner--truthy .q-radio__check {
  transform: scale3d(1, 1, 1);
}

.q-radio.disabled {
  opacity: .75 !important;
}

.q-radio--dark .q-radio__inner {
  color: #ffffffb3;
}

.q-radio--dark .q-radio__inner:before {
  opacity: .32 !important;
}

.q-radio--dark .q-radio__inner--truthy {
  color: var(--q-primary);
}

.q-radio--dense .q-radio__inner {
  width: .5em;
  min-width: .5em;
  height: .5em;
}

.q-radio--dense .q-radio__bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.q-radio--dense .q-radio__label {
  padding-left: .5em;
}

.q-radio--dense.reverse .q-radio__label {
  padding-left: 0;
  padding-right: .5em;
}

body.desktop .q-radio:not(.disabled) .q-radio__inner:before {
  content: "";
  opacity: .12;
  background: currentColor;
  border-radius: 50%;
  transition: transform .22s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  inset: 0;
  transform: scale3d(0, 0, 1);
}

body.desktop .q-radio:not(.disabled):focus .q-radio__inner:before, body.desktop .q-radio:not(.disabled):hover .q-radio__inner:before {
  transform: scale3d(1, 1, 1);
}

body.desktop .q-radio--dense:not(.disabled):focus .q-radio__inner:before, body.desktop .q-radio--dense:not(.disabled):hover .q-radio__inner:before {
  transform: scale3d(1.5, 1.5, 1);
}

.q-rating {
  color: #ffeb3b;
  vertical-align: middle;
}

.q-rating__icon-container {
  outline: 0;
  height: 1em;
}

.q-rating__icon-container + .q-rating__icon-container {
  margin-left: 2px;
}

.q-rating__icon {
  color: currentColor;
  text-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  opacity: .4;
  transition: transform .2s ease-in, opacity .2s ease-in;
  position: relative;
}

.q-rating__icon--hovered {
  transform: scale(1.3);
}

.q-rating__icon--active {
  opacity: 1;
}

.q-rating__icon--exselected {
  opacity: .7;
}

.q-rating--no-dimming .q-rating__icon {
  opacity: 1;
}

.q-rating--editable .q-rating__icon-container {
  cursor: pointer;
}

.q-responsive {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.q-responsive__filler {
  width: inherit;
  max-width: inherit;
  height: inherit;
  max-height: inherit;
}

.q-responsive__content {
  border-radius: inherit;
}

.q-responsive__content > * {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}

.q-scrollarea {
  contain: strict;
  position: relative;
}

.q-scrollarea__bar, .q-scrollarea__thumb {
  opacity: .2;
  will-change: opacity;
  cursor: grab;
  transition: opacity .3s;
}

.q-scrollarea__bar--v, .q-scrollarea__thumb--v {
  width: 10px;
  right: 0;
}

.q-scrollarea__bar--h, .q-scrollarea__thumb--h {
  height: 10px;
  bottom: 0;
}

.q-scrollarea__bar--invisible, .q-scrollarea__thumb--invisible {
  pointer-events: none;
  opacity: 0 !important;
}

.q-scrollarea__thumb {
  background: #000;
  border-radius: 3px;
}

.q-scrollarea__thumb:hover {
  opacity: .3;
}

.q-scrollarea__thumb:active {
  opacity: .5;
}

.q-scrollarea__content {
  min-width: 100%;
  min-height: 100%;
}

.q-scrollarea--dark .q-scrollarea__thumb {
  background: #fff;
}

.q-select--without-input .q-field__control {
  cursor: pointer;
}

.q-select--with-input .q-field__control {
  cursor: text;
}

.q-select .q-field__input {
  cursor: text;
  min-width: 50px !important;
}

.q-select .q-field__input--padding {
  padding-left: 4px;
}

.q-select__focus-target, .q-select__autocomplete-input {
  opacity: 0;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  outline: 0 !important;
}

.q-select__dropdown-icon {
  cursor: pointer;
  transition: transform .28s;
}

.q-select.q-field--readonly .q-field__control, .q-select.q-field--readonly .q-select__dropdown-icon {
  cursor: default;
}

.q-select__dialog {
  background: #fff;
  flex-direction: column;
  display: flex;
  width: 90vw !important;
  max-width: 90vw !important;
  max-height: calc(100vh - 70px) !important;
}

.q-select__dialog > .scroll {
  background: inherit;
  position: relative;
}

body.mobile:not(.native-mobile) .q-select__dialog {
  max-height: calc(100vh - 108px) !important;
}

body.platform-android.native-mobile .q-dialog__inner--top .q-select__dialog {
  max-height: calc(100vh - 24px) !important;
}

body.platform-android:not(.native-mobile) .q-dialog__inner--top .q-select__dialog {
  max-height: calc(100vh - 80px) !important;
}

body.platform-ios.native-mobile .q-dialog__inner--top > div {
  border-radius: 4px;
}

body.platform-ios.native-mobile .q-dialog__inner--top .q-select__dialog--focused {
  max-height: 47vh !important;
}

body.platform-ios:not(.native-mobile) .q-dialog__inner--top .q-select__dialog--focused {
  max-height: 50vh !important;
}

.q-separator {
  background: #0000001f;
  border: 0;
  flex-shrink: 0;
  margin: 0;
  transition: background .3s, opacity .3s;
}

.q-separator--dark {
  background: #ffffff47;
}

.q-separator--horizontal {
  height: 1px;
  display: block;
}

.q-separator--horizontal-inset {
  margin-left: 16px;
  margin-right: 16px;
}

.q-separator--horizontal-item-inset {
  margin-left: 72px;
  margin-right: 0;
}

.q-separator--horizontal-item-thumbnail-inset {
  margin-left: 116px;
  margin-right: 0;
}

.q-separator--vertical {
  align-self: stretch;
  width: 1px;
  height: auto;
}

.q-separator--vertical-inset {
  margin-top: 8px;
  margin-bottom: 8px;
}

.q-skeleton {
  --q-skeleton-speed: 1.5s;
  box-sizing: border-box;
  background: #0000001f;
  border-radius: 4px;
}

.q-skeleton--anim {
  cursor: wait;
}

.q-skeleton:before {
  content: " ";
}

.q-skeleton--type-text {
  transform: scale(1, .5);
}

.q-skeleton--type-circle, .q-skeleton--type-QAvatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.q-skeleton--type-QBtn {
  width: 90px;
  height: 36px;
}

.q-skeleton--type-QBadge {
  width: 70px;
  height: 16px;
}

.q-skeleton--type-QChip {
  border-radius: 16px;
  width: 90px;
  height: 28px;
}

.q-skeleton--type-QToolbar {
  height: 50px;
}

.q-skeleton--type-QCheckbox, .q-skeleton--type-QRadio {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.q-skeleton--type-QToggle {
  border-radius: 7px;
  width: 56px;
  height: 40px;
}

.q-skeleton--type-QSlider, .q-skeleton--type-QRange {
  height: 40px;
}

.q-skeleton--type-QInput {
  height: 56px;
}

.q-skeleton--bordered {
  border: 1px solid #0000000d;
}

.q-skeleton--square {
  border-radius: 0;
}

.q-skeleton--anim-fade {
  animation: q-skeleton--fade var(--q-skeleton-speed) linear .5s infinite;
}

.q-skeleton--anim-pulse {
  animation: q-skeleton--pulse var(--q-skeleton-speed) ease-in-out .5s infinite;
}

.q-skeleton--anim-pulse-x {
  animation: q-skeleton--pulse-x var(--q-skeleton-speed) ease-in-out .5s infinite;
}

.q-skeleton--anim-pulse-y {
  animation: q-skeleton--pulse-y var(--q-skeleton-speed) ease-in-out .5s infinite;
}

.q-skeleton--anim-wave, .q-skeleton--anim-blink, .q-skeleton--anim-pop {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.q-skeleton--anim-wave:after, .q-skeleton--anim-blink:after, .q-skeleton--anim-pop:after {
  content: "";
  z-index: 0;
  position: absolute;
  inset: 0;
}

.q-skeleton--anim-blink:after {
  animation: q-skeleton--fade var(--q-skeleton-speed) linear .5s infinite;
  background: #ffffffb3;
}

.q-skeleton--anim-wave:after {
  animation: q-skeleton--wave var(--q-skeleton-speed) linear .5s infinite;
  background: linear-gradient(90deg, #fff0, #ffffff80, #fff0);
}

.q-skeleton--dark {
  background: #ffffff0d;
}

.q-skeleton--dark.q-skeleton--bordered {
  border: 1px solid #ffffff40;
}

.q-skeleton--dark.q-skeleton--anim-wave:after {
  background: linear-gradient(90deg, #fff0, #ffffff1a, #fff0);
}

.q-skeleton--dark.q-skeleton--anim-blink:after {
  background: #fff3;
}

@keyframes q-skeleton--fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes q-skeleton--pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes q-skeleton--pulse-x {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(.75);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes q-skeleton--pulse-y {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.75);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes q-skeleton--wave {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.q-slide-item {
  background: #fff;
  position: relative;
}

.q-slide-item__left, .q-slide-item__right, .q-slide-item__top, .q-slide-item__bottom {
  visibility: hidden;
  color: #fff;
  font-size: 14px;
}

.q-slide-item__left .q-icon, .q-slide-item__right .q-icon, .q-slide-item__top .q-icon, .q-slide-item__bottom .q-icon {
  font-size: 1.714em;
}

.q-slide-item__left {
  background: #4caf50;
  padding: 8px 16px;
}

.q-slide-item__left > div {
  transform-origin: 0;
}

.q-slide-item__right {
  background: #ff9800;
  padding: 8px 16px;
}

.q-slide-item__right > div {
  transform-origin: 100%;
}

.q-slide-item__top {
  background: #2196f3;
  padding: 16px 8px;
}

.q-slide-item__top > div {
  transform-origin: top;
}

.q-slide-item__bottom {
  background: #9c27b0;
  padding: 16px 8px;
}

.q-slide-item__bottom > div {
  transform-origin: bottom;
}

.q-slide-item__content {
  background: inherit;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: transform .2s ease-in;
}

.q-slider {
  position: relative;
}

.q-slider--h {
  width: 100%;
}

.q-slider--v {
  height: 200px;
}

.q-slider--editable .q-slider__track-container {
  cursor: grab;
}

.q-slider__track-container {
  outline: 0;
}

.q-slider__track-container--h {
  width: 100%;
  padding: 12px 0;
}

.q-slider__track-container--h .q-slider__selection {
  will-change: width, left;
}

.q-slider__track-container--v {
  height: 100%;
  padding: 0 12px;
}

.q-slider__track-container--v .q-slider__selection {
  will-change: height, top;
}

.q-slider__track {
  color: var(--q-primary);
  width: inherit;
  height: inherit;
  background: #0000001a;
  border-radius: 4px;
}

.q-slider__inner {
  border-radius: inherit;
  background: #0000001a;
  width: 100%;
  height: 100%;
}

.q-slider__selection {
  border-radius: inherit;
  background: currentColor;
  width: 100%;
  height: 100%;
}

.q-slider__markers {
  color: #0000004d;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.q-slider__markers:after {
  content: "";
  background: currentColor;
  position: absolute;
}

.q-slider__markers--h {
  background-image: repeating-linear-gradient(to right, currentColor, currentColor 2px, #fff0 0, #fff0);
}

.q-slider__markers--h:after {
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
}

.q-slider__markers--v {
  background-image: repeating-linear-gradient(currentColor, currentColor 2px, #fff0 0, #fff0);
}

.q-slider__markers--v:after {
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
}

.q-slider__marker-labels-container {
  width: 100%;
  min-width: 24px;
  height: 100%;
  min-height: 24px;
  position: relative;
}

.q-slider__marker-labels {
  position: absolute;
}

.q-slider__marker-labels--h-standard {
  top: 0;
}

.q-slider__marker-labels--h-switched {
  bottom: 0;
}

.q-slider__marker-labels--h-ltr {
  transform: translateX(-50%);
}

.q-slider__marker-labels--h-rtl {
  transform: translateX(50%);
}

.q-slider__marker-labels--v-standard {
  left: 4px;
}

.q-slider__marker-labels--v-switched {
  right: 4px;
}

.q-slider__marker-labels--v-ltr {
  transform: translateY(-50%);
}

.q-slider__marker-labels--v-rtl {
  transform: translateY(50%);
}

.q-slider__thumb {
  z-index: 1;
  color: var(--q-primary);
  outline: 0;
  transition: transform .18s ease-out, fill .18s ease-out, stroke .18s ease-out;
}

.q-slider__thumb.q-slider--focus {
  opacity: 1 !important;
}

.q-slider__thumb--h {
  will-change: left;
  top: 50%;
}

.q-slider__thumb--h-ltr {
  transform: scale(1)translate(-50%, -50%);
}

.q-slider__thumb--h-rtl {
  transform: scale(1)translate(50%, -50%);
}

.q-slider__thumb--v {
  will-change: top;
  left: 50%;
}

.q-slider__thumb--v-ltr {
  transform: scale(1)translate(-50%, -50%);
}

.q-slider__thumb--v-rtl {
  transform: scale(1)translate(-50%, 50%);
}

.q-slider__thumb-shape {
  stroke-width: 3.5px;
  stroke: currentColor;
  transition: transform .28s;
  top: 0;
  left: 0;
}

.q-slider__thumb-shape path {
  stroke: currentColor;
  fill: currentColor;
}

.q-slider__focus-ring {
  opacity: 0;
  border-radius: 50%;
  transition: transform .26667s ease-out .14s, opacity .26667s ease-out .14s, background-color .26667s ease-out .14s;
}

.q-slider__pin {
  opacity: 0;
  white-space: nowrap;
  transition: opacity .28s ease-out .14s;
}

.q-slider__pin:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.q-slider__pin--h:before {
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  left: 50%;
  transform: translateX(-50%);
}

.q-slider__pin--h-standard {
  bottom: 100%;
}

.q-slider__pin--h-standard:before {
  border-top: 6px solid;
  bottom: 2px;
}

.q-slider__pin--h-switched {
  top: 100%;
}

.q-slider__pin--h-switched:before {
  border-bottom: 6px solid;
  top: 2px;
}

.q-slider__pin--v {
  top: 0;
}

.q-slider__pin--v:before {
  border-top: 6px solid #0000;
  border-bottom: 6px solid #0000;
  top: 50%;
  transform: translateY(-50%);
}

.q-slider__pin--v-standard {
  left: 100%;
}

.q-slider__pin--v-standard:before {
  border-right: 6px solid;
  left: 2px;
}

.q-slider__pin--v-switched {
  right: 100%;
}

.q-slider__pin--v-switched:before {
  border-left: 6px solid;
  right: 2px;
}

.q-slider__label {
  z-index: 1;
  white-space: nowrap;
  position: absolute;
}

.q-slider__label--h {
  left: 50%;
  transform: translateX(-50%);
}

.q-slider__label--h-standard {
  bottom: 7px;
}

.q-slider__label--h-switched {
  top: 7px;
}

.q-slider__label--v {
  top: 50%;
  transform: translateY(-50%);
}

.q-slider__label--v-standard {
  left: 7px;
}

.q-slider__label--v-switched {
  right: 7px;
}

.q-slider__text-container {
  text-align: center;
  background: currentColor;
  border-radius: 4px;
  min-height: 25px;
  padding: 2px 8px;
  position: relative;
}

.q-slider__text {
  color: #fff;
  font-size: 12px;
}

.q-slider--no-value .q-slider__thumb, .q-slider--no-value .q-slider__inner, .q-slider--no-value .q-slider__selection {
  opacity: 0;
}

.q-slider--focus .q-slider__focus-ring, body.desktop .q-slider.q-slider--editable .q-slider__track-container:hover .q-slider__focus-ring {
  opacity: .25;
  background: currentColor;
  transform: scale3d(1.55, 1.55, 1);
}

.q-slider--focus .q-slider__thumb, .q-slider--focus .q-slider__inner, .q-slider--focus .q-slider__selection, body.desktop .q-slider.q-slider--editable .q-slider__track-container:hover .q-slider__thumb, body.desktop .q-slider.q-slider--editable .q-slider__track-container:hover .q-slider__inner, body.desktop .q-slider.q-slider--editable .q-slider__track-container:hover .q-slider__selection {
  opacity: 1;
}

.q-slider--inactive .q-slider__thumb--h {
  transition: left .28s, right .28s;
}

.q-slider--inactive .q-slider__thumb--v {
  transition: top .28s, bottom .28s;
}

.q-slider--inactive .q-slider__selection {
  transition: width .28s, left .28s, right .28s, height .28s, top .28s, bottom .28s;
}

.q-slider--inactive .q-slider__text-container {
  transition: transform .28s;
}

.q-slider--active {
  cursor: grabbing;
}

.q-slider--active .q-slider__thumb-shape {
  transform: scale(1.5);
}

.q-slider--active .q-slider__focus-ring, .q-slider--active.q-slider--label .q-slider__thumb-shape {
  transform: scale(0) !important;
}

body.desktop .q-slider.q-slider--enabled .q-slider__track-container:hover .q-slider__pin, .q-slider--label.q-slider--active .q-slider__pin, .q-slider--label .q-slider--focus .q-slider__pin, .q-slider--label.q-slider--label-always .q-slider__pin {
  opacity: 1;
}

.q-slider--dark .q-slider__track, .q-slider--dark .q-slider__inner {
  background: #ffffff1a;
}

.q-slider--dark .q-slider__markers {
  color: #ffffff4d;
}

.q-slider--dense .q-slider__track-container--h {
  padding: 6px 0;
}

.q-slider--dense .q-slider__track-container--v {
  padding: 0 6px;
}

.q-space {
  flex-grow: 1 !important;
}

.q-spinner {
  vertical-align: middle;
}

.q-spinner-mat {
  transform-origin: center;
  animation: 2s linear infinite q-spin;
}

.q-spinner-mat .path {
  stroke-dasharray: 1 200;
  stroke-dashoffset: 0;
  animation: 1.5s ease-in-out infinite q-mat-dash;
}

@keyframes q-spin {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }

  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }

  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }

  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 359deg);
  }
}

@keyframes q-mat-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

.q-splitter__panel {
  z-index: 0;
  position: relative;
}

.q-splitter__panel > .q-splitter {
  width: 100%;
  height: 100%;
}

.q-splitter__separator {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background-color: #0000001f;
  position: relative;
}

.q-splitter__separator-area > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.q-splitter--dark .q-splitter__separator {
  background-color: #ffffff47;
}

.q-splitter--vertical > .q-splitter__panel {
  height: 100%;
}

.q-splitter--vertical.q-splitter--active {
  cursor: col-resize;
}

.q-splitter--vertical > .q-splitter__separator {
  width: 1px;
}

.q-splitter--vertical > .q-splitter__separator > div {
  left: -6px;
  right: -6px;
}

.q-splitter--vertical.q-splitter--workable > .q-splitter__separator {
  cursor: col-resize;
}

.q-splitter--horizontal > .q-splitter__panel {
  width: 100%;
}

.q-splitter--horizontal.q-splitter--active {
  cursor: row-resize;
}

.q-splitter--horizontal > .q-splitter__separator {
  height: 1px;
}

.q-splitter--horizontal > .q-splitter__separator > div {
  top: -6px;
  bottom: -6px;
}

.q-splitter--horizontal.q-splitter--workable > .q-splitter__separator {
  cursor: row-resize;
}

.q-splitter__before, .q-splitter__after {
  overflow: auto;
}

.q-stepper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-stepper__title {
  letter-spacing: .1px;
  font-size: 14px;
  line-height: 18px;
}

.q-stepper__caption {
  font-size: 12px;
  line-height: 14px;
}

.q-stepper__dot {
  contain: layout;
  background: currentColor;
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
  font-size: 14px;
}

.q-stepper__dot span {
  color: #fff;
}

.q-stepper__tab {
  color: #9e9e9e;
  flex-direction: row;
  padding: 8px 24px;
  font-size: 14px;
}

.q-stepper--dark {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-stepper--dark .q-stepper__dot span {
  color: #000;
}

.q-stepper__tab--navigation {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.q-stepper__tab--active, .q-stepper__tab--done {
  color: var(--q-primary);
}

.q-stepper__tab--active .q-stepper__dot, .q-stepper__tab--active .q-stepper__label, .q-stepper__tab--done .q-stepper__dot, .q-stepper__tab--done .q-stepper__label {
  text-shadow: 0 0;
}

.q-stepper__tab--disabled .q-stepper__dot {
  background: #00000038;
}

.q-stepper__tab--disabled .q-stepper__label {
  color: #00000052;
}

.q-stepper__tab--error {
  color: var(--q-negative);
}

.q-stepper__tab--error-with-icon .q-stepper__dot {
  background: none !important;
}

.q-stepper__tab--error-with-icon .q-stepper__dot span {
  color: currentColor;
  font-size: 24px;
}

.q-stepper__header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.q-stepper__header--border {
  border-bottom: 1px solid #0000001f;
}

.q-stepper__header--standard-labels .q-stepper__tab {
  justify-content: center;
  min-height: 72px;
}

.q-stepper__header--standard-labels .q-stepper__tab:first-child {
  justify-content: flex-start;
}

.q-stepper__header--standard-labels .q-stepper__tab:last-child {
  justify-content: flex-end;
}

.q-stepper__header--standard-labels .q-stepper__tab:only-child {
  justify-content: center;
}

.q-stepper__header--standard-labels .q-stepper__dot:after {
  display: none;
}

.q-stepper__header--alternative-labels .q-stepper__tab {
  flex-direction: column;
  justify-content: flex-start;
  min-height: 104px;
  padding: 24px 32px;
}

.q-stepper__header--alternative-labels .q-stepper__dot {
  margin-right: 0;
}

.q-stepper__header--alternative-labels .q-stepper__label {
  text-align: center;
  margin-top: 8px;
}

.q-stepper__header--alternative-labels .q-stepper__label:before, .q-stepper__header--alternative-labels .q-stepper__label:after {
  display: none;
}

.q-stepper__header--contracted, .q-stepper__header--contracted.q-stepper__header--alternative-labels .q-stepper__tab {
  min-height: 72px;
}

.q-stepper__header--contracted.q-stepper__header--alternative-labels .q-stepper__tab:first-child {
  align-items: flex-start;
}

.q-stepper__header--contracted.q-stepper__header--alternative-labels .q-stepper__tab:last-child {
  align-items: flex-end;
}

.q-stepper__header--contracted .q-stepper__tab {
  padding: 24px 0;
}

.q-stepper__header--contracted .q-stepper__tab:first-child .q-stepper__dot {
  transform: translateX(24px);
}

.q-stepper__header--contracted .q-stepper__tab:last-child .q-stepper__dot {
  transform: translateX(-24px);
}

.q-stepper__header--contracted .q-stepper__tab:not(:last-child) .q-stepper__dot:after {
  display: block !important;
}

.q-stepper__header--contracted .q-stepper__dot {
  margin: 0;
}

.q-stepper__header--contracted .q-stepper__label {
  display: none;
}

.q-stepper__nav {
  padding-top: 24px;
}

.q-stepper--flat {
  box-shadow: none;
}

.q-stepper--bordered {
  border: 1px solid #0000001f;
}

.q-stepper--horizontal .q-stepper__step-inner {
  padding: 24px;
}

.q-stepper--horizontal .q-stepper__tab:first-child {
  border-top-left-radius: inherit;
}

.q-stepper--horizontal .q-stepper__tab:last-child {
  border-top-right-radius: inherit;
}

.q-stepper--horizontal .q-stepper__tab:first-child .q-stepper__dot:before, .q-stepper--horizontal .q-stepper__tab:last-child .q-stepper__label:after, .q-stepper--horizontal .q-stepper__tab:last-child .q-stepper__dot:after {
  display: none;
}

.q-stepper--horizontal .q-stepper__tab {
  overflow: hidden;
}

.q-stepper--horizontal .q-stepper__line {
  contain: layout;
}

.q-stepper--horizontal .q-stepper__line:before, .q-stepper--horizontal .q-stepper__line:after {
  background: #0000001f;
  width: 100vw;
  height: 1px;
  position: absolute;
  top: 50%;
}

.q-stepper--horizontal .q-stepper__label:after, .q-stepper--horizontal .q-stepper__dot:after {
  content: "";
  margin-left: 8px;
  left: 100%;
}

.q-stepper--horizontal .q-stepper__dot:before {
  content: "";
  margin-right: 8px;
  right: 100%;
}

.q-stepper--horizontal > .q-stepper__nav {
  padding: 0 24px 24px;
}

.q-stepper--vertical {
  padding: 16px 0;
}

.q-stepper--vertical .q-stepper__tab {
  padding: 12px 24px;
}

.q-stepper--vertical .q-stepper__title {
  line-height: 18px;
}

.q-stepper--vertical .q-stepper__step-inner {
  padding: 0 24px 32px 60px;
}

.q-stepper--vertical > .q-stepper__nav {
  padding: 24px 24px 0;
}

.q-stepper--vertical .q-stepper__step {
  overflow: hidden;
}

.q-stepper--vertical .q-stepper__dot {
  margin-right: 12px;
}

.q-stepper--vertical .q-stepper__dot:before, .q-stepper--vertical .q-stepper__dot:after {
  content: "";
  background: #0000001f;
  width: 1px;
  height: 99999px;
  position: absolute;
  left: 50%;
}

.q-stepper--vertical .q-stepper__dot:before {
  margin-bottom: 8px;
  bottom: 100%;
}

.q-stepper--vertical .q-stepper__dot:after {
  margin-top: 8px;
  top: 100%;
}

.q-stepper--vertical .q-stepper__step:first-child .q-stepper__dot:before, .q-stepper--vertical .q-stepper__step:last-child .q-stepper__dot:after {
  display: none;
}

.q-stepper--vertical .q-stepper__step:last-child .q-stepper__step-inner {
  padding-bottom: 8px;
}

.q-stepper--dark.q-stepper--bordered, .q-stepper--dark .q-stepper__header--border {
  border-color: #ffffff47;
}

.q-stepper--dark.q-stepper--horizontal .q-stepper__line:before, .q-stepper--dark.q-stepper--horizontal .q-stepper__line:after, .q-stepper--dark.q-stepper--vertical .q-stepper__dot:before, .q-stepper--dark.q-stepper--vertical .q-stepper__dot:after {
  background: #ffffff47;
}

.q-stepper--dark .q-stepper__tab--disabled {
  color: #ffffff47;
}

.q-stepper--dark .q-stepper__tab--disabled .q-stepper__dot {
  background: #ffffff47;
}

.q-stepper--dark .q-stepper__tab--disabled .q-stepper__label {
  color: #ffffff8a;
}

.q-tab-panels {
  background: #fff;
}

.q-tab-panel {
  padding: 16px;
}

.q-markup-table {
  background: #fff;
  overflow: auto;
}

.q-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}

.q-table thead tr, .q-table tbody td {
  height: 48px;
}

.q-table th {
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 500;
}

.q-table th.sortable {
  cursor: pointer;
}

.q-table th.sortable:hover .q-table__sort-icon {
  opacity: .64;
}

.q-table th.sorted .q-table__sort-icon {
  opacity: .86 !important;
}

.q-table th.sort-desc .q-table__sort-icon {
  transform: rotate(180deg);
}

.q-table th, .q-table td {
  background-color: inherit;
  padding: 7px 16px;
}

.q-table thead, .q-table td, .q-table th {
  border-style: solid;
  border-width: 0;
}

.q-table tbody td {
  font-size: 13px;
}

.q-table__card {
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-table__card .q-table__middle {
  flex: auto;
}

.q-table__card .q-table__top, .q-table__card .q-table__bottom {
  flex: none;
}

.q-table__container {
  position: relative;
}

.q-table__container > div:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.q-table__container > div:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.q-table__container > .q-inner-loading {
  border-radius: inherit !important;
}

.q-table__top {
  padding: 12px 16px;
}

.q-table__top .q-table__control {
  flex-wrap: wrap;
}

.q-table__title {
  letter-spacing: .005em;
  font-size: 20px;
  font-weight: 400;
}

.q-table__separator {
  min-width: 8px !important;
}

.q-table__progress {
  height: 0 !important;
}

.q-table__progress th {
  border: 0 !important;
  padding: 0 !important;
}

.q-table__progress .q-linear-progress {
  position: absolute;
  bottom: 0;
}

.q-table__middle {
  max-width: 100%;
}

.q-table__bottom {
  min-height: 50px;
  padding: 4px 14px 4px 16px;
  font-size: 12px;
}

.q-table__bottom .q-table__control {
  min-height: 24px;
}

.q-table__bottom-nodata-icon {
  margin-right: 8px;
  font-size: 200%;
}

.q-table__bottom-item {
  margin-right: 16px;
}

.q-table__control {
  align-items: center;
  display: flex;
}

.q-table__sort-icon {
  opacity: 0;
  font-size: 120%;
  transition: transform .3s cubic-bezier(.25, .8, .5, 1);
}

.q-table__sort-icon--left, .q-table__sort-icon--center {
  margin-left: 4px;
}

.q-table__sort-icon--right {
  margin-right: 4px;
}

.q-table--col-auto-width {
  width: 1px;
}

.q-table__card--dark, .q-table--dark {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-table--flat {
  box-shadow: none;
}

.q-table--bordered {
  border: 1px solid #0000001f;
}

.q-table--square {
  border-radius: 0;
}

.q-table__linear-progress {
  height: 2px;
}

.q-table--no-wrap th, .q-table--no-wrap td {
  white-space: nowrap;
}

.q-table--grid {
  box-shadow: none;
  border-radius: 4px;
}

.q-table--grid .q-table__top {
  padding-bottom: 4px;
}

.q-table--grid .q-table__middle {
  min-height: 2px;
  margin-bottom: 4px;
}

.q-table--grid .q-table__middle thead, .q-table--grid .q-table__middle thead th {
  border: 0 !important;
}

.q-table--grid .q-table__linear-progress {
  bottom: 0;
}

.q-table--grid .q-table__bottom {
  border-top: 0;
}

.q-table--grid .q-table__grid-content {
  flex: auto;
}

.q-table--grid.fullscreen {
  background: inherit;
}

.q-table__grid-item-card {
  vertical-align: top;
  padding: 12px;
}

.q-table__grid-item-card .q-separator {
  margin: 12px 0;
}

.q-table__grid-item-row + .q-table__grid-item-row {
  margin-top: 8px;
}

.q-table__grid-item-title {
  opacity: .54;
  font-size: 12px;
  font-weight: 500;
}

.q-table__grid-item-value {
  font-size: 13px;
}

.q-table__grid-item {
  padding: 4px;
  transition: transform .3s cubic-bezier(.25, .8, .5, 1);
}

.q-table__grid-item--selected {
  transform: scale(.95);
}

.q-table--horizontal-separator thead th, .q-table--horizontal-separator tbody tr:not(:last-child) > td, .q-table--cell-separator thead th, .q-table--cell-separator tbody tr:not(:last-child) > td {
  border-bottom-width: 1px;
}

.q-table--vertical-separator td, .q-table--vertical-separator th, .q-table--cell-separator td, .q-table--cell-separator th {
  border-left-width: 1px;
}

.q-table--vertical-separator thead tr:last-child th, .q-table--vertical-separator.q-table--loading tr:nth-last-child(2) th, .q-table--cell-separator thead tr:last-child th, .q-table--cell-separator.q-table--loading tr:nth-last-child(2) th {
  border-bottom-width: 1px;
}

.q-table--vertical-separator td:first-child, .q-table--vertical-separator th:first-child, .q-table--cell-separator td:first-child, .q-table--cell-separator th:first-child {
  border-left: 0;
}

.q-table--vertical-separator .q-table__top, .q-table--cell-separator .q-table__top {
  border-bottom: 1px solid #0000001f;
}

.q-table--dense .q-table__top {
  padding: 6px 16px;
}

.q-table--dense .q-table__bottom {
  min-height: 33px;
}

.q-table--dense .q-table__sort-icon {
  font-size: 110%;
}

.q-table--dense .q-table th, .q-table--dense .q-table td {
  padding: 4px 8px;
}

.q-table--dense .q-table thead tr, .q-table--dense .q-table tbody tr, .q-table--dense .q-table tbody td {
  height: 28px;
}

.q-table--dense .q-table th:first-child, .q-table--dense .q-table td:first-child {
  padding-left: 16px;
}

.q-table--dense .q-table th:last-child, .q-table--dense .q-table td:last-child {
  padding-right: 16px;
}

.q-table--dense .q-table__bottom-item {
  margin-right: 8px;
}

.q-table--dense .q-table__select .q-field__control, .q-table--dense .q-table__select .q-field__native {
  min-height: 24px;
  padding: 0;
}

.q-table--dense .q-table__select .q-field__marginal {
  height: 24px;
}

.q-table__bottom {
  border-top: 1px solid #0000001f;
}

.q-table thead, .q-table tr, .q-table th, .q-table td {
  border-color: #0000001f;
}

.q-table tbody td {
  position: relative;
}

.q-table tbody td:before, .q-table tbody td:after {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.q-table tbody td:before {
  background: #00000008;
}

.q-table tbody td:after {
  background: #0000000f;
}

.q-table tbody tr.selected td:after, body.desktop .q-table > tbody > tr:not(.q-tr--no-hover):hover > td:not(.q-td--no-hover):before {
  content: "";
}

.q-table__card--dark, .q-table--dark, .q-table--dark .q-table__bottom, .q-table--dark thead, .q-table--dark tr, .q-table--dark th, .q-table--dark td {
  border-color: #ffffff47;
}

.q-table--dark tbody td:before {
  background: #ffffff12;
}

.q-table--dark tbody td:after {
  background: #ffffff1a;
}

.q-table--dark.q-table--vertical-separator .q-table__top, .q-table--dark.q-table--cell-separator .q-table__top {
  border-color: #ffffff47;
}

.q-tab {
  text-transform: uppercase;
  white-space: nowrap;
  color: inherit;
  min-height: 48px;
  padding: 0 16px;
  text-decoration: none;
  transition: color .3s, background-color .3s;
}

.q-tab--full {
  min-height: 72px;
}

.q-tab--no-caps {
  text-transform: none;
}

.q-tab__content {
  height: inherit;
  min-width: 40px;
  padding: 4px 0;
}

.q-tab__content--inline .q-tab__icon + .q-tab__label {
  padding-left: 8px;
}

.q-tab__content .q-chip--floating {
  top: 0;
  right: -16px;
}

.q-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.q-tab__label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.715em;
}

.q-tab .q-badge {
  top: 3px;
  right: -12px;
}

.q-tab__alert, .q-tab__alert-icon {
  position: absolute;
}

.q-tab__alert {
  background: currentColor;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 7px;
  right: -9px;
}

.q-tab__alert-icon {
  font-size: 18px;
  top: 2px;
  right: -12px;
}

.q-tab__indicator {
  opacity: 0;
  background: currentColor;
  height: 2px;
}

.q-tab--active .q-tab__indicator {
  opacity: 1;
  transform-origin: 0;
}

.q-tab--inactive {
  opacity: .85;
}

.q-tabs {
  transition: color .3s, background-color .3s;
  position: relative;
}

.q-tabs--scrollable.q-tabs__arrows--outside.q-tabs--horizontal {
  padding-left: 36px;
  padding-right: 36px;
}

.q-tabs--scrollable.q-tabs__arrows--outside.q-tabs--vertical {
  padding-top: 36px;
  padding-bottom: 36px;
}

.q-tabs--scrollable.q-tabs__arrows--outside .q-tabs__arrow--faded {
  opacity: .3;
  pointer-events: none;
}

.q-tabs--scrollable.q-tabs__arrows--inside .q-tabs__arrow--faded {
  display: none;
}

.q-tabs--not-scrollable.q-tabs__arrows--outside, body.mobile .q-tabs--scrollable.q-tabs--mobile-without-arrows.q-tabs__arrows--outside {
  padding-left: 0;
  padding-right: 0;
}

.q-tabs--not-scrollable .q-tabs__arrow, body.mobile .q-tabs--scrollable.q-tabs--mobile-without-arrows .q-tabs__arrow {
  display: none;
}

.q-tabs--not-scrollable .q-tabs__content, body.mobile .q-tabs--scrollable.q-tabs--mobile-without-arrows .q-tabs__content {
  border-radius: inherit;
}

.q-tabs__arrow {
  cursor: pointer;
  text-shadow: 0 0 3px #fff, 0 0 1px #fff, 0 0 1px #000;
  min-width: 36px;
  font-size: 32px;
  transition: opacity .3s;
}

.q-tabs__content {
  flex: auto;
  overflow: hidden;
}

.q-tabs__content--align-center {
  justify-content: center;
}

.q-tabs__content--align-right {
  justify-content: flex-end;
}

.q-tabs__content--align-justify .q-tab {
  flex: auto;
}

.q-tabs__offset {
  display: none;
}

.q-tabs--horizontal .q-tabs__arrow {
  height: 100%;
}

.q-tabs--horizontal .q-tabs__arrow--left {
  top: 0;
  bottom: 0;
  left: 0;
}

.q-tabs--horizontal .q-tabs__arrow--right {
  top: 0;
  bottom: 0;
  right: 0;
}

.q-tabs--vertical, .q-tabs--vertical .q-tabs__content {
  height: 100%;
  display: block !important;
}

.q-tabs--vertical .q-tabs__arrow {
  text-align: center;
  width: 100%;
  height: 36px;
}

.q-tabs--vertical .q-tabs__arrow--left {
  top: 0;
  left: 0;
  right: 0;
}

.q-tabs--vertical .q-tabs__arrow--right {
  bottom: 0;
  left: 0;
  right: 0;
}

.q-tabs--vertical .q-tab {
  padding: 0 8px;
}

.q-tabs--vertical .q-tab__indicator {
  height: unset;
  width: 2px;
}

.q-tabs--vertical.q-tabs--not-scrollable .q-tabs__content {
  height: 100%;
}

.q-tabs--vertical.q-tabs--dense .q-tab__content {
  min-width: 24px;
}

.q-tabs--dense .q-tab {
  min-height: 36px;
}

.q-tabs--dense .q-tab--full {
  min-height: 52px;
}

.q-time {
  background: #fff;
  border-radius: 4px;
  outline: 0;
  width: 290px;
  min-width: 290px;
  max-width: 100%;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-time--bordered {
  border: 1px solid #0000001f;
}

.q-time__header {
  border-top-left-radius: inherit;
  color: #fff;
  background-color: var(--q-primary);
  padding: 16px;
  font-weight: 300;
}

.q-time__actions {
  padding: 0 16px 16px;
}

.q-time__header-label {
  letter-spacing: -.00833em;
  font-size: 28px;
  line-height: 1;
}

.q-time__header-label > div + div {
  margin-left: 4px;
}

.q-time__link {
  opacity: .56;
  outline: 0;
  transition: opacity .3s ease-out;
}

.q-time__link--active, .q-time__link:hover, .q-time__link:focus {
  opacity: 1;
}

.q-time__header-ampm {
  letter-spacing: .1em;
  font-size: 16px;
}

.q-time__content {
  padding: 16px;
}

.q-time__content:before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.q-time__container-parent {
  padding: 16px;
}

.q-time__container-child {
  background: #0000001f;
  border-radius: 50%;
}

.q-time__clock {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 24px;
  font-size: 14px;
}

.q-time__clock-circle {
  position: relative;
}

.q-time__clock-center {
  background: currentColor;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  min-height: 0;
  margin: auto;
}

.q-time__clock-pointer {
  transform-origin: 0 0;
  color: var(--q-primary);
  background: currentColor;
  width: 2px;
  height: 50%;
  min-height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

.q-time__clock-pointer:before, .q-time__clock-pointer:after {
  content: "";
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.q-time__clock-pointer:before {
  width: 8px;
  height: 8px;
  bottom: -4px;
}

.q-time__clock-pointer:after {
  width: 6px;
  height: 6px;
  top: -3px;
}

.q-time__clock-position {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-height: 32px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 32px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.q-time__clock-position--disable {
  opacity: .4;
}

.q-time__clock-position--active {
  background-color: var(--q-primary);
  color: #fff;
}

.q-time__clock-pos-0 {
  top: 0%;
  left: 50%;
}

.q-time__clock-pos-1 {
  top: 6.7%;
  left: 75%;
}

.q-time__clock-pos-2 {
  top: 25%;
  left: 93.3%;
}

.q-time__clock-pos-3 {
  top: 50%;
  left: 100%;
}

.q-time__clock-pos-4 {
  top: 75%;
  left: 93.3%;
}

.q-time__clock-pos-5 {
  top: 93.3%;
  left: 75%;
}

.q-time__clock-pos-6 {
  top: 100%;
  left: 50%;
}

.q-time__clock-pos-7 {
  top: 93.3%;
  left: 25%;
}

.q-time__clock-pos-8 {
  top: 75%;
  left: 6.7%;
}

.q-time__clock-pos-9 {
  top: 50%;
  left: 0%;
}

.q-time__clock-pos-10 {
  top: 25%;
  left: 6.7%;
}

.q-time__clock-pos-11 {
  top: 6.7%;
  left: 25%;
}

.q-time__clock-pos-12 {
  top: 15%;
  left: 50%;
}

.q-time__clock-pos-13 {
  top: 19.69%;
  left: 67.5%;
}

.q-time__clock-pos-14 {
  top: 32.5%;
  left: 80.31%;
}

.q-time__clock-pos-15 {
  top: 50%;
  left: 85%;
}

.q-time__clock-pos-16 {
  top: 67.5%;
  left: 80.31%;
}

.q-time__clock-pos-17 {
  top: 80.31%;
  left: 67.5%;
}

.q-time__clock-pos-18 {
  top: 85%;
  left: 50%;
}

.q-time__clock-pos-19 {
  top: 80.31%;
  left: 32.5%;
}

.q-time__clock-pos-20 {
  top: 67.5%;
  left: 19.69%;
}

.q-time__clock-pos-21 {
  top: 50%;
  left: 15%;
}

.q-time__clock-pos-22 {
  top: 32.5%;
  left: 19.69%;
}

.q-time__clock-pos-23 {
  top: 19.69%;
  left: 32.5%;
}

.q-time__now-button {
  background-color: var(--q-primary);
  color: #fff;
  top: 12px;
  right: 12px;
}

.q-time.disabled .q-time__header-ampm, .q-time.disabled .q-time__content, .q-time--readonly .q-time__header-ampm, .q-time--readonly .q-time__content {
  pointer-events: none;
}

.q-time--portrait {
  flex-direction: column;
  display: inline-flex;
}

.q-time--portrait .q-time__header {
  border-top-right-radius: inherit;
  min-height: 86px;
}

.q-time--portrait .q-time__header-ampm {
  margin-left: 12px;
}

.q-time--portrait.q-time--bordered .q-time__content {
  margin: 1px 0;
}

.q-time--landscape {
  align-items: stretch;
  min-width: 420px;
  display: inline-flex;
}

.q-time--landscape > div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.q-time--landscape .q-time__header {
  border-bottom-left-radius: inherit;
  min-width: 156px;
}

.q-time--landscape .q-time__header-ampm {
  margin-top: 12px;
}

.q-time--dark {
  border-color: #ffffff47;
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-timeline {
  width: 100%;
  padding: 0;
  list-style: none;
}

.q-timeline h6 {
  line-height: inherit;
}

.q-timeline--dark {
  color: #fff;
}

.q-timeline--dark .q-timeline__subtitle {
  opacity: .7;
}

.q-timeline__content {
  padding-bottom: 24px;
}

.q-timeline__title {
  margin-top: 0;
  margin-bottom: 16px;
}

.q-timeline__subtitle {
  opacity: .6;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 700;
}

.q-timeline__dot {
  width: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.q-timeline__dot:before, .q-timeline__dot:after {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
}

.q-timeline__dot:before {
  border: 3px solid #0000;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  transition: background .3s ease-in-out, border .3s ease-in-out;
  top: 4px;
  left: 0;
}

.q-timeline__dot:after {
  opacity: .4;
  width: 3px;
  top: 24px;
  bottom: 0;
  left: 6px;
}

.q-timeline__dot .q-icon {
  color: #fff;
  width: 100%;
  height: 38px;
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.q-timeline__dot .q-icon > svg, .q-timeline__dot .q-icon > img {
  width: 1em;
  height: 1em;
}

.q-timeline__dot-img {
  background: currentColor;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
}

.q-timeline__heading {
  position: relative;
}

.q-timeline__heading:first-child .q-timeline__heading-title {
  padding-top: 0;
}

.q-timeline__heading:last-child .q-timeline__heading-title {
  padding-bottom: 0;
}

.q-timeline__heading-title {
  margin: 0;
  padding: 32px 0;
}

.q-timeline__entry {
  line-height: 22px;
  position: relative;
}

.q-timeline__entry:last-child {
  padding-bottom: 0 !important;
}

.q-timeline__entry:last-child .q-timeline__dot:after {
  content: none;
}

.q-timeline__entry--icon .q-timeline__dot {
  width: 31px;
}

.q-timeline__entry--icon .q-timeline__dot:before {
  width: 31px;
  height: 31px;
}

.q-timeline__entry--icon .q-timeline__dot:after {
  top: 41px;
  left: 14px;
}

.q-timeline__entry--icon .q-timeline__subtitle {
  padding-top: 8px;
}

.q-timeline--dense--right .q-timeline__entry {
  padding-left: 40px;
}

.q-timeline--dense--right .q-timeline__entry--icon .q-timeline__dot {
  left: -8px;
}

.q-timeline--dense--right .q-timeline__dot {
  left: 0;
}

.q-timeline--dense--left .q-timeline__heading {
  text-align: right;
}

.q-timeline--dense--left .q-timeline__entry {
  padding-right: 40px;
}

.q-timeline--dense--left .q-timeline__entry--icon .q-timeline__dot {
  right: -8px;
}

.q-timeline--dense--left .q-timeline__content, .q-timeline--dense--left .q-timeline__title, .q-timeline--dense--left .q-timeline__subtitle {
  text-align: right;
}

.q-timeline--dense--left .q-timeline__dot {
  right: 0;
}

.q-timeline--comfortable {
  display: table;
}

.q-timeline--comfortable .q-timeline__heading {
  font-size: 200%;
  display: table-row;
}

.q-timeline--comfortable .q-timeline__heading > div {
  display: table-cell;
}

.q-timeline--comfortable .q-timeline__entry {
  padding: 0;
  display: table-row;
}

.q-timeline--comfortable .q-timeline__entry--icon .q-timeline__content {
  padding-top: 8px;
}

.q-timeline--comfortable .q-timeline__subtitle, .q-timeline--comfortable .q-timeline__dot, .q-timeline--comfortable .q-timeline__content {
  vertical-align: top;
  display: table-cell;
}

.q-timeline--comfortable .q-timeline__subtitle {
  width: 35%;
}

.q-timeline--comfortable .q-timeline__dot {
  min-width: 31px;
  position: relative;
}

.q-timeline--comfortable--right .q-timeline__heading .q-timeline__heading-title {
  margin-left: -50px;
}

.q-timeline--comfortable--right .q-timeline__subtitle {
  text-align: right;
  padding-right: 30px;
}

.q-timeline--comfortable--right .q-timeline__content {
  padding-left: 30px;
}

.q-timeline--comfortable--right .q-timeline__entry--icon .q-timeline__dot {
  left: -8px;
}

.q-timeline--comfortable--left .q-timeline__heading {
  text-align: right;
}

.q-timeline--comfortable--left .q-timeline__heading .q-timeline__heading-title {
  margin-right: -50px;
}

.q-timeline--comfortable--left .q-timeline__subtitle {
  padding-left: 30px;
}

.q-timeline--comfortable--left .q-timeline__content {
  padding-right: 30px;
}

.q-timeline--comfortable--left .q-timeline__content, .q-timeline--comfortable--left .q-timeline__title {
  text-align: right;
}

.q-timeline--comfortable--left .q-timeline__entry--icon .q-timeline__dot {
  right: 0;
}

.q-timeline--comfortable--left .q-timeline__dot {
  right: -8px;
}

.q-timeline--loose .q-timeline__heading-title {
  text-align: center;
  margin-left: 0;
}

.q-timeline--loose .q-timeline__entry, .q-timeline--loose .q-timeline__subtitle, .q-timeline--loose .q-timeline__dot, .q-timeline--loose .q-timeline__content {
  margin: 0;
  padding: 0;
  display: block;
}

.q-timeline--loose .q-timeline__dot {
  margin-left: -7.15px;
  position: absolute;
  left: 50%;
}

.q-timeline--loose .q-timeline__entry {
  padding-bottom: 24px;
  overflow: hidden;
}

.q-timeline--loose .q-timeline__entry--icon .q-timeline__dot {
  margin-left: -15px;
}

.q-timeline--loose .q-timeline__entry--icon .q-timeline__subtitle {
  line-height: 38px;
}

.q-timeline--loose .q-timeline__entry--icon .q-timeline__content {
  padding-top: 8px;
}

.q-timeline--loose .q-timeline__entry--left .q-timeline__content, .q-timeline--loose .q-timeline__entry--right .q-timeline__subtitle {
  float: left;
  text-align: right;
  padding-right: 30px;
}

.q-timeline--loose .q-timeline__entry--left .q-timeline__subtitle, .q-timeline--loose .q-timeline__entry--right .q-timeline__content {
  float: right;
  text-align: left;
  padding-left: 30px;
}

.q-timeline--loose .q-timeline__subtitle, .q-timeline--loose .q-timeline__content {
  width: 50%;
}

.q-toggle {
  vertical-align: middle;
}

.q-toggle__native {
  width: 1px;
  height: 1px;
}

.q-toggle__track {
  opacity: .38;
  background: currentColor;
  border-radius: .175em;
  height: .35em;
}

.q-toggle__thumb {
  -webkit-user-select: none;
  user-select: none;
  z-index: 0;
  width: .5em;
  height: .5em;
  transition: left .22s cubic-bezier(.4, 0, .2, 1);
  top: .25em;
  left: .25em;
}

.q-toggle__thumb:after {
  content: "";
  background: #fff;
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.q-toggle__thumb .q-icon {
  color: #000;
  opacity: .54;
  z-index: 1;
  min-width: 1em;
  font-size: .3em;
}

.q-toggle__inner {
  -webkit-print-color-adjust: exact;
  width: 1.4em;
  min-width: 1.4em;
  height: 1em;
  padding: .325em .3em;
  font-size: 40px;
}

.q-toggle__inner--indet .q-toggle__thumb {
  left: .45em;
}

.q-toggle__inner--truthy {
  color: var(--q-primary);
}

.q-toggle__inner--truthy .q-toggle__track {
  opacity: .54;
}

.q-toggle__inner--truthy .q-toggle__thumb {
  left: .65em;
}

.q-toggle__inner--truthy .q-toggle__thumb:after {
  background-color: currentColor;
}

.q-toggle__inner--truthy .q-toggle__thumb .q-icon {
  color: #fff;
  opacity: 1;
}

.q-toggle.disabled {
  opacity: .75 !important;
}

.q-toggle--dark .q-toggle__inner {
  color: #fff;
}

.q-toggle--dark .q-toggle__inner--truthy {
  color: var(--q-primary);
}

.q-toggle--dark .q-toggle__thumb:after {
  box-shadow: none;
}

.q-toggle--dark .q-toggle__thumb:before {
  opacity: .32 !important;
}

.q-toggle--dense .q-toggle__inner {
  width: .8em;
  min-width: .8em;
  height: .5em;
  padding: .07625em 0;
}

.q-toggle--dense .q-toggle__thumb {
  top: 0;
  left: 0;
}

.q-toggle--dense .q-toggle__inner--indet .q-toggle__thumb {
  left: .15em;
}

.q-toggle--dense .q-toggle__inner--truthy .q-toggle__thumb {
  left: .3em;
}

.q-toggle--dense .q-toggle__label {
  padding-left: .5em;
}

.q-toggle--dense.reverse .q-toggle__label {
  padding-left: 0;
  padding-right: .5em;
}

body.desktop .q-toggle:not(.disabled) .q-toggle__thumb:before {
  content: "";
  opacity: .12;
  background: currentColor;
  border-radius: 50%;
  transition: transform .22s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  inset: 0;
  transform: scale3d(0, 0, 1);
}

body.desktop .q-toggle:not(.disabled):focus .q-toggle__thumb:before, body.desktop .q-toggle:not(.disabled):hover .q-toggle__thumb:before {
  transform: scale3d(2, 2, 1);
}

body.desktop .q-toggle--dense:not(.disabled):focus .q-toggle__thumb:before, body.desktop .q-toggle--dense:not(.disabled):hover .q-toggle__thumb:before {
  transform: scale3d(1.5, 1.5, 1);
}

.q-toolbar {
  width: 100%;
  min-height: 50px;
  padding: 0 12px;
  position: relative;
}

.q-toolbar--inset {
  padding-left: 58px;
}

.q-toolbar .q-avatar {
  font-size: 38px;
}

.q-toolbar__title {
  letter-spacing: .01em;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding: 0 12px;
  font-size: 21px;
  font-weight: normal;
}

.q-toolbar__title:first-child {
  padding-left: 0;
}

.q-toolbar__title:last-child {
  padding-right: 0;
}

.q-tooltip--style {
  color: #fafafa;
  text-transform: none;
  background: #757575;
  border-radius: 4px;
  font-size: 10px;
  font-weight: normal;
}

.q-tooltip {
  z-index: 9000;
  padding: 6px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed !important;
}

@media (width <= 599.98px) {
  .q-tooltip {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.q-tree {
  color: #9e9e9e;
  position: relative;
}

.q-tree__node {
  padding: 0 0 3px 22px;
}

.q-tree__node:after {
  content: "";
  border-left: 1px solid;
  width: 2px;
  position: absolute;
  inset: -3px auto 0 -13px;
}

.q-tree__node:last-child:after {
  display: none;
}

.q-tree__node--disabled {
  pointer-events: none;
}

.q-tree__node--disabled .disabled {
  opacity: 1 !important;
}

.q-tree__node--disabled > div, .q-tree__node--disabled > i, .q-tree__node--disabled > .disabled {
  opacity: .6 !important;
}

.q-tree__node--disabled > div .q-tree__node--disabled > div, .q-tree__node--disabled > div .q-tree__node--disabled > i, .q-tree__node--disabled > div .q-tree__node--disabled > .disabled, .q-tree__node--disabled > i .q-tree__node--disabled > div, .q-tree__node--disabled > i .q-tree__node--disabled > i, .q-tree__node--disabled > i .q-tree__node--disabled > .disabled, .q-tree__node--disabled > .disabled .q-tree__node--disabled > div, .q-tree__node--disabled > .disabled .q-tree__node--disabled > i, .q-tree__node--disabled > .disabled .q-tree__node--disabled > .disabled {
  opacity: 1 !important;
}

.q-tree__node-header:before {
  content: "";
  border-bottom: 1px solid;
  border-left: 1px solid;
  width: 31px;
  position: absolute;
  top: -3px;
  bottom: 50%;
  left: -35px;
}

.q-tree__children {
  padding-left: 25px;
}

.q-tree__node-body {
  padding: 5px 0 8px 5px;
}

.q-tree__node--parent {
  padding-left: 2px;
}

.q-tree__node--parent > .q-tree__node-header:before {
  width: 15px;
  left: -15px;
}

.q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body {
  padding: 5px 0 8px 27px;
}

.q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body:after {
  content: "";
  border-left: 1px solid;
  width: 2px;
  height: 100%;
  position: absolute;
  inset: 0 auto 50px 12px;
}

.q-tree__node--link {
  cursor: pointer;
}

.q-tree__node-header {
  border-radius: 4px;
  outline: 0;
  margin-top: 3px;
  padding: 4px;
}

.q-tree__node-header-content {
  color: #000;
  transition: color .3s;
}

.q-tree__node--selected .q-tree__node-header-content {
  color: #9e9e9e;
}

.q-tree__icon, .q-tree__node-header-content .q-icon {
  font-size: 21px;
}

.q-tree__img {
  border-radius: 2px;
  height: 42px;
}

.q-tree__avatar, .q-tree__node-header-content .q-avatar {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 28px;
}

.q-tree__arrow, .q-tree__spinner {
  margin-right: 4px;
  font-size: 16px;
}

.q-tree__arrow {
  transition: transform .3s;
}

.q-tree__arrow--rotate {
  transform: rotate3d(0, 0, 1, 90deg);
}

.q-tree__tickbox {
  margin-right: 4px;
}

.q-tree > .q-tree__node {
  padding: 0;
}

.q-tree > .q-tree__node:after, .q-tree > .q-tree__node > .q-tree__node-header:before {
  display: none;
}

.q-tree > .q-tree__node--child > .q-tree__node-header {
  padding-left: 24px;
}

.q-tree--dark .q-tree__node-header-content {
  color: #fff;
}

.q-tree--no-connectors .q-tree__node:after, .q-tree--no-connectors .q-tree__node-header:before, .q-tree--no-connectors .q-tree__node-body:after {
  display: none !important;
}

.q-tree--dense > .q-tree__node--child > .q-tree__node-header {
  padding-left: 1px;
}

.q-tree--dense .q-tree__arrow, .q-tree--dense .q-tree__spinner {
  margin-right: 1px;
}

.q-tree--dense .q-tree__img {
  height: 32px;
}

.q-tree--dense .q-tree__tickbox {
  margin-right: 3px;
}

.q-tree--dense .q-tree__node {
  padding: 0;
}

.q-tree--dense .q-tree__node:after {
  top: 0;
  left: -8px;
}

.q-tree--dense .q-tree__node-header {
  margin-top: 0;
  padding: 1px;
}

.q-tree--dense .q-tree__node-header:before {
  width: 8px;
  top: 0;
  left: -8px;
}

.q-tree--dense .q-tree__node--child {
  padding-left: 17px;
}

.q-tree--dense .q-tree__node--child > .q-tree__node-header:before {
  width: 21px;
  left: -25px;
}

.q-tree--dense .q-tree__node-body {
  padding: 0 0 2px;
}

.q-tree--dense .q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body {
  padding: 0 0 2px 20px;
}

.q-tree--dense .q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body:after {
  left: 8px;
}

.q-tree--dense .q-tree__children {
  padding-left: 16px;
}

[dir="rtl"] .q-tree__arrow {
  transform: rotate3d(0, 0, 1, 180deg);
}

[dir="rtl"] .q-tree__arrow--rotate {
  transform: rotate3d(0, 0, 1, 90deg);
}

.q-uploader {
  vertical-align: top;
  background: #fff;
  border-radius: 4px;
  width: 320px;
  max-height: 320px;
  position: relative;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-uploader--bordered {
  border: 1px solid #0000001f;
}

.q-uploader__input {
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.q-uploader__input::-webkit-file-upload-button {
  cursor: pointer;
}

.q-uploader__file:before {
  content: "";
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  pointer-events: none;
  opacity: .04;
  background: currentColor;
  position: absolute;
  inset: 0;
}

.q-uploader__header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: var(--q-primary);
  color: #fff;
  width: 100%;
  position: relative;
}

.q-uploader__spinner {
  margin-right: 4px;
  font-size: 24px;
}

.q-uploader__header-content {
  padding: 8px;
}

.q-uploader__dnd {
  outline-offset: -4px;
  background: #fff9;
  outline: 1px dashed;
}

.q-uploader__overlay {
  color: #000;
  background-color: #fff9;
  font-size: 36px;
}

.q-uploader__list {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  flex: auto;
  min-height: 60px;
  padding: 8px;
  position: relative;
}

.q-uploader__file {
  border: 1px solid #0000001f;
  border-radius: 4px 4px 0 0;
}

.q-uploader__file .q-circular-progress {
  font-size: 24px;
}

.q-uploader__file--img {
  color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 200px;
  height: 200px;
}

.q-uploader__file--img:before {
  content: none;
}

.q-uploader__file--img .q-circular-progress {
  color: #fff;
}

.q-uploader__file--img .q-uploader__file-header {
  background: linear-gradient(#000000b3 20%, #fff0);
  padding-bottom: 24px;
}

.q-uploader__file + .q-uploader__file {
  margin-top: 8px;
}

.q-uploader__file-header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 4px 8px;
  position: relative;
}

.q-uploader__file-header-content {
  padding-right: 8px;
}

.q-uploader__file-status {
  margin-right: 4px;
  font-size: 24px;
}

.q-uploader__title {
  word-break: break-word;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.q-uploader__subtitle {
  font-size: 12px;
  line-height: 18px;
}

.q-uploader--disable .q-uploader__header, .q-uploader--disable .q-uploader__list {
  pointer-events: none;
}

.q-uploader--dark {
  border-color: #ffffff47;
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

.q-uploader--dark .q-uploader__file {
  border-color: #ffffff47;
}

.q-uploader--dark .q-uploader__dnd, .q-uploader--dark .q-uploader__overlay {
  background: #ffffff4d;
}

.q-uploader--dark .q-uploader__overlay {
  color: #fff;
}

.q-video {
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.q-video iframe, .q-video object, .q-video embed {
  width: 100%;
  height: 100%;
}

.q-video--responsive {
  height: 0;
}

.q-video--responsive iframe, .q-video--responsive object, .q-video--responsive embed {
  position: absolute;
  top: 0;
  left: 0;
}

.q-virtual-scroll:focus {
  outline: 0;
}

.q-virtual-scroll__content {
  contain: content;
  outline: none;
}

.q-virtual-scroll__content > * {
  overflow-anchor: none;
}

.q-virtual-scroll__content > [data-q-vs-anchor] {
  overflow-anchor: auto;
}

.q-virtual-scroll__padding {
  background: linear-gradient(#fff0, #fff0 20%, #80808008 20%, #80808014 50%, #80808008 80%, #fff0 80%, #fff0);
  background-size: var(--q-virtual-scroll-item-width, 100%) var(--q-virtual-scroll-item-height, 50px);
}

.q-table .q-virtual-scroll__padding tr {
  height: 0 !important;
}

.q-table .q-virtual-scroll__padding td {
  padding: 0 !important;
}

.q-virtual-scroll--horizontal {
  flex-flow: row;
  align-items: stretch;
  display: flex;
}

.q-virtual-scroll--horizontal .q-virtual-scroll__content {
  flex-flow: row;
  display: flex;
}

.q-virtual-scroll--horizontal .q-virtual-scroll__padding, .q-virtual-scroll--horizontal .q-virtual-scroll__content, .q-virtual-scroll--horizontal .q-virtual-scroll__content > * {
  flex: none;
}

.q-virtual-scroll--horizontal .q-virtual-scroll__padding {
  background: linear-gradient(to left, #fff0, #fff0 20%, #80808008 20%, #80808014 50%, #80808008 80%, #fff0 80%, #fff0);
  background-size: var(--q-virtual-scroll-item-width, 50px) var(--q-virtual-scroll-item-height, 100%);
}

.q-ripple {
  color: inherit;
  border-radius: inherit;
  z-index: 0;
  pointer-events: none;
  contain: strict;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.q-ripple__inner {
  opacity: 0;
  color: inherit;
  pointer-events: none;
  will-change: transform, opacity;
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.q-ripple__inner--enter {
  transition: transform .225s cubic-bezier(.4, 0, .2, 1), opacity .1s cubic-bezier(.4, 0, .2, 1);
}

.q-ripple__inner--leave {
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
}

.q-morph--invisible, .q-morph--internal {
  opacity: 0 !important;
  pointer-events: none !important;
  position: fixed !important;
  bottom: 200vh !important;
  right: 200vw !important;
}

.q-loading {
  color: #000;
  position: fixed !important;
}

.q-loading__backdrop {
  opacity: .5;
  z-index: -1;
  background-color: #000;
  transition: background-color .28s;
  position: fixed;
  inset: 0;
}

.q-loading__box {
  color: #fff;
  border-radius: 4px;
  max-width: 450px;
  padding: 18px;
}

.q-loading__message {
  text-align: center;
  margin: 40px 20px 0;
}

.q-notifications__list {
  z-index: 9500;
  pointer-events: none;
  margin-bottom: 10px;
  position: relative;
  left: 0;
  right: 0;
}

.q-notifications__list--center {
  top: 0;
  bottom: 0;
}

.q-notifications__list--top {
  top: 0;
}

.q-notifications__list--bottom {
  bottom: 0;
}

body.q-ios-padding .q-notifications__list--center, body.q-ios-padding .q-notifications__list--top {
  top: 20px;
  top: env(safe-area-inset-top);
}

body.q-ios-padding .q-notifications__list--center, body.q-ios-padding .q-notifications__list--bottom {
  bottom: env(safe-area-inset-bottom);
}

.q-notification {
  pointer-events: all;
  z-index: 9500;
  color: #fff;
  background: #323232;
  border-radius: 4px;
  flex-shrink: 0;
  max-width: 95vw;
  margin: 10px 10px 0;
  font-size: 14px;
  transition: transform 1s, opacity 1s;
  display: inline-flex;
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.q-notification__icon {
  flex: 0 0 1em;
  font-size: 24px;
}

.q-notification__icon--additional {
  margin-right: 16px;
}

.q-notification__avatar {
  font-size: 32px;
}

.q-notification__avatar--additional {
  margin-right: 8px;
}

.q-notification__spinner {
  font-size: 32px;
}

.q-notification__spinner--additional {
  margin-right: 8px;
}

.q-notification__message {
  padding: 8px 0;
}

.q-notification__caption {
  opacity: .7;
  font-size: .9em;
}

.q-notification__actions {
  color: var(--q-primary);
}

.q-notification__badge {
  background-color: var(--q-negative);
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
  animation: .42s q-notif-badge;
  position: absolute;
  box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
}

.q-notification__badge--top-left, .q-notification__badge--top-right {
  top: -6px;
}

.q-notification__badge--bottom-left, .q-notification__badge--bottom-right {
  bottom: -6px;
}

.q-notification__badge--top-left, .q-notification__badge--bottom-left {
  left: -22px;
}

.q-notification__badge--top-right, .q-notification__badge--bottom-right {
  right: -22px;
}

.q-notification__progress {
  z-index: -1;
  opacity: .3;
  transform-origin: 0;
  background: currentColor;
  border-radius: 4px 4px 0 0;
  height: 3px;
  animation: linear q-notif-progress;
  position: absolute;
  bottom: 0;
  left: -10px;
  right: -10px;
  transform: scaleX(0);
}

.q-notification--standard {
  min-height: 48px;
  padding: 0 16px;
}

.q-notification--standard .q-notification__actions {
  margin-right: -8px;
  padding: 6px 0 6px 8px;
}

.q-notification--multi-line {
  min-height: 68px;
  padding: 8px 16px;
}

.q-notification--multi-line .q-notification__badge--top-left, .q-notification--multi-line .q-notification__badge--top-right {
  top: -15px;
}

.q-notification--multi-line .q-notification__badge--bottom-left, .q-notification--multi-line .q-notification__badge--bottom-right {
  bottom: -15px;
}

.q-notification--multi-line .q-notification__progress {
  bottom: -8px;
}

.q-notification--multi-line .q-notification__actions {
  padding: 0;
}

.q-notification--multi-line .q-notification__actions--with-media {
  padding-left: 25px;
}

.q-notification--top-left-enter-from, .q-notification--top-left-leave-to, .q-notification--top-enter-from, .q-notification--top-leave-to, .q-notification--top-right-enter-from, .q-notification--top-right-leave-to {
  opacity: 0;
  z-index: 9499;
  transform: translateY(-50px);
}

.q-notification--left-enter-from, .q-notification--left-leave-to, .q-notification--center-enter-from, .q-notification--center-leave-to, .q-notification--right-enter-from, .q-notification--right-leave-to {
  opacity: 0;
  z-index: 9499;
  transform: rotateX(90deg);
}

.q-notification--bottom-left-enter-from, .q-notification--bottom-left-leave-to, .q-notification--bottom-enter-from, .q-notification--bottom-leave-to, .q-notification--bottom-right-enter-from, .q-notification--bottom-right-leave-to {
  opacity: 0;
  z-index: 9499;
  transform: translateY(50px);
}

.q-notification--top-left-leave-active, .q-notification--top-leave-active, .q-notification--top-right-leave-active, .q-notification--left-leave-active, .q-notification--center-leave-active, .q-notification--right-leave-active, .q-notification--bottom-left-leave-active, .q-notification--bottom-leave-active, .q-notification--bottom-right-leave-active {
  z-index: 9499;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
}

.q-notification--top-leave-active, .q-notification--center-leave-active {
  top: 0;
}

.q-notification--bottom-left-leave-active, .q-notification--bottom-leave-active, .q-notification--bottom-right-leave-active {
  bottom: 0;
}

@media (width >= 600px) {
  .q-notification {
    max-width: 65vw;
  }
}

@keyframes q-notif-badge {
  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }
}

@keyframes q-notif-progress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

:root {
  --animate-duration: .3s;
  --animate-delay: .3s;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * .8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*="Out"] {
    opacity: 0;
  }
}

.q-animate--scale {
  animation: .15s cubic-bezier(.25, .8, .25, 1) q-scale;
}

@keyframes q-scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}

.q-animate--fade {
  animation: .2s q-fade;
}

@keyframes q-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

:root {
  --q-primary: #1976d2;
  --q-secondary: #26a69a;
  --q-accent: #9c27b0;
  --q-positive: #21ba45;
  --q-negative: #c10015;
  --q-info: #31ccec;
  --q-warning: #f2c037;
  --q-dark: #1d1d1d;
  --q-dark-page: #121212;
}

.text-dark {
  color: var(--q-dark) !important;
}

.bg-dark {
  background: var(--q-dark) !important;
}

.text-primary {
  color: var(--q-primary) !important;
}

.bg-primary {
  background: var(--q-primary) !important;
}

.text-secondary {
  color: var(--q-secondary) !important;
}

.bg-secondary {
  background: var(--q-secondary) !important;
}

.text-accent {
  color: var(--q-accent) !important;
}

.bg-accent {
  background: var(--q-accent) !important;
}

.text-positive {
  color: var(--q-positive) !important;
}

.bg-positive {
  background: var(--q-positive) !important;
}

.text-negative {
  color: var(--q-negative) !important;
}

.bg-negative {
  background: var(--q-negative) !important;
}

.text-info {
  color: var(--q-info) !important;
}

.bg-info {
  background: var(--q-info) !important;
}

.text-warning {
  color: var(--q-warning) !important;
}

.bg-warning {
  background: var(--q-warning) !important;
}

.text-white {
  color: #fff !important;
}

.bg-white {
  background: #fff !important;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.text-transparent {
  color: #0000 !important;
}

.bg-transparent {
  background: none !important;
}

.text-separator {
  color: #0000001f !important;
}

.bg-separator {
  background: #0000001f !important;
}

.text-dark-separator {
  color: #ffffff47 !important;
}

.bg-dark-separator {
  background: #ffffff47 !important;
}

.text-red {
  color: #f44336 !important;
}

.text-red-1 {
  color: #ffebee !important;
}

.text-red-2 {
  color: #ffcdd2 !important;
}

.text-red-3 {
  color: #ef9a9a !important;
}

.text-red-4 {
  color: #e57373 !important;
}

.text-red-5 {
  color: #ef5350 !important;
}

.text-red-6 {
  color: #f44336 !important;
}

.text-red-7 {
  color: #e53935 !important;
}

.text-red-8 {
  color: #d32f2f !important;
}

.text-red-9 {
  color: #c62828 !important;
}

.text-red-10 {
  color: #b71c1c !important;
}

.text-red-11 {
  color: #ff8a80 !important;
}

.text-red-12 {
  color: #ff5252 !important;
}

.text-red-13 {
  color: #ff1744 !important;
}

.text-red-14 {
  color: #d50000 !important;
}

.text-pink {
  color: #e91e63 !important;
}

.text-pink-1 {
  color: #fce4ec !important;
}

.text-pink-2 {
  color: #f8bbd0 !important;
}

.text-pink-3 {
  color: #f48fb1 !important;
}

.text-pink-4 {
  color: #f06292 !important;
}

.text-pink-5 {
  color: #ec407a !important;
}

.text-pink-6 {
  color: #e91e63 !important;
}

.text-pink-7 {
  color: #d81b60 !important;
}

.text-pink-8 {
  color: #c2185b !important;
}

.text-pink-9 {
  color: #ad1457 !important;
}

.text-pink-10 {
  color: #880e4f !important;
}

.text-pink-11 {
  color: #ff80ab !important;
}

.text-pink-12 {
  color: #ff4081 !important;
}

.text-pink-13 {
  color: #f50057 !important;
}

.text-pink-14 {
  color: #c51162 !important;
}

.text-purple {
  color: #9c27b0 !important;
}

.text-purple-1 {
  color: #f3e5f5 !important;
}

.text-purple-2 {
  color: #e1bee7 !important;
}

.text-purple-3 {
  color: #ce93d8 !important;
}

.text-purple-4 {
  color: #ba68c8 !important;
}

.text-purple-5 {
  color: #ab47bc !important;
}

.text-purple-6 {
  color: #9c27b0 !important;
}

.text-purple-7 {
  color: #8e24aa !important;
}

.text-purple-8 {
  color: #7b1fa2 !important;
}

.text-purple-9 {
  color: #6a1b9a !important;
}

.text-purple-10 {
  color: #4a148c !important;
}

.text-purple-11 {
  color: #ea80fc !important;
}

.text-purple-12 {
  color: #e040fb !important;
}

.text-purple-13 {
  color: #d500f9 !important;
}

.text-purple-14 {
  color: #a0f !important;
}

.text-deep-purple {
  color: #673ab7 !important;
}

.text-deep-purple-1 {
  color: #ede7f6 !important;
}

.text-deep-purple-2 {
  color: #d1c4e9 !important;
}

.text-deep-purple-3 {
  color: #b39ddb !important;
}

.text-deep-purple-4 {
  color: #9575cd !important;
}

.text-deep-purple-5 {
  color: #7e57c2 !important;
}

.text-deep-purple-6 {
  color: #673ab7 !important;
}

.text-deep-purple-7 {
  color: #5e35b1 !important;
}

.text-deep-purple-8 {
  color: #512da8 !important;
}

.text-deep-purple-9 {
  color: #4527a0 !important;
}

.text-deep-purple-10 {
  color: #311b92 !important;
}

.text-deep-purple-11 {
  color: #b388ff !important;
}

.text-deep-purple-12 {
  color: #7c4dff !important;
}

.text-deep-purple-13 {
  color: #651fff !important;
}

.text-deep-purple-14 {
  color: #6200ea !important;
}

.text-indigo {
  color: #3f51b5 !important;
}

.text-indigo-1 {
  color: #e8eaf6 !important;
}

.text-indigo-2 {
  color: #c5cae9 !important;
}

.text-indigo-3 {
  color: #9fa8da !important;
}

.text-indigo-4 {
  color: #7986cb !important;
}

.text-indigo-5 {
  color: #5c6bc0 !important;
}

.text-indigo-6 {
  color: #3f51b5 !important;
}

.text-indigo-7 {
  color: #3949ab !important;
}

.text-indigo-8 {
  color: #303f9f !important;
}

.text-indigo-9 {
  color: #283593 !important;
}

.text-indigo-10 {
  color: #1a237e !important;
}

.text-indigo-11 {
  color: #8c9eff !important;
}

.text-indigo-12 {
  color: #536dfe !important;
}

.text-indigo-13 {
  color: #3d5afe !important;
}

.text-indigo-14 {
  color: #304ffe !important;
}

.text-blue {
  color: #2196f3 !important;
}

.text-blue-1 {
  color: #e3f2fd !important;
}

.text-blue-2 {
  color: #bbdefb !important;
}

.text-blue-3 {
  color: #90caf9 !important;
}

.text-blue-4 {
  color: #64b5f6 !important;
}

.text-blue-5 {
  color: #42a5f5 !important;
}

.text-blue-6 {
  color: #2196f3 !important;
}

.text-blue-7 {
  color: #1e88e5 !important;
}

.text-blue-8 {
  color: #1976d2 !important;
}

.text-blue-9 {
  color: #1565c0 !important;
}

.text-blue-10 {
  color: #0d47a1 !important;
}

.text-blue-11 {
  color: #82b1ff !important;
}

.text-blue-12 {
  color: #448aff !important;
}

.text-blue-13 {
  color: #2979ff !important;
}

.text-blue-14 {
  color: #2962ff !important;
}

.text-light-blue {
  color: #03a9f4 !important;
}

.text-light-blue-1 {
  color: #e1f5fe !important;
}

.text-light-blue-2 {
  color: #b3e5fc !important;
}

.text-light-blue-3 {
  color: #81d4fa !important;
}

.text-light-blue-4 {
  color: #4fc3f7 !important;
}

.text-light-blue-5 {
  color: #29b6f6 !important;
}

.text-light-blue-6 {
  color: #03a9f4 !important;
}

.text-light-blue-7 {
  color: #039be5 !important;
}

.text-light-blue-8 {
  color: #0288d1 !important;
}

.text-light-blue-9 {
  color: #0277bd !important;
}

.text-light-blue-10 {
  color: #01579b !important;
}

.text-light-blue-11 {
  color: #80d8ff !important;
}

.text-light-blue-12 {
  color: #40c4ff !important;
}

.text-light-blue-13 {
  color: #00b0ff !important;
}

.text-light-blue-14 {
  color: #0091ea !important;
}

.text-cyan {
  color: #00bcd4 !important;
}

.text-cyan-1 {
  color: #e0f7fa !important;
}

.text-cyan-2 {
  color: #b2ebf2 !important;
}

.text-cyan-3 {
  color: #80deea !important;
}

.text-cyan-4 {
  color: #4dd0e1 !important;
}

.text-cyan-5 {
  color: #26c6da !important;
}

.text-cyan-6 {
  color: #00bcd4 !important;
}

.text-cyan-7 {
  color: #00acc1 !important;
}

.text-cyan-8 {
  color: #0097a7 !important;
}

.text-cyan-9 {
  color: #00838f !important;
}

.text-cyan-10 {
  color: #006064 !important;
}

.text-cyan-11 {
  color: #84ffff !important;
}

.text-cyan-12 {
  color: #18ffff !important;
}

.text-cyan-13 {
  color: #00e5ff !important;
}

.text-cyan-14 {
  color: #00b8d4 !important;
}

.text-teal {
  color: #009688 !important;
}

.text-teal-1 {
  color: #e0f2f1 !important;
}

.text-teal-2 {
  color: #b2dfdb !important;
}

.text-teal-3 {
  color: #80cbc4 !important;
}

.text-teal-4 {
  color: #4db6ac !important;
}

.text-teal-5 {
  color: #26a69a !important;
}

.text-teal-6 {
  color: #009688 !important;
}

.text-teal-7 {
  color: #00897b !important;
}

.text-teal-8 {
  color: #00796b !important;
}

.text-teal-9 {
  color: #00695c !important;
}

.text-teal-10 {
  color: #004d40 !important;
}

.text-teal-11 {
  color: #a7ffeb !important;
}

.text-teal-12 {
  color: #64ffda !important;
}

.text-teal-13 {
  color: #1de9b6 !important;
}

.text-teal-14 {
  color: #00bfa5 !important;
}

.text-green {
  color: #4caf50 !important;
}

.text-green-1 {
  color: #e8f5e9 !important;
}

.text-green-2 {
  color: #c8e6c9 !important;
}

.text-green-3 {
  color: #a5d6a7 !important;
}

.text-green-4 {
  color: #81c784 !important;
}

.text-green-5 {
  color: #66bb6a !important;
}

.text-green-6 {
  color: #4caf50 !important;
}

.text-green-7 {
  color: #43a047 !important;
}

.text-green-8 {
  color: #388e3c !important;
}

.text-green-9 {
  color: #2e7d32 !important;
}

.text-green-10 {
  color: #1b5e20 !important;
}

.text-green-11 {
  color: #b9f6ca !important;
}

.text-green-12 {
  color: #69f0ae !important;
}

.text-green-13 {
  color: #00e676 !important;
}

.text-green-14 {
  color: #00c853 !important;
}

.text-light-green {
  color: #8bc34a !important;
}

.text-light-green-1 {
  color: #f1f8e9 !important;
}

.text-light-green-2 {
  color: #dcedc8 !important;
}

.text-light-green-3 {
  color: #c5e1a5 !important;
}

.text-light-green-4 {
  color: #aed581 !important;
}

.text-light-green-5 {
  color: #9ccc65 !important;
}

.text-light-green-6 {
  color: #8bc34a !important;
}

.text-light-green-7 {
  color: #7cb342 !important;
}

.text-light-green-8 {
  color: #689f38 !important;
}

.text-light-green-9 {
  color: #558b2f !important;
}

.text-light-green-10 {
  color: #33691e !important;
}

.text-light-green-11 {
  color: #ccff90 !important;
}

.text-light-green-12 {
  color: #b2ff59 !important;
}

.text-light-green-13 {
  color: #76ff03 !important;
}

.text-light-green-14 {
  color: #64dd17 !important;
}

.text-lime {
  color: #cddc39 !important;
}

.text-lime-1 {
  color: #f9fbe7 !important;
}

.text-lime-2 {
  color: #f0f4c3 !important;
}

.text-lime-3 {
  color: #e6ee9c !important;
}

.text-lime-4 {
  color: #dce775 !important;
}

.text-lime-5 {
  color: #d4e157 !important;
}

.text-lime-6 {
  color: #cddc39 !important;
}

.text-lime-7 {
  color: #c0ca33 !important;
}

.text-lime-8 {
  color: #afb42b !important;
}

.text-lime-9 {
  color: #9e9d24 !important;
}

.text-lime-10 {
  color: #827717 !important;
}

.text-lime-11 {
  color: #f4ff81 !important;
}

.text-lime-12 {
  color: #eeff41 !important;
}

.text-lime-13 {
  color: #c6ff00 !important;
}

.text-lime-14 {
  color: #aeea00 !important;
}

.text-yellow {
  color: #ffeb3b !important;
}

.text-yellow-1 {
  color: #fffde7 !important;
}

.text-yellow-2 {
  color: #fff9c4 !important;
}

.text-yellow-3 {
  color: #fff59d !important;
}

.text-yellow-4 {
  color: #fff176 !important;
}

.text-yellow-5 {
  color: #ffee58 !important;
}

.text-yellow-6 {
  color: #ffeb3b !important;
}

.text-yellow-7 {
  color: #fdd835 !important;
}

.text-yellow-8 {
  color: #fbc02d !important;
}

.text-yellow-9 {
  color: #f9a825 !important;
}

.text-yellow-10 {
  color: #f57f17 !important;
}

.text-yellow-11 {
  color: #ffff8d !important;
}

.text-yellow-12 {
  color: #ff0 !important;
}

.text-yellow-13 {
  color: #ffea00 !important;
}

.text-yellow-14 {
  color: #ffd600 !important;
}

.text-amber {
  color: #ffc107 !important;
}

.text-amber-1 {
  color: #fff8e1 !important;
}

.text-amber-2 {
  color: #ffecb3 !important;
}

.text-amber-3 {
  color: #ffe082 !important;
}

.text-amber-4 {
  color: #ffd54f !important;
}

.text-amber-5 {
  color: #ffca28 !important;
}

.text-amber-6 {
  color: #ffc107 !important;
}

.text-amber-7 {
  color: #ffb300 !important;
}

.text-amber-8 {
  color: #ffa000 !important;
}

.text-amber-9 {
  color: #ff8f00 !important;
}

.text-amber-10 {
  color: #ff6f00 !important;
}

.text-amber-11 {
  color: #ffe57f !important;
}

.text-amber-12 {
  color: #ffd740 !important;
}

.text-amber-13 {
  color: #ffc400 !important;
}

.text-amber-14 {
  color: #ffab00 !important;
}

.text-orange {
  color: #ff9800 !important;
}

.text-orange-1 {
  color: #fff3e0 !important;
}

.text-orange-2 {
  color: #ffe0b2 !important;
}

.text-orange-3 {
  color: #ffcc80 !important;
}

.text-orange-4 {
  color: #ffb74d !important;
}

.text-orange-5 {
  color: #ffa726 !important;
}

.text-orange-6 {
  color: #ff9800 !important;
}

.text-orange-7 {
  color: #fb8c00 !important;
}

.text-orange-8 {
  color: #f57c00 !important;
}

.text-orange-9 {
  color: #ef6c00 !important;
}

.text-orange-10 {
  color: #e65100 !important;
}

.text-orange-11 {
  color: #ffd180 !important;
}

.text-orange-12 {
  color: #ffab40 !important;
}

.text-orange-13 {
  color: #ff9100 !important;
}

.text-orange-14 {
  color: #ff6d00 !important;
}

.text-deep-orange {
  color: #ff5722 !important;
}

.text-deep-orange-1 {
  color: #fbe9e7 !important;
}

.text-deep-orange-2 {
  color: #ffccbc !important;
}

.text-deep-orange-3 {
  color: #ffab91 !important;
}

.text-deep-orange-4 {
  color: #ff8a65 !important;
}

.text-deep-orange-5 {
  color: #ff7043 !important;
}

.text-deep-orange-6 {
  color: #ff5722 !important;
}

.text-deep-orange-7 {
  color: #f4511e !important;
}

.text-deep-orange-8 {
  color: #e64a19 !important;
}

.text-deep-orange-9 {
  color: #d84315 !important;
}

.text-deep-orange-10 {
  color: #bf360c !important;
}

.text-deep-orange-11 {
  color: #ff9e80 !important;
}

.text-deep-orange-12 {
  color: #ff6e40 !important;
}

.text-deep-orange-13 {
  color: #ff3d00 !important;
}

.text-deep-orange-14 {
  color: #dd2c00 !important;
}

.text-brown {
  color: #795548 !important;
}

.text-brown-1 {
  color: #efebe9 !important;
}

.text-brown-2 {
  color: #d7ccc8 !important;
}

.text-brown-3 {
  color: #bcaaa4 !important;
}

.text-brown-4 {
  color: #a1887f !important;
}

.text-brown-5 {
  color: #8d6e63 !important;
}

.text-brown-6 {
  color: #795548 !important;
}

.text-brown-7 {
  color: #6d4c41 !important;
}

.text-brown-8 {
  color: #5d4037 !important;
}

.text-brown-9 {
  color: #4e342e !important;
}

.text-brown-10 {
  color: #3e2723 !important;
}

.text-brown-11 {
  color: #d7ccc8 !important;
}

.text-brown-12 {
  color: #bcaaa4 !important;
}

.text-brown-13 {
  color: #8d6e63 !important;
}

.text-brown-14 {
  color: #5d4037 !important;
}

.text-grey {
  color: #9e9e9e !important;
}

.text-grey-1 {
  color: #fafafa !important;
}

.text-grey-2 {
  color: #f5f5f5 !important;
}

.text-grey-3 {
  color: #eee !important;
}

.text-grey-4 {
  color: #e0e0e0 !important;
}

.text-grey-5 {
  color: #bdbdbd !important;
}

.text-grey-6 {
  color: #9e9e9e !important;
}

.text-grey-7 {
  color: #757575 !important;
}

.text-grey-8 {
  color: #616161 !important;
}

.text-grey-9 {
  color: #424242 !important;
}

.text-grey-10 {
  color: #212121 !important;
}

.text-grey-11 {
  color: #f5f5f5 !important;
}

.text-grey-12 {
  color: #eee !important;
}

.text-grey-13 {
  color: #bdbdbd !important;
}

.text-grey-14 {
  color: #616161 !important;
}

.text-blue-grey {
  color: #607d8b !important;
}

.text-blue-grey-1 {
  color: #eceff1 !important;
}

.text-blue-grey-2 {
  color: #cfd8dc !important;
}

.text-blue-grey-3 {
  color: #b0bec5 !important;
}

.text-blue-grey-4 {
  color: #90a4ae !important;
}

.text-blue-grey-5 {
  color: #78909c !important;
}

.text-blue-grey-6 {
  color: #607d8b !important;
}

.text-blue-grey-7 {
  color: #546e7a !important;
}

.text-blue-grey-8 {
  color: #455a64 !important;
}

.text-blue-grey-9 {
  color: #37474f !important;
}

.text-blue-grey-10 {
  color: #263238 !important;
}

.text-blue-grey-11 {
  color: #cfd8dc !important;
}

.text-blue-grey-12 {
  color: #b0bec5 !important;
}

.text-blue-grey-13 {
  color: #78909c !important;
}

.text-blue-grey-14 {
  color: #455a64 !important;
}

.bg-red {
  background: #f44336 !important;
}

.bg-red-1 {
  background: #ffebee !important;
}

.bg-red-2 {
  background: #ffcdd2 !important;
}

.bg-red-3 {
  background: #ef9a9a !important;
}

.bg-red-4 {
  background: #e57373 !important;
}

.bg-red-5 {
  background: #ef5350 !important;
}

.bg-red-6 {
  background: #f44336 !important;
}

.bg-red-7 {
  background: #e53935 !important;
}

.bg-red-8 {
  background: #d32f2f !important;
}

.bg-red-9 {
  background: #c62828 !important;
}

.bg-red-10 {
  background: #b71c1c !important;
}

.bg-red-11 {
  background: #ff8a80 !important;
}

.bg-red-12 {
  background: #ff5252 !important;
}

.bg-red-13 {
  background: #ff1744 !important;
}

.bg-red-14 {
  background: #d50000 !important;
}

.bg-pink {
  background: #e91e63 !important;
}

.bg-pink-1 {
  background: #fce4ec !important;
}

.bg-pink-2 {
  background: #f8bbd0 !important;
}

.bg-pink-3 {
  background: #f48fb1 !important;
}

.bg-pink-4 {
  background: #f06292 !important;
}

.bg-pink-5 {
  background: #ec407a !important;
}

.bg-pink-6 {
  background: #e91e63 !important;
}

.bg-pink-7 {
  background: #d81b60 !important;
}

.bg-pink-8 {
  background: #c2185b !important;
}

.bg-pink-9 {
  background: #ad1457 !important;
}

.bg-pink-10 {
  background: #880e4f !important;
}

.bg-pink-11 {
  background: #ff80ab !important;
}

.bg-pink-12 {
  background: #ff4081 !important;
}

.bg-pink-13 {
  background: #f50057 !important;
}

.bg-pink-14 {
  background: #c51162 !important;
}

.bg-purple {
  background: #9c27b0 !important;
}

.bg-purple-1 {
  background: #f3e5f5 !important;
}

.bg-purple-2 {
  background: #e1bee7 !important;
}

.bg-purple-3 {
  background: #ce93d8 !important;
}

.bg-purple-4 {
  background: #ba68c8 !important;
}

.bg-purple-5 {
  background: #ab47bc !important;
}

.bg-purple-6 {
  background: #9c27b0 !important;
}

.bg-purple-7 {
  background: #8e24aa !important;
}

.bg-purple-8 {
  background: #7b1fa2 !important;
}

.bg-purple-9 {
  background: #6a1b9a !important;
}

.bg-purple-10 {
  background: #4a148c !important;
}

.bg-purple-11 {
  background: #ea80fc !important;
}

.bg-purple-12 {
  background: #e040fb !important;
}

.bg-purple-13 {
  background: #d500f9 !important;
}

.bg-purple-14 {
  background: #a0f !important;
}

.bg-deep-purple {
  background: #673ab7 !important;
}

.bg-deep-purple-1 {
  background: #ede7f6 !important;
}

.bg-deep-purple-2 {
  background: #d1c4e9 !important;
}

.bg-deep-purple-3 {
  background: #b39ddb !important;
}

.bg-deep-purple-4 {
  background: #9575cd !important;
}

.bg-deep-purple-5 {
  background: #7e57c2 !important;
}

.bg-deep-purple-6 {
  background: #673ab7 !important;
}

.bg-deep-purple-7 {
  background: #5e35b1 !important;
}

.bg-deep-purple-8 {
  background: #512da8 !important;
}

.bg-deep-purple-9 {
  background: #4527a0 !important;
}

.bg-deep-purple-10 {
  background: #311b92 !important;
}

.bg-deep-purple-11 {
  background: #b388ff !important;
}

.bg-deep-purple-12 {
  background: #7c4dff !important;
}

.bg-deep-purple-13 {
  background: #651fff !important;
}

.bg-deep-purple-14 {
  background: #6200ea !important;
}

.bg-indigo {
  background: #3f51b5 !important;
}

.bg-indigo-1 {
  background: #e8eaf6 !important;
}

.bg-indigo-2 {
  background: #c5cae9 !important;
}

.bg-indigo-3 {
  background: #9fa8da !important;
}

.bg-indigo-4 {
  background: #7986cb !important;
}

.bg-indigo-5 {
  background: #5c6bc0 !important;
}

.bg-indigo-6 {
  background: #3f51b5 !important;
}

.bg-indigo-7 {
  background: #3949ab !important;
}

.bg-indigo-8 {
  background: #303f9f !important;
}

.bg-indigo-9 {
  background: #283593 !important;
}

.bg-indigo-10 {
  background: #1a237e !important;
}

.bg-indigo-11 {
  background: #8c9eff !important;
}

.bg-indigo-12 {
  background: #536dfe !important;
}

.bg-indigo-13 {
  background: #3d5afe !important;
}

.bg-indigo-14 {
  background: #304ffe !important;
}

.bg-blue {
  background: #2196f3 !important;
}

.bg-blue-1 {
  background: #e3f2fd !important;
}

.bg-blue-2 {
  background: #bbdefb !important;
}

.bg-blue-3 {
  background: #90caf9 !important;
}

.bg-blue-4 {
  background: #64b5f6 !important;
}

.bg-blue-5 {
  background: #42a5f5 !important;
}

.bg-blue-6 {
  background: #2196f3 !important;
}

.bg-blue-7 {
  background: #1e88e5 !important;
}

.bg-blue-8 {
  background: #1976d2 !important;
}

.bg-blue-9 {
  background: #1565c0 !important;
}

.bg-blue-10 {
  background: #0d47a1 !important;
}

.bg-blue-11 {
  background: #82b1ff !important;
}

.bg-blue-12 {
  background: #448aff !important;
}

.bg-blue-13 {
  background: #2979ff !important;
}

.bg-blue-14 {
  background: #2962ff !important;
}

.bg-light-blue {
  background: #03a9f4 !important;
}

.bg-light-blue-1 {
  background: #e1f5fe !important;
}

.bg-light-blue-2 {
  background: #b3e5fc !important;
}

.bg-light-blue-3 {
  background: #81d4fa !important;
}

.bg-light-blue-4 {
  background: #4fc3f7 !important;
}

.bg-light-blue-5 {
  background: #29b6f6 !important;
}

.bg-light-blue-6 {
  background: #03a9f4 !important;
}

.bg-light-blue-7 {
  background: #039be5 !important;
}

.bg-light-blue-8 {
  background: #0288d1 !important;
}

.bg-light-blue-9 {
  background: #0277bd !important;
}

.bg-light-blue-10 {
  background: #01579b !important;
}

.bg-light-blue-11 {
  background: #80d8ff !important;
}

.bg-light-blue-12 {
  background: #40c4ff !important;
}

.bg-light-blue-13 {
  background: #00b0ff !important;
}

.bg-light-blue-14 {
  background: #0091ea !important;
}

.bg-cyan {
  background: #00bcd4 !important;
}

.bg-cyan-1 {
  background: #e0f7fa !important;
}

.bg-cyan-2 {
  background: #b2ebf2 !important;
}

.bg-cyan-3 {
  background: #80deea !important;
}

.bg-cyan-4 {
  background: #4dd0e1 !important;
}

.bg-cyan-5 {
  background: #26c6da !important;
}

.bg-cyan-6 {
  background: #00bcd4 !important;
}

.bg-cyan-7 {
  background: #00acc1 !important;
}

.bg-cyan-8 {
  background: #0097a7 !important;
}

.bg-cyan-9 {
  background: #00838f !important;
}

.bg-cyan-10 {
  background: #006064 !important;
}

.bg-cyan-11 {
  background: #84ffff !important;
}

.bg-cyan-12 {
  background: #18ffff !important;
}

.bg-cyan-13 {
  background: #00e5ff !important;
}

.bg-cyan-14 {
  background: #00b8d4 !important;
}

.bg-teal {
  background: #009688 !important;
}

.bg-teal-1 {
  background: #e0f2f1 !important;
}

.bg-teal-2 {
  background: #b2dfdb !important;
}

.bg-teal-3 {
  background: #80cbc4 !important;
}

.bg-teal-4 {
  background: #4db6ac !important;
}

.bg-teal-5 {
  background: #26a69a !important;
}

.bg-teal-6 {
  background: #009688 !important;
}

.bg-teal-7 {
  background: #00897b !important;
}

.bg-teal-8 {
  background: #00796b !important;
}

.bg-teal-9 {
  background: #00695c !important;
}

.bg-teal-10 {
  background: #004d40 !important;
}

.bg-teal-11 {
  background: #a7ffeb !important;
}

.bg-teal-12 {
  background: #64ffda !important;
}

.bg-teal-13 {
  background: #1de9b6 !important;
}

.bg-teal-14 {
  background: #00bfa5 !important;
}

.bg-green {
  background: #4caf50 !important;
}

.bg-green-1 {
  background: #e8f5e9 !important;
}

.bg-green-2 {
  background: #c8e6c9 !important;
}

.bg-green-3 {
  background: #a5d6a7 !important;
}

.bg-green-4 {
  background: #81c784 !important;
}

.bg-green-5 {
  background: #66bb6a !important;
}

.bg-green-6 {
  background: #4caf50 !important;
}

.bg-green-7 {
  background: #43a047 !important;
}

.bg-green-8 {
  background: #388e3c !important;
}

.bg-green-9 {
  background: #2e7d32 !important;
}

.bg-green-10 {
  background: #1b5e20 !important;
}

.bg-green-11 {
  background: #b9f6ca !important;
}

.bg-green-12 {
  background: #69f0ae !important;
}

.bg-green-13 {
  background: #00e676 !important;
}

.bg-green-14 {
  background: #00c853 !important;
}

.bg-light-green {
  background: #8bc34a !important;
}

.bg-light-green-1 {
  background: #f1f8e9 !important;
}

.bg-light-green-2 {
  background: #dcedc8 !important;
}

.bg-light-green-3 {
  background: #c5e1a5 !important;
}

.bg-light-green-4 {
  background: #aed581 !important;
}

.bg-light-green-5 {
  background: #9ccc65 !important;
}

.bg-light-green-6 {
  background: #8bc34a !important;
}

.bg-light-green-7 {
  background: #7cb342 !important;
}

.bg-light-green-8 {
  background: #689f38 !important;
}

.bg-light-green-9 {
  background: #558b2f !important;
}

.bg-light-green-10 {
  background: #33691e !important;
}

.bg-light-green-11 {
  background: #ccff90 !important;
}

.bg-light-green-12 {
  background: #b2ff59 !important;
}

.bg-light-green-13 {
  background: #76ff03 !important;
}

.bg-light-green-14 {
  background: #64dd17 !important;
}

.bg-lime {
  background: #cddc39 !important;
}

.bg-lime-1 {
  background: #f9fbe7 !important;
}

.bg-lime-2 {
  background: #f0f4c3 !important;
}

.bg-lime-3 {
  background: #e6ee9c !important;
}

.bg-lime-4 {
  background: #dce775 !important;
}

.bg-lime-5 {
  background: #d4e157 !important;
}

.bg-lime-6 {
  background: #cddc39 !important;
}

.bg-lime-7 {
  background: #c0ca33 !important;
}

.bg-lime-8 {
  background: #afb42b !important;
}

.bg-lime-9 {
  background: #9e9d24 !important;
}

.bg-lime-10 {
  background: #827717 !important;
}

.bg-lime-11 {
  background: #f4ff81 !important;
}

.bg-lime-12 {
  background: #eeff41 !important;
}

.bg-lime-13 {
  background: #c6ff00 !important;
}

.bg-lime-14 {
  background: #aeea00 !important;
}

.bg-yellow {
  background: #ffeb3b !important;
}

.bg-yellow-1 {
  background: #fffde7 !important;
}

.bg-yellow-2 {
  background: #fff9c4 !important;
}

.bg-yellow-3 {
  background: #fff59d !important;
}

.bg-yellow-4 {
  background: #fff176 !important;
}

.bg-yellow-5 {
  background: #ffee58 !important;
}

.bg-yellow-6 {
  background: #ffeb3b !important;
}

.bg-yellow-7 {
  background: #fdd835 !important;
}

.bg-yellow-8 {
  background: #fbc02d !important;
}

.bg-yellow-9 {
  background: #f9a825 !important;
}

.bg-yellow-10 {
  background: #f57f17 !important;
}

.bg-yellow-11 {
  background: #ffff8d !important;
}

.bg-yellow-12 {
  background: #ff0 !important;
}

.bg-yellow-13 {
  background: #ffea00 !important;
}

.bg-yellow-14 {
  background: #ffd600 !important;
}

.bg-amber {
  background: #ffc107 !important;
}

.bg-amber-1 {
  background: #fff8e1 !important;
}

.bg-amber-2 {
  background: #ffecb3 !important;
}

.bg-amber-3 {
  background: #ffe082 !important;
}

.bg-amber-4 {
  background: #ffd54f !important;
}

.bg-amber-5 {
  background: #ffca28 !important;
}

.bg-amber-6 {
  background: #ffc107 !important;
}

.bg-amber-7 {
  background: #ffb300 !important;
}

.bg-amber-8 {
  background: #ffa000 !important;
}

.bg-amber-9 {
  background: #ff8f00 !important;
}

.bg-amber-10 {
  background: #ff6f00 !important;
}

.bg-amber-11 {
  background: #ffe57f !important;
}

.bg-amber-12 {
  background: #ffd740 !important;
}

.bg-amber-13 {
  background: #ffc400 !important;
}

.bg-amber-14 {
  background: #ffab00 !important;
}

.bg-orange {
  background: #ff9800 !important;
}

.bg-orange-1 {
  background: #fff3e0 !important;
}

.bg-orange-2 {
  background: #ffe0b2 !important;
}

.bg-orange-3 {
  background: #ffcc80 !important;
}

.bg-orange-4 {
  background: #ffb74d !important;
}

.bg-orange-5 {
  background: #ffa726 !important;
}

.bg-orange-6 {
  background: #ff9800 !important;
}

.bg-orange-7 {
  background: #fb8c00 !important;
}

.bg-orange-8 {
  background: #f57c00 !important;
}

.bg-orange-9 {
  background: #ef6c00 !important;
}

.bg-orange-10 {
  background: #e65100 !important;
}

.bg-orange-11 {
  background: #ffd180 !important;
}

.bg-orange-12 {
  background: #ffab40 !important;
}

.bg-orange-13 {
  background: #ff9100 !important;
}

.bg-orange-14 {
  background: #ff6d00 !important;
}

.bg-deep-orange {
  background: #ff5722 !important;
}

.bg-deep-orange-1 {
  background: #fbe9e7 !important;
}

.bg-deep-orange-2 {
  background: #ffccbc !important;
}

.bg-deep-orange-3 {
  background: #ffab91 !important;
}

.bg-deep-orange-4 {
  background: #ff8a65 !important;
}

.bg-deep-orange-5 {
  background: #ff7043 !important;
}

.bg-deep-orange-6 {
  background: #ff5722 !important;
}

.bg-deep-orange-7 {
  background: #f4511e !important;
}

.bg-deep-orange-8 {
  background: #e64a19 !important;
}

.bg-deep-orange-9 {
  background: #d84315 !important;
}

.bg-deep-orange-10 {
  background: #bf360c !important;
}

.bg-deep-orange-11 {
  background: #ff9e80 !important;
}

.bg-deep-orange-12 {
  background: #ff6e40 !important;
}

.bg-deep-orange-13 {
  background: #ff3d00 !important;
}

.bg-deep-orange-14 {
  background: #dd2c00 !important;
}

.bg-brown {
  background: #795548 !important;
}

.bg-brown-1 {
  background: #efebe9 !important;
}

.bg-brown-2 {
  background: #d7ccc8 !important;
}

.bg-brown-3 {
  background: #bcaaa4 !important;
}

.bg-brown-4 {
  background: #a1887f !important;
}

.bg-brown-5 {
  background: #8d6e63 !important;
}

.bg-brown-6 {
  background: #795548 !important;
}

.bg-brown-7 {
  background: #6d4c41 !important;
}

.bg-brown-8 {
  background: #5d4037 !important;
}

.bg-brown-9 {
  background: #4e342e !important;
}

.bg-brown-10 {
  background: #3e2723 !important;
}

.bg-brown-11 {
  background: #d7ccc8 !important;
}

.bg-brown-12 {
  background: #bcaaa4 !important;
}

.bg-brown-13 {
  background: #8d6e63 !important;
}

.bg-brown-14 {
  background: #5d4037 !important;
}

.bg-grey {
  background: #9e9e9e !important;
}

.bg-grey-1 {
  background: #fafafa !important;
}

.bg-grey-2 {
  background: #f5f5f5 !important;
}

.bg-grey-3 {
  background: #eee !important;
}

.bg-grey-4 {
  background: #e0e0e0 !important;
}

.bg-grey-5 {
  background: #bdbdbd !important;
}

.bg-grey-6 {
  background: #9e9e9e !important;
}

.bg-grey-7 {
  background: #757575 !important;
}

.bg-grey-8 {
  background: #616161 !important;
}

.bg-grey-9 {
  background: #424242 !important;
}

.bg-grey-10 {
  background: #212121 !important;
}

.bg-grey-11 {
  background: #f5f5f5 !important;
}

.bg-grey-12 {
  background: #eee !important;
}

.bg-grey-13 {
  background: #bdbdbd !important;
}

.bg-grey-14 {
  background: #616161 !important;
}

.bg-blue-grey {
  background: #607d8b !important;
}

.bg-blue-grey-1 {
  background: #eceff1 !important;
}

.bg-blue-grey-2 {
  background: #cfd8dc !important;
}

.bg-blue-grey-3 {
  background: #b0bec5 !important;
}

.bg-blue-grey-4 {
  background: #90a4ae !important;
}

.bg-blue-grey-5 {
  background: #78909c !important;
}

.bg-blue-grey-6 {
  background: #607d8b !important;
}

.bg-blue-grey-7 {
  background: #546e7a !important;
}

.bg-blue-grey-8 {
  background: #455a64 !important;
}

.bg-blue-grey-9 {
  background: #37474f !important;
}

.bg-blue-grey-10 {
  background: #263238 !important;
}

.bg-blue-grey-11 {
  background: #cfd8dc !important;
}

.bg-blue-grey-12 {
  background: #b0bec5 !important;
}

.bg-blue-grey-13 {
  background: #78909c !important;
}

.bg-blue-grey-14 {
  background: #455a64 !important;
}

.shadow-transition {
  transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1) !important;
}

.shadow-1 {
  box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
}

.shadow-up-1 {
  box-shadow: 0 -1px 3px #0003, 0 -1px 1px #00000024, 0 -2px 1px -1px #0000001f;
}

.shadow-2 {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.shadow-up-2 {
  box-shadow: 0 -1px 5px #0003, 0 -2px 2px #00000024, 0 -3px 1px -2px #0000001f;
}

.shadow-3 {
  box-shadow: 0 1px 8px #0003, 0 3px 4px #00000024, 0 3px 3px -2px #0000001f;
}

.shadow-up-3 {
  box-shadow: 0 -1px 8px #0003, 0 -3px 4px #00000024, 0 -3px 3px -2px #0000001f;
}

.shadow-4 {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.shadow-up-4 {
  box-shadow: 0 -2px 4px -1px #0003, 0 -4px 5px #00000024, 0 -1px 10px #0000001f;
}

.shadow-5 {
  box-shadow: 0 3px 5px -1px #0003, 0 5px 8px #00000024, 0 1px 14px #0000001f;
}

.shadow-up-5 {
  box-shadow: 0 -3px 5px -1px #0003, 0 -5px 8px #00000024, 0 -1px 14px #0000001f;
}

.shadow-6 {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.shadow-up-6 {
  box-shadow: 0 -3px 5px -1px #0003, 0 -6px 10px #00000024, 0 -1px 18px #0000001f;
}

.shadow-7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024, 0 2px 16px 1px #0000001f;
}

.shadow-up-7 {
  box-shadow: 0 -4px 5px -2px #0003, 0 -7px 10px 1px #00000024, 0 -2px 16px 1px #0000001f;
}

.shadow-8 {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.shadow-up-8 {
  box-shadow: 0 -5px 5px -3px #0003, 0 -8px 10px 1px #00000024, 0 -3px 14px 2px #0000001f;
}

.shadow-9 {
  box-shadow: 0 5px 6px -3px #0003, 0 9px 12px 1px #00000024, 0 3px 16px 2px #0000001f;
}

.shadow-up-9 {
  box-shadow: 0 -5px 6px -3px #0003, 0 -9px 12px 1px #00000024, 0 -3px 16px 2px #0000001f;
}

.shadow-10 {
  box-shadow: 0 6px 6px -3px #0003, 0 10px 14px 1px #00000024, 0 4px 18px 3px #0000001f;
}

.shadow-up-10 {
  box-shadow: 0 -6px 6px -3px #0003, 0 -10px 14px 1px #00000024, 0 -4px 18px 3px #0000001f;
}

.shadow-11 {
  box-shadow: 0 6px 7px -4px #0003, 0 11px 15px 1px #00000024, 0 4px 20px 3px #0000001f;
}

.shadow-up-11 {
  box-shadow: 0 -6px 7px -4px #0003, 0 -11px 15px 1px #00000024, 0 -4px 20px 3px #0000001f;
}

.shadow-12 {
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024, 0 5px 22px 4px #0000001f;
}

.shadow-up-12 {
  box-shadow: 0 -7px 8px -4px #0003, 0 -12px 17px 2px #00000024, 0 -5px 22px 4px #0000001f;
}

.shadow-13 {
  box-shadow: 0 7px 8px -4px #0003, 0 13px 19px 2px #00000024, 0 5px 24px 4px #0000001f;
}

.shadow-up-13 {
  box-shadow: 0 -7px 8px -4px #0003, 0 -13px 19px 2px #00000024, 0 -5px 24px 4px #0000001f;
}

.shadow-14 {
  box-shadow: 0 7px 9px -4px #0003, 0 14px 21px 2px #00000024, 0 5px 26px 4px #0000001f;
}

.shadow-up-14 {
  box-shadow: 0 -7px 9px -4px #0003, 0 -14px 21px 2px #00000024, 0 -5px 26px 4px #0000001f;
}

.shadow-15 {
  box-shadow: 0 8px 9px -5px #0003, 0 15px 22px 2px #00000024, 0 6px 28px 5px #0000001f;
}

.shadow-up-15 {
  box-shadow: 0 -8px 9px -5px #0003, 0 -15px 22px 2px #00000024, 0 -6px 28px 5px #0000001f;
}

.shadow-16 {
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.shadow-up-16 {
  box-shadow: 0 -8px 10px -5px #0003, 0 -16px 24px 2px #00000024, 0 -6px 30px 5px #0000001f;
}

.shadow-17 {
  box-shadow: 0 8px 11px -5px #0003, 0 17px 26px 2px #00000024, 0 6px 32px 5px #0000001f;
}

.shadow-up-17 {
  box-shadow: 0 -8px 11px -5px #0003, 0 -17px 26px 2px #00000024, 0 -6px 32px 5px #0000001f;
}

.shadow-18 {
  box-shadow: 0 9px 11px -5px #0003, 0 18px 28px 2px #00000024, 0 7px 34px 6px #0000001f;
}

.shadow-up-18 {
  box-shadow: 0 -9px 11px -5px #0003, 0 -18px 28px 2px #00000024, 0 -7px 34px 6px #0000001f;
}

.shadow-19 {
  box-shadow: 0 9px 12px -6px #0003, 0 19px 29px 2px #00000024, 0 7px 36px 6px #0000001f;
}

.shadow-up-19 {
  box-shadow: 0 -9px 12px -6px #0003, 0 -19px 29px 2px #00000024, 0 -7px 36px 6px #0000001f;
}

.shadow-20 {
  box-shadow: 0 10px 13px -6px #0003, 0 20px 31px 3px #00000024, 0 8px 38px 7px #0000001f;
}

.shadow-up-20 {
  box-shadow: 0 -10px 13px -6px #0003, 0 -20px 31px 3px #00000024, 0 -8px 38px 7px #0000001f;
}

.shadow-21 {
  box-shadow: 0 10px 13px -6px #0003, 0 21px 33px 3px #00000024, 0 8px 40px 7px #0000001f;
}

.shadow-up-21 {
  box-shadow: 0 -10px 13px -6px #0003, 0 -21px 33px 3px #00000024, 0 -8px 40px 7px #0000001f;
}

.shadow-22 {
  box-shadow: 0 10px 14px -6px #0003, 0 22px 35px 3px #00000024, 0 8px 42px 7px #0000001f;
}

.shadow-up-22 {
  box-shadow: 0 -10px 14px -6px #0003, 0 -22px 35px 3px #00000024, 0 -8px 42px 7px #0000001f;
}

.shadow-23 {
  box-shadow: 0 11px 14px -7px #0003, 0 23px 36px 3px #00000024, 0 9px 44px 8px #0000001f;
}

.shadow-up-23 {
  box-shadow: 0 -11px 14px -7px #0003, 0 -23px 36px 3px #00000024, 0 -9px 44px 8px #0000001f;
}

.shadow-24 {
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.shadow-up-24 {
  box-shadow: 0 -11px 15px -7px #0003, 0 -24px 38px 3px #00000024, 0 -9px 46px 8px #0000001f;
}

.inset-shadow {
  box-shadow: inset 0 7px 9px -7px #000000b3;
}

.inset-shadow-down {
  box-shadow: inset 0 -7px 9px -7px #000000b3;
}

body.body--dark .shadow-1 {
  box-shadow: 0 1px 3px #fff3, 0 1px 1px #ffffff24, 0 2px 1px -1px #ffffff1f;
}

body.body--dark .shadow-up-1 {
  box-shadow: 0 -1px 3px #fff3, 0 -1px 1px #ffffff24, 0 -2px 1px -1px #ffffff1f;
}

body.body--dark .shadow-2 {
  box-shadow: 0 1px 5px #fff3, 0 2px 2px #ffffff24, 0 3px 1px -2px #ffffff1f;
}

body.body--dark .shadow-up-2 {
  box-shadow: 0 -1px 5px #fff3, 0 -2px 2px #ffffff24, 0 -3px 1px -2px #ffffff1f;
}

body.body--dark .shadow-3 {
  box-shadow: 0 1px 8px #fff3, 0 3px 4px #ffffff24, 0 3px 3px -2px #ffffff1f;
}

body.body--dark .shadow-up-3 {
  box-shadow: 0 -1px 8px #fff3, 0 -3px 4px #ffffff24, 0 -3px 3px -2px #ffffff1f;
}

body.body--dark .shadow-4 {
  box-shadow: 0 2px 4px -1px #fff3, 0 4px 5px #ffffff24, 0 1px 10px #ffffff1f;
}

body.body--dark .shadow-up-4 {
  box-shadow: 0 -2px 4px -1px #fff3, 0 -4px 5px #ffffff24, 0 -1px 10px #ffffff1f;
}

body.body--dark .shadow-5 {
  box-shadow: 0 3px 5px -1px #fff3, 0 5px 8px #ffffff24, 0 1px 14px #ffffff1f;
}

body.body--dark .shadow-up-5 {
  box-shadow: 0 -3px 5px -1px #fff3, 0 -5px 8px #ffffff24, 0 -1px 14px #ffffff1f;
}

body.body--dark .shadow-6 {
  box-shadow: 0 3px 5px -1px #fff3, 0 6px 10px #ffffff24, 0 1px 18px #ffffff1f;
}

body.body--dark .shadow-up-6 {
  box-shadow: 0 -3px 5px -1px #fff3, 0 -6px 10px #ffffff24, 0 -1px 18px #ffffff1f;
}

body.body--dark .shadow-7 {
  box-shadow: 0 4px 5px -2px #fff3, 0 7px 10px 1px #ffffff24, 0 2px 16px 1px #ffffff1f;
}

body.body--dark .shadow-up-7 {
  box-shadow: 0 -4px 5px -2px #fff3, 0 -7px 10px 1px #ffffff24, 0 -2px 16px 1px #ffffff1f;
}

body.body--dark .shadow-8 {
  box-shadow: 0 5px 5px -3px #fff3, 0 8px 10px 1px #ffffff24, 0 3px 14px 2px #ffffff1f;
}

body.body--dark .shadow-up-8 {
  box-shadow: 0 -5px 5px -3px #fff3, 0 -8px 10px 1px #ffffff24, 0 -3px 14px 2px #ffffff1f;
}

body.body--dark .shadow-9 {
  box-shadow: 0 5px 6px -3px #fff3, 0 9px 12px 1px #ffffff24, 0 3px 16px 2px #ffffff1f;
}

body.body--dark .shadow-up-9 {
  box-shadow: 0 -5px 6px -3px #fff3, 0 -9px 12px 1px #ffffff24, 0 -3px 16px 2px #ffffff1f;
}

body.body--dark .shadow-10 {
  box-shadow: 0 6px 6px -3px #fff3, 0 10px 14px 1px #ffffff24, 0 4px 18px 3px #ffffff1f;
}

body.body--dark .shadow-up-10 {
  box-shadow: 0 -6px 6px -3px #fff3, 0 -10px 14px 1px #ffffff24, 0 -4px 18px 3px #ffffff1f;
}

body.body--dark .shadow-11 {
  box-shadow: 0 6px 7px -4px #fff3, 0 11px 15px 1px #ffffff24, 0 4px 20px 3px #ffffff1f;
}

body.body--dark .shadow-up-11 {
  box-shadow: 0 -6px 7px -4px #fff3, 0 -11px 15px 1px #ffffff24, 0 -4px 20px 3px #ffffff1f;
}

body.body--dark .shadow-12 {
  box-shadow: 0 7px 8px -4px #fff3, 0 12px 17px 2px #ffffff24, 0 5px 22px 4px #ffffff1f;
}

body.body--dark .shadow-up-12 {
  box-shadow: 0 -7px 8px -4px #fff3, 0 -12px 17px 2px #ffffff24, 0 -5px 22px 4px #ffffff1f;
}

body.body--dark .shadow-13 {
  box-shadow: 0 7px 8px -4px #fff3, 0 13px 19px 2px #ffffff24, 0 5px 24px 4px #ffffff1f;
}

body.body--dark .shadow-up-13 {
  box-shadow: 0 -7px 8px -4px #fff3, 0 -13px 19px 2px #ffffff24, 0 -5px 24px 4px #ffffff1f;
}

body.body--dark .shadow-14 {
  box-shadow: 0 7px 9px -4px #fff3, 0 14px 21px 2px #ffffff24, 0 5px 26px 4px #ffffff1f;
}

body.body--dark .shadow-up-14 {
  box-shadow: 0 -7px 9px -4px #fff3, 0 -14px 21px 2px #ffffff24, 0 -5px 26px 4px #ffffff1f;
}

body.body--dark .shadow-15 {
  box-shadow: 0 8px 9px -5px #fff3, 0 15px 22px 2px #ffffff24, 0 6px 28px 5px #ffffff1f;
}

body.body--dark .shadow-up-15 {
  box-shadow: 0 -8px 9px -5px #fff3, 0 -15px 22px 2px #ffffff24, 0 -6px 28px 5px #ffffff1f;
}

body.body--dark .shadow-16 {
  box-shadow: 0 8px 10px -5px #fff3, 0 16px 24px 2px #ffffff24, 0 6px 30px 5px #ffffff1f;
}

body.body--dark .shadow-up-16 {
  box-shadow: 0 -8px 10px -5px #fff3, 0 -16px 24px 2px #ffffff24, 0 -6px 30px 5px #ffffff1f;
}

body.body--dark .shadow-17 {
  box-shadow: 0 8px 11px -5px #fff3, 0 17px 26px 2px #ffffff24, 0 6px 32px 5px #ffffff1f;
}

body.body--dark .shadow-up-17 {
  box-shadow: 0 -8px 11px -5px #fff3, 0 -17px 26px 2px #ffffff24, 0 -6px 32px 5px #ffffff1f;
}

body.body--dark .shadow-18 {
  box-shadow: 0 9px 11px -5px #fff3, 0 18px 28px 2px #ffffff24, 0 7px 34px 6px #ffffff1f;
}

body.body--dark .shadow-up-18 {
  box-shadow: 0 -9px 11px -5px #fff3, 0 -18px 28px 2px #ffffff24, 0 -7px 34px 6px #ffffff1f;
}

body.body--dark .shadow-19 {
  box-shadow: 0 9px 12px -6px #fff3, 0 19px 29px 2px #ffffff24, 0 7px 36px 6px #ffffff1f;
}

body.body--dark .shadow-up-19 {
  box-shadow: 0 -9px 12px -6px #fff3, 0 -19px 29px 2px #ffffff24, 0 -7px 36px 6px #ffffff1f;
}

body.body--dark .shadow-20 {
  box-shadow: 0 10px 13px -6px #fff3, 0 20px 31px 3px #ffffff24, 0 8px 38px 7px #ffffff1f;
}

body.body--dark .shadow-up-20 {
  box-shadow: 0 -10px 13px -6px #fff3, 0 -20px 31px 3px #ffffff24, 0 -8px 38px 7px #ffffff1f;
}

body.body--dark .shadow-21 {
  box-shadow: 0 10px 13px -6px #fff3, 0 21px 33px 3px #ffffff24, 0 8px 40px 7px #ffffff1f;
}

body.body--dark .shadow-up-21 {
  box-shadow: 0 -10px 13px -6px #fff3, 0 -21px 33px 3px #ffffff24, 0 -8px 40px 7px #ffffff1f;
}

body.body--dark .shadow-22 {
  box-shadow: 0 10px 14px -6px #fff3, 0 22px 35px 3px #ffffff24, 0 8px 42px 7px #ffffff1f;
}

body.body--dark .shadow-up-22 {
  box-shadow: 0 -10px 14px -6px #fff3, 0 -22px 35px 3px #ffffff24, 0 -8px 42px 7px #ffffff1f;
}

body.body--dark .shadow-23 {
  box-shadow: 0 11px 14px -7px #fff3, 0 23px 36px 3px #ffffff24, 0 9px 44px 8px #ffffff1f;
}

body.body--dark .shadow-up-23 {
  box-shadow: 0 -11px 14px -7px #fff3, 0 -23px 36px 3px #ffffff24, 0 -9px 44px 8px #ffffff1f;
}

body.body--dark .shadow-24 {
  box-shadow: 0 11px 15px -7px #fff3, 0 24px 38px 3px #ffffff24, 0 9px 46px 8px #ffffff1f;
}

body.body--dark .shadow-up-24 {
  box-shadow: 0 -11px 15px -7px #fff3, 0 -24px 38px 3px #ffffff24, 0 -9px 46px 8px #ffffff1f;
}

body.body--dark .inset-shadow {
  box-shadow: inset 0 7px 9px -7px #ffffffb3;
}

body.body--dark .inset-shadow-down {
  box-shadow: inset 0 -7px 9px -7px #ffffffb3;
}

.no-shadow, .shadow-0 {
  box-shadow: none !important;
}

.z-marginals {
  z-index: 2000;
}

.z-notify {
  z-index: 9500;
}

.z-fullscreen {
  z-index: 6000;
}

.z-inherit {
  z-index: inherit !important;
}

.row, .column, .flex {
  flex-wrap: wrap;
  display: flex;
}

.row.inline, .column.inline, .flex.inline {
  display: inline-flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.column.reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.reverse-wrap {
  flex-wrap: wrap-reverse;
}

.order-first {
  order: -10000;
}

.order-last {
  order: 10000;
}

.order-none {
  order: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center, .flex-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center, .flex-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-stretch {
  align-content: stretch;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.q-gutter-x-none, .q-gutter-none, .q-gutter-x-none > *, .q-gutter-none > * {
  margin-left: 0;
}

.q-gutter-y-none, .q-gutter-none, .q-gutter-y-none > *, .q-gutter-none > * {
  margin-top: 0;
}

.q-col-gutter-x-none, .q-col-gutter-none {
  margin-left: 0;
}

.q-col-gutter-x-none > *, .q-col-gutter-none > * {
  padding-left: 0;
}

.q-col-gutter-y-none, .q-col-gutter-none {
  margin-top: 0;
}

.q-col-gutter-y-none > *, .q-col-gutter-none > * {
  padding-top: 0;
}

.q-gutter-x-xs, .q-gutter-xs {
  margin-left: -4px;
}

.q-gutter-x-xs > *, .q-gutter-xs > * {
  margin-left: 4px;
}

.q-gutter-y-xs, .q-gutter-xs {
  margin-top: -4px;
}

.q-gutter-y-xs > *, .q-gutter-xs > * {
  margin-top: 4px;
}

.q-col-gutter-x-xs, .q-col-gutter-xs {
  margin-left: -4px;
}

.q-col-gutter-x-xs > *, .q-col-gutter-xs > * {
  padding-left: 4px;
}

.q-col-gutter-y-xs, .q-col-gutter-xs {
  margin-top: -4px;
}

.q-col-gutter-y-xs > *, .q-col-gutter-xs > * {
  padding-top: 4px;
}

.q-gutter-x-sm, .q-gutter-sm {
  margin-left: -8px;
}

.q-gutter-x-sm > *, .q-gutter-sm > * {
  margin-left: 8px;
}

.q-gutter-y-sm, .q-gutter-sm {
  margin-top: -8px;
}

.q-gutter-y-sm > *, .q-gutter-sm > * {
  margin-top: 8px;
}

.q-col-gutter-x-sm, .q-col-gutter-sm {
  margin-left: -8px;
}

.q-col-gutter-x-sm > *, .q-col-gutter-sm > * {
  padding-left: 8px;
}

.q-col-gutter-y-sm, .q-col-gutter-sm {
  margin-top: -8px;
}

.q-col-gutter-y-sm > *, .q-col-gutter-sm > * {
  padding-top: 8px;
}

.q-gutter-x-md, .q-gutter-md {
  margin-left: -16px;
}

.q-gutter-x-md > *, .q-gutter-md > * {
  margin-left: 16px;
}

.q-gutter-y-md, .q-gutter-md {
  margin-top: -16px;
}

.q-gutter-y-md > *, .q-gutter-md > * {
  margin-top: 16px;
}

.q-col-gutter-x-md, .q-col-gutter-md {
  margin-left: -16px;
}

.q-col-gutter-x-md > *, .q-col-gutter-md > * {
  padding-left: 16px;
}

.q-col-gutter-y-md, .q-col-gutter-md {
  margin-top: -16px;
}

.q-col-gutter-y-md > *, .q-col-gutter-md > * {
  padding-top: 16px;
}

.q-gutter-x-lg, .q-gutter-lg {
  margin-left: -24px;
}

.q-gutter-x-lg > *, .q-gutter-lg > * {
  margin-left: 24px;
}

.q-gutter-y-lg, .q-gutter-lg {
  margin-top: -24px;
}

.q-gutter-y-lg > *, .q-gutter-lg > * {
  margin-top: 24px;
}

.q-col-gutter-x-lg, .q-col-gutter-lg {
  margin-left: -24px;
}

.q-col-gutter-x-lg > *, .q-col-gutter-lg > * {
  padding-left: 24px;
}

.q-col-gutter-y-lg, .q-col-gutter-lg {
  margin-top: -24px;
}

.q-col-gutter-y-lg > *, .q-col-gutter-lg > * {
  padding-top: 24px;
}

.q-gutter-x-xl, .q-gutter-xl {
  margin-left: -48px;
}

.q-gutter-x-xl > *, .q-gutter-xl > * {
  margin-left: 48px;
}

.q-gutter-y-xl, .q-gutter-xl {
  margin-top: -48px;
}

.q-gutter-y-xl > *, .q-gutter-xl > * {
  margin-top: 48px;
}

.q-col-gutter-x-xl, .q-col-gutter-xl {
  margin-left: -48px;
}

.q-col-gutter-x-xl > *, .q-col-gutter-xl > * {
  padding-left: 48px;
}

.q-col-gutter-y-xl, .q-col-gutter-xl {
  margin-top: -48px;
}

.q-col-gutter-y-xl > *, .q-col-gutter-xl > * {
  padding-top: 48px;
}

@media (width >= 0) {
  .row > .col, .flex > .col, .row > .col-auto, .flex > .col-auto, .row > .col-grow, .flex > .col-grow, .row > .col-shrink, .flex > .col-shrink, .row > .col-xs, .flex > .col-xs, .row > .col-xs-auto, .row > .col-12, .row > .col-xs-12, .row > .col-11, .row > .col-xs-11, .row > .col-10, .row > .col-xs-10, .row > .col-9, .row > .col-xs-9, .row > .col-8, .row > .col-xs-8, .row > .col-7, .row > .col-xs-7, .row > .col-6, .row > .col-xs-6, .row > .col-5, .row > .col-xs-5, .row > .col-4, .row > .col-xs-4, .row > .col-3, .row > .col-xs-3, .row > .col-2, .row > .col-xs-2, .row > .col-1, .row > .col-xs-1, .row > .col-0, .row > .col-xs-0, .flex > .col-xs-auto, .flex > .col-12, .flex > .col-xs-12, .flex > .col-11, .flex > .col-xs-11, .flex > .col-10, .flex > .col-xs-10, .flex > .col-9, .flex > .col-xs-9, .flex > .col-8, .flex > .col-xs-8, .flex > .col-7, .flex > .col-xs-7, .flex > .col-6, .flex > .col-xs-6, .flex > .col-5, .flex > .col-xs-5, .flex > .col-4, .flex > .col-xs-4, .flex > .col-3, .flex > .col-xs-3, .flex > .col-2, .flex > .col-xs-2, .flex > .col-1, .flex > .col-xs-1, .flex > .col-0, .flex > .col-xs-0, .row > .col-xs-grow, .flex > .col-xs-grow, .row > .col-xs-shrink, .flex > .col-xs-shrink {
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .column > .col, .flex > .col, .column > .col-auto, .flex > .col-auto, .column > .col-grow, .flex > .col-grow, .column > .col-shrink, .flex > .col-shrink, .column > .col-xs, .flex > .col-xs, .column > .col-xs-auto, .column > .col-12, .column > .col-xs-12, .column > .col-11, .column > .col-xs-11, .column > .col-10, .column > .col-xs-10, .column > .col-9, .column > .col-xs-9, .column > .col-8, .column > .col-xs-8, .column > .col-7, .column > .col-xs-7, .column > .col-6, .column > .col-xs-6, .column > .col-5, .column > .col-xs-5, .column > .col-4, .column > .col-xs-4, .column > .col-3, .column > .col-xs-3, .column > .col-2, .column > .col-xs-2, .column > .col-1, .column > .col-xs-1, .column > .col-0, .column > .col-xs-0, .flex > .col-xs-auto, .flex > .col-12, .flex > .col-xs-12, .flex > .col-11, .flex > .col-xs-11, .flex > .col-10, .flex > .col-xs-10, .flex > .col-9, .flex > .col-xs-9, .flex > .col-8, .flex > .col-xs-8, .flex > .col-7, .flex > .col-xs-7, .flex > .col-6, .flex > .col-xs-6, .flex > .col-5, .flex > .col-xs-5, .flex > .col-4, .flex > .col-xs-4, .flex > .col-3, .flex > .col-xs-3, .flex > .col-2, .flex > .col-xs-2, .flex > .col-1, .flex > .col-xs-1, .flex > .col-0, .flex > .col-xs-0, .column > .col-xs-grow, .flex > .col-xs-grow, .column > .col-xs-shrink, .flex > .col-xs-shrink {
    height: auto;
    min-height: 0;
    max-height: 100%;
  }

  .col, .col-xs {
    flex: 10000;
  }

  .col-auto, .col-xs-auto, .col-12, .col-xs-12, .col-11, .col-xs-11, .col-10, .col-xs-10, .col-9, .col-xs-9, .col-8, .col-xs-8, .col-7, .col-xs-7, .col-6, .col-xs-6, .col-5, .col-xs-5, .col-4, .col-xs-4, .col-3, .col-xs-3, .col-2, .col-xs-2, .col-1, .col-xs-1, .col-0, .col-xs-0 {
    flex: none;
  }

  .col-grow, .col-xs-grow {
    flex: 1 0 auto;
  }

  .col-shrink, .col-xs-shrink {
    flex: 0 auto;
  }

  .row > .col-0, .row > .col-xs-0 {
    width: 0%;
    height: auto;
  }

  .row > .offset-0, .row > .offset-xs-0 {
    margin-left: 0%;
  }

  .column > .col-0, .column > .col-xs-0 {
    width: auto;
    height: 0%;
  }

  .row > .col-1, .row > .col-xs-1 {
    width: 8.3333%;
    height: auto;
  }

  .row > .offset-1, .row > .offset-xs-1 {
    margin-left: 8.3333%;
  }

  .column > .col-1, .column > .col-xs-1 {
    width: auto;
    height: 8.3333%;
  }

  .row > .col-2, .row > .col-xs-2 {
    width: 16.6667%;
    height: auto;
  }

  .row > .offset-2, .row > .offset-xs-2 {
    margin-left: 16.6667%;
  }

  .column > .col-2, .column > .col-xs-2 {
    width: auto;
    height: 16.6667%;
  }

  .row > .col-3, .row > .col-xs-3 {
    width: 25%;
    height: auto;
  }

  .row > .offset-3, .row > .offset-xs-3 {
    margin-left: 25%;
  }

  .column > .col-3, .column > .col-xs-3 {
    width: auto;
    height: 25%;
  }

  .row > .col-4, .row > .col-xs-4 {
    width: 33.3333%;
    height: auto;
  }

  .row > .offset-4, .row > .offset-xs-4 {
    margin-left: 33.3333%;
  }

  .column > .col-4, .column > .col-xs-4 {
    width: auto;
    height: 33.3333%;
  }

  .row > .col-5, .row > .col-xs-5 {
    width: 41.6667%;
    height: auto;
  }

  .row > .offset-5, .row > .offset-xs-5 {
    margin-left: 41.6667%;
  }

  .column > .col-5, .column > .col-xs-5 {
    width: auto;
    height: 41.6667%;
  }

  .row > .col-6, .row > .col-xs-6 {
    width: 50%;
    height: auto;
  }

  .row > .offset-6, .row > .offset-xs-6 {
    margin-left: 50%;
  }

  .column > .col-6, .column > .col-xs-6 {
    width: auto;
    height: 50%;
  }

  .row > .col-7, .row > .col-xs-7 {
    width: 58.3333%;
    height: auto;
  }

  .row > .offset-7, .row > .offset-xs-7 {
    margin-left: 58.3333%;
  }

  .column > .col-7, .column > .col-xs-7 {
    width: auto;
    height: 58.3333%;
  }

  .row > .col-8, .row > .col-xs-8 {
    width: 66.6667%;
    height: auto;
  }

  .row > .offset-8, .row > .offset-xs-8 {
    margin-left: 66.6667%;
  }

  .column > .col-8, .column > .col-xs-8 {
    width: auto;
    height: 66.6667%;
  }

  .row > .col-9, .row > .col-xs-9 {
    width: 75%;
    height: auto;
  }

  .row > .offset-9, .row > .offset-xs-9 {
    margin-left: 75%;
  }

  .column > .col-9, .column > .col-xs-9 {
    width: auto;
    height: 75%;
  }

  .row > .col-10, .row > .col-xs-10 {
    width: 83.3333%;
    height: auto;
  }

  .row > .offset-10, .row > .offset-xs-10 {
    margin-left: 83.3333%;
  }

  .column > .col-10, .column > .col-xs-10 {
    width: auto;
    height: 83.3333%;
  }

  .row > .col-11, .row > .col-xs-11 {
    width: 91.6667%;
    height: auto;
  }

  .row > .offset-11, .row > .offset-xs-11 {
    margin-left: 91.6667%;
  }

  .column > .col-11, .column > .col-xs-11 {
    width: auto;
    height: 91.6667%;
  }

  .row > .col-12, .row > .col-xs-12 {
    width: 100%;
    height: auto;
  }

  .row > .offset-12, .row > .offset-xs-12 {
    margin-left: 100%;
  }

  .column > .col-12, .column > .col-xs-12 {
    width: auto;
    height: 100%;
  }

  .row > .col-all {
    flex: 0 0 100%;
    height: auto;
  }
}

@media (width >= 600px) {
  .row > .col-sm, .flex > .col-sm, .row > .col-sm-auto, .row > .col-sm-12, .row > .col-sm-11, .row > .col-sm-10, .row > .col-sm-9, .row > .col-sm-8, .row > .col-sm-7, .row > .col-sm-6, .row > .col-sm-5, .row > .col-sm-4, .row > .col-sm-3, .row > .col-sm-2, .row > .col-sm-1, .row > .col-sm-0, .flex > .col-sm-auto, .flex > .col-sm-12, .flex > .col-sm-11, .flex > .col-sm-10, .flex > .col-sm-9, .flex > .col-sm-8, .flex > .col-sm-7, .flex > .col-sm-6, .flex > .col-sm-5, .flex > .col-sm-4, .flex > .col-sm-3, .flex > .col-sm-2, .flex > .col-sm-1, .flex > .col-sm-0, .row > .col-sm-grow, .flex > .col-sm-grow, .row > .col-sm-shrink, .flex > .col-sm-shrink {
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .column > .col-sm, .flex > .col-sm, .column > .col-sm-auto, .column > .col-sm-12, .column > .col-sm-11, .column > .col-sm-10, .column > .col-sm-9, .column > .col-sm-8, .column > .col-sm-7, .column > .col-sm-6, .column > .col-sm-5, .column > .col-sm-4, .column > .col-sm-3, .column > .col-sm-2, .column > .col-sm-1, .column > .col-sm-0, .flex > .col-sm-auto, .flex > .col-sm-12, .flex > .col-sm-11, .flex > .col-sm-10, .flex > .col-sm-9, .flex > .col-sm-8, .flex > .col-sm-7, .flex > .col-sm-6, .flex > .col-sm-5, .flex > .col-sm-4, .flex > .col-sm-3, .flex > .col-sm-2, .flex > .col-sm-1, .flex > .col-sm-0, .column > .col-sm-grow, .flex > .col-sm-grow, .column > .col-sm-shrink, .flex > .col-sm-shrink {
    height: auto;
    min-height: 0;
    max-height: 100%;
  }

  .col-sm {
    flex: 10000;
  }

  .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm-0 {
    flex: none;
  }

  .col-sm-grow {
    flex: 1 0 auto;
  }

  .col-sm-shrink {
    flex: 0 auto;
  }

  .row > .col-sm-0 {
    width: 0%;
    height: auto;
  }

  .row > .offset-sm-0 {
    margin-left: 0%;
  }

  .column > .col-sm-0 {
    width: auto;
    height: 0%;
  }

  .row > .col-sm-1 {
    width: 8.3333%;
    height: auto;
  }

  .row > .offset-sm-1 {
    margin-left: 8.3333%;
  }

  .column > .col-sm-1 {
    width: auto;
    height: 8.3333%;
  }

  .row > .col-sm-2 {
    width: 16.6667%;
    height: auto;
  }

  .row > .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .column > .col-sm-2 {
    width: auto;
    height: 16.6667%;
  }

  .row > .col-sm-3 {
    width: 25%;
    height: auto;
  }

  .row > .offset-sm-3 {
    margin-left: 25%;
  }

  .column > .col-sm-3 {
    width: auto;
    height: 25%;
  }

  .row > .col-sm-4 {
    width: 33.3333%;
    height: auto;
  }

  .row > .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .column > .col-sm-4 {
    width: auto;
    height: 33.3333%;
  }

  .row > .col-sm-5 {
    width: 41.6667%;
    height: auto;
  }

  .row > .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .column > .col-sm-5 {
    width: auto;
    height: 41.6667%;
  }

  .row > .col-sm-6 {
    width: 50%;
    height: auto;
  }

  .row > .offset-sm-6 {
    margin-left: 50%;
  }

  .column > .col-sm-6 {
    width: auto;
    height: 50%;
  }

  .row > .col-sm-7 {
    width: 58.3333%;
    height: auto;
  }

  .row > .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .column > .col-sm-7 {
    width: auto;
    height: 58.3333%;
  }

  .row > .col-sm-8 {
    width: 66.6667%;
    height: auto;
  }

  .row > .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .column > .col-sm-8 {
    width: auto;
    height: 66.6667%;
  }

  .row > .col-sm-9 {
    width: 75%;
    height: auto;
  }

  .row > .offset-sm-9 {
    margin-left: 75%;
  }

  .column > .col-sm-9 {
    width: auto;
    height: 75%;
  }

  .row > .col-sm-10 {
    width: 83.3333%;
    height: auto;
  }

  .row > .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .column > .col-sm-10 {
    width: auto;
    height: 83.3333%;
  }

  .row > .col-sm-11 {
    width: 91.6667%;
    height: auto;
  }

  .row > .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .column > .col-sm-11 {
    width: auto;
    height: 91.6667%;
  }

  .row > .col-sm-12 {
    width: 100%;
    height: auto;
  }

  .row > .offset-sm-12 {
    margin-left: 100%;
  }

  .column > .col-sm-12 {
    width: auto;
    height: 100%;
  }
}

@media (width >= 1024px) {
  .row > .col-md, .flex > .col-md, .row > .col-md-auto, .row > .col-md-12, .row > .col-md-11, .row > .col-md-10, .row > .col-md-9, .row > .col-md-8, .row > .col-md-7, .row > .col-md-6, .row > .col-md-5, .row > .col-md-4, .row > .col-md-3, .row > .col-md-2, .row > .col-md-1, .row > .col-md-0, .flex > .col-md-auto, .flex > .col-md-12, .flex > .col-md-11, .flex > .col-md-10, .flex > .col-md-9, .flex > .col-md-8, .flex > .col-md-7, .flex > .col-md-6, .flex > .col-md-5, .flex > .col-md-4, .flex > .col-md-3, .flex > .col-md-2, .flex > .col-md-1, .flex > .col-md-0, .row > .col-md-grow, .flex > .col-md-grow, .row > .col-md-shrink, .flex > .col-md-shrink {
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .column > .col-md, .flex > .col-md, .column > .col-md-auto, .column > .col-md-12, .column > .col-md-11, .column > .col-md-10, .column > .col-md-9, .column > .col-md-8, .column > .col-md-7, .column > .col-md-6, .column > .col-md-5, .column > .col-md-4, .column > .col-md-3, .column > .col-md-2, .column > .col-md-1, .column > .col-md-0, .flex > .col-md-auto, .flex > .col-md-12, .flex > .col-md-11, .flex > .col-md-10, .flex > .col-md-9, .flex > .col-md-8, .flex > .col-md-7, .flex > .col-md-6, .flex > .col-md-5, .flex > .col-md-4, .flex > .col-md-3, .flex > .col-md-2, .flex > .col-md-1, .flex > .col-md-0, .column > .col-md-grow, .flex > .col-md-grow, .column > .col-md-shrink, .flex > .col-md-shrink {
    height: auto;
    min-height: 0;
    max-height: 100%;
  }

  .col-md {
    flex: 10000;
  }

  .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md-0 {
    flex: none;
  }

  .col-md-grow {
    flex: 1 0 auto;
  }

  .col-md-shrink {
    flex: 0 auto;
  }

  .row > .col-md-0 {
    width: 0%;
    height: auto;
  }

  .row > .offset-md-0 {
    margin-left: 0%;
  }

  .column > .col-md-0 {
    width: auto;
    height: 0%;
  }

  .row > .col-md-1 {
    width: 8.3333%;
    height: auto;
  }

  .row > .offset-md-1 {
    margin-left: 8.3333%;
  }

  .column > .col-md-1 {
    width: auto;
    height: 8.3333%;
  }

  .row > .col-md-2 {
    width: 16.6667%;
    height: auto;
  }

  .row > .offset-md-2 {
    margin-left: 16.6667%;
  }

  .column > .col-md-2 {
    width: auto;
    height: 16.6667%;
  }

  .row > .col-md-3 {
    width: 25%;
    height: auto;
  }

  .row > .offset-md-3 {
    margin-left: 25%;
  }

  .column > .col-md-3 {
    width: auto;
    height: 25%;
  }

  .row > .col-md-4 {
    width: 33.3333%;
    height: auto;
  }

  .row > .offset-md-4 {
    margin-left: 33.3333%;
  }

  .column > .col-md-4 {
    width: auto;
    height: 33.3333%;
  }

  .row > .col-md-5 {
    width: 41.6667%;
    height: auto;
  }

  .row > .offset-md-5 {
    margin-left: 41.6667%;
  }

  .column > .col-md-5 {
    width: auto;
    height: 41.6667%;
  }

  .row > .col-md-6 {
    width: 50%;
    height: auto;
  }

  .row > .offset-md-6 {
    margin-left: 50%;
  }

  .column > .col-md-6 {
    width: auto;
    height: 50%;
  }

  .row > .col-md-7 {
    width: 58.3333%;
    height: auto;
  }

  .row > .offset-md-7 {
    margin-left: 58.3333%;
  }

  .column > .col-md-7 {
    width: auto;
    height: 58.3333%;
  }

  .row > .col-md-8 {
    width: 66.6667%;
    height: auto;
  }

  .row > .offset-md-8 {
    margin-left: 66.6667%;
  }

  .column > .col-md-8 {
    width: auto;
    height: 66.6667%;
  }

  .row > .col-md-9 {
    width: 75%;
    height: auto;
  }

  .row > .offset-md-9 {
    margin-left: 75%;
  }

  .column > .col-md-9 {
    width: auto;
    height: 75%;
  }

  .row > .col-md-10 {
    width: 83.3333%;
    height: auto;
  }

  .row > .offset-md-10 {
    margin-left: 83.3333%;
  }

  .column > .col-md-10 {
    width: auto;
    height: 83.3333%;
  }

  .row > .col-md-11 {
    width: 91.6667%;
    height: auto;
  }

  .row > .offset-md-11 {
    margin-left: 91.6667%;
  }

  .column > .col-md-11 {
    width: auto;
    height: 91.6667%;
  }

  .row > .col-md-12 {
    width: 100%;
    height: auto;
  }

  .row > .offset-md-12 {
    margin-left: 100%;
  }

  .column > .col-md-12 {
    width: auto;
    height: 100%;
  }
}

@media (width >= 1440px) {
  .row > .col-lg, .flex > .col-lg, .row > .col-lg-auto, .row > .col-lg-12, .row > .col-lg-11, .row > .col-lg-10, .row > .col-lg-9, .row > .col-lg-8, .row > .col-lg-7, .row > .col-lg-6, .row > .col-lg-5, .row > .col-lg-4, .row > .col-lg-3, .row > .col-lg-2, .row > .col-lg-1, .row > .col-lg-0, .flex > .col-lg-auto, .flex > .col-lg-12, .flex > .col-lg-11, .flex > .col-lg-10, .flex > .col-lg-9, .flex > .col-lg-8, .flex > .col-lg-7, .flex > .col-lg-6, .flex > .col-lg-5, .flex > .col-lg-4, .flex > .col-lg-3, .flex > .col-lg-2, .flex > .col-lg-1, .flex > .col-lg-0, .row > .col-lg-grow, .flex > .col-lg-grow, .row > .col-lg-shrink, .flex > .col-lg-shrink {
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .column > .col-lg, .flex > .col-lg, .column > .col-lg-auto, .column > .col-lg-12, .column > .col-lg-11, .column > .col-lg-10, .column > .col-lg-9, .column > .col-lg-8, .column > .col-lg-7, .column > .col-lg-6, .column > .col-lg-5, .column > .col-lg-4, .column > .col-lg-3, .column > .col-lg-2, .column > .col-lg-1, .column > .col-lg-0, .flex > .col-lg-auto, .flex > .col-lg-12, .flex > .col-lg-11, .flex > .col-lg-10, .flex > .col-lg-9, .flex > .col-lg-8, .flex > .col-lg-7, .flex > .col-lg-6, .flex > .col-lg-5, .flex > .col-lg-4, .flex > .col-lg-3, .flex > .col-lg-2, .flex > .col-lg-1, .flex > .col-lg-0, .column > .col-lg-grow, .flex > .col-lg-grow, .column > .col-lg-shrink, .flex > .col-lg-shrink {
    height: auto;
    min-height: 0;
    max-height: 100%;
  }

  .col-lg {
    flex: 10000;
  }

  .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg-0 {
    flex: none;
  }

  .col-lg-grow {
    flex: 1 0 auto;
  }

  .col-lg-shrink {
    flex: 0 auto;
  }

  .row > .col-lg-0 {
    width: 0%;
    height: auto;
  }

  .row > .offset-lg-0 {
    margin-left: 0%;
  }

  .column > .col-lg-0 {
    width: auto;
    height: 0%;
  }

  .row > .col-lg-1 {
    width: 8.3333%;
    height: auto;
  }

  .row > .offset-lg-1 {
    margin-left: 8.3333%;
  }

  .column > .col-lg-1 {
    width: auto;
    height: 8.3333%;
  }

  .row > .col-lg-2 {
    width: 16.6667%;
    height: auto;
  }

  .row > .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .column > .col-lg-2 {
    width: auto;
    height: 16.6667%;
  }

  .row > .col-lg-3 {
    width: 25%;
    height: auto;
  }

  .row > .offset-lg-3 {
    margin-left: 25%;
  }

  .column > .col-lg-3 {
    width: auto;
    height: 25%;
  }

  .row > .col-lg-4 {
    width: 33.3333%;
    height: auto;
  }

  .row > .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .column > .col-lg-4 {
    width: auto;
    height: 33.3333%;
  }

  .row > .col-lg-5 {
    width: 41.6667%;
    height: auto;
  }

  .row > .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .column > .col-lg-5 {
    width: auto;
    height: 41.6667%;
  }

  .row > .col-lg-6 {
    width: 50%;
    height: auto;
  }

  .row > .offset-lg-6 {
    margin-left: 50%;
  }

  .column > .col-lg-6 {
    width: auto;
    height: 50%;
  }

  .row > .col-lg-7 {
    width: 58.3333%;
    height: auto;
  }

  .row > .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .column > .col-lg-7 {
    width: auto;
    height: 58.3333%;
  }

  .row > .col-lg-8 {
    width: 66.6667%;
    height: auto;
  }

  .row > .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .column > .col-lg-8 {
    width: auto;
    height: 66.6667%;
  }

  .row > .col-lg-9 {
    width: 75%;
    height: auto;
  }

  .row > .offset-lg-9 {
    margin-left: 75%;
  }

  .column > .col-lg-9 {
    width: auto;
    height: 75%;
  }

  .row > .col-lg-10 {
    width: 83.3333%;
    height: auto;
  }

  .row > .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .column > .col-lg-10 {
    width: auto;
    height: 83.3333%;
  }

  .row > .col-lg-11 {
    width: 91.6667%;
    height: auto;
  }

  .row > .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .column > .col-lg-11 {
    width: auto;
    height: 91.6667%;
  }

  .row > .col-lg-12 {
    width: 100%;
    height: auto;
  }

  .row > .offset-lg-12 {
    margin-left: 100%;
  }

  .column > .col-lg-12 {
    width: auto;
    height: 100%;
  }
}

@media (width >= 1920px) {
  .row > .col-xl, .flex > .col-xl, .row > .col-xl-auto, .row > .col-xl-12, .row > .col-xl-11, .row > .col-xl-10, .row > .col-xl-9, .row > .col-xl-8, .row > .col-xl-7, .row > .col-xl-6, .row > .col-xl-5, .row > .col-xl-4, .row > .col-xl-3, .row > .col-xl-2, .row > .col-xl-1, .row > .col-xl-0, .flex > .col-xl-auto, .flex > .col-xl-12, .flex > .col-xl-11, .flex > .col-xl-10, .flex > .col-xl-9, .flex > .col-xl-8, .flex > .col-xl-7, .flex > .col-xl-6, .flex > .col-xl-5, .flex > .col-xl-4, .flex > .col-xl-3, .flex > .col-xl-2, .flex > .col-xl-1, .flex > .col-xl-0, .row > .col-xl-grow, .flex > .col-xl-grow, .row > .col-xl-shrink, .flex > .col-xl-shrink {
    width: auto;
    min-width: 0;
    max-width: 100%;
  }

  .column > .col-xl, .flex > .col-xl, .column > .col-xl-auto, .column > .col-xl-12, .column > .col-xl-11, .column > .col-xl-10, .column > .col-xl-9, .column > .col-xl-8, .column > .col-xl-7, .column > .col-xl-6, .column > .col-xl-5, .column > .col-xl-4, .column > .col-xl-3, .column > .col-xl-2, .column > .col-xl-1, .column > .col-xl-0, .flex > .col-xl-auto, .flex > .col-xl-12, .flex > .col-xl-11, .flex > .col-xl-10, .flex > .col-xl-9, .flex > .col-xl-8, .flex > .col-xl-7, .flex > .col-xl-6, .flex > .col-xl-5, .flex > .col-xl-4, .flex > .col-xl-3, .flex > .col-xl-2, .flex > .col-xl-1, .flex > .col-xl-0, .column > .col-xl-grow, .flex > .col-xl-grow, .column > .col-xl-shrink, .flex > .col-xl-shrink {
    height: auto;
    min-height: 0;
    max-height: 100%;
  }

  .col-xl {
    flex: 10000;
  }

  .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl-0 {
    flex: none;
  }

  .col-xl-grow {
    flex: 1 0 auto;
  }

  .col-xl-shrink {
    flex: 0 auto;
  }

  .row > .col-xl-0 {
    width: 0%;
    height: auto;
  }

  .row > .offset-xl-0 {
    margin-left: 0%;
  }

  .column > .col-xl-0 {
    width: auto;
    height: 0%;
  }

  .row > .col-xl-1 {
    width: 8.3333%;
    height: auto;
  }

  .row > .offset-xl-1 {
    margin-left: 8.3333%;
  }

  .column > .col-xl-1 {
    width: auto;
    height: 8.3333%;
  }

  .row > .col-xl-2 {
    width: 16.6667%;
    height: auto;
  }

  .row > .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .column > .col-xl-2 {
    width: auto;
    height: 16.6667%;
  }

  .row > .col-xl-3 {
    width: 25%;
    height: auto;
  }

  .row > .offset-xl-3 {
    margin-left: 25%;
  }

  .column > .col-xl-3 {
    width: auto;
    height: 25%;
  }

  .row > .col-xl-4 {
    width: 33.3333%;
    height: auto;
  }

  .row > .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .column > .col-xl-4 {
    width: auto;
    height: 33.3333%;
  }

  .row > .col-xl-5 {
    width: 41.6667%;
    height: auto;
  }

  .row > .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .column > .col-xl-5 {
    width: auto;
    height: 41.6667%;
  }

  .row > .col-xl-6 {
    width: 50%;
    height: auto;
  }

  .row > .offset-xl-6 {
    margin-left: 50%;
  }

  .column > .col-xl-6 {
    width: auto;
    height: 50%;
  }

  .row > .col-xl-7 {
    width: 58.3333%;
    height: auto;
  }

  .row > .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .column > .col-xl-7 {
    width: auto;
    height: 58.3333%;
  }

  .row > .col-xl-8 {
    width: 66.6667%;
    height: auto;
  }

  .row > .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .column > .col-xl-8 {
    width: auto;
    height: 66.6667%;
  }

  .row > .col-xl-9 {
    width: 75%;
    height: auto;
  }

  .row > .offset-xl-9 {
    margin-left: 75%;
  }

  .column > .col-xl-9 {
    width: auto;
    height: 75%;
  }

  .row > .col-xl-10 {
    width: 83.3333%;
    height: auto;
  }

  .row > .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .column > .col-xl-10 {
    width: auto;
    height: 83.3333%;
  }

  .row > .col-xl-11 {
    width: 91.6667%;
    height: auto;
  }

  .row > .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .column > .col-xl-11 {
    width: auto;
    height: 91.6667%;
  }

  .row > .col-xl-12 {
    width: 100%;
    height: auto;
  }

  .row > .offset-xl-12 {
    margin-left: 100%;
  }

  .column > .col-xl-12 {
    width: auto;
    height: 100%;
  }
}

.rounded-borders {
  border-radius: 4px;
}

.border-radius-inherit {
  border-radius: inherit;
}

.no-transition {
  transition: none !important;
}

.transition-0 {
  transition: all !important;
}

.glossy {
  background-image: linear-gradient(#ffffff4d, #fff0 50%, #0000001f 51%, #0000000a) !important;
}

.q-placeholder::-webkit-input-placeholder {
  color: inherit;
  opacity: .7;
}

.q-placeholder::placeholder {
  color: inherit;
  opacity: .7;
}

.q-placeholder:-ms-input-placeholder {
  color: inherit !important;
  opacity: .7 !important;
}

.q-placeholder::placeholder {
  color: inherit;
  opacity: .7;
}

.q-body--fullscreen-mixin, .q-body--prevent-scroll {
  position: fixed !important;
}

.q-body--force-scrollbar-x {
  overflow-x: scroll;
}

.q-body--force-scrollbar-y {
  overflow-y: scroll;
}

.q-no-input-spinner {
  -moz-appearance: textfield !important;
}

.q-no-input-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.q-no-input-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.q-link {
  outline: 0;
  text-decoration: none;
}

.q-link--focusable:focus-visible {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
  text-decoration-thickness: 1px;
}

body.electron .q-electron-drag {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

body.electron .q-electron-drag .q-btn-item, body.electron .q-electron-drag--exception {
  -webkit-app-region: no-drag;
}

img.responsive {
  max-width: 100%;
  height: auto;
}

.non-selectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.scroll, body.mobile .scroll--mobile {
  overflow: auto;
}

.scroll, .scroll-x, .scroll-y {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.no-scroll {
  overflow: hidden !important;
}

.no-pointer-events, .no-pointer-events--children, .no-pointer-events--children * {
  pointer-events: none !important;
}

.all-pointer-events {
  pointer-events: all !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-inherit {
  cursor: inherit !important;
}

.cursor-none {
  cursor: none !important;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

.flip-horizontal {
  transform: scaleX(-1);
}

.flip-vertical {
  transform: scaleY(-1);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.relative-position {
  position: relative;
}

.fixed, .fixed-full, .fullscreen, .fixed-center, .fixed-bottom, .fixed-left, .fixed-right, .fixed-top, .fixed-top-left, .fixed-top-right, .fixed-bottom-left, .fixed-bottom-right {
  position: fixed;
}

.absolute, .absolute-full, .absolute-center, .absolute-bottom, .absolute-left, .absolute-right, .absolute-top, .absolute-top-left, .absolute-top-right, .absolute-bottom-left, .absolute-bottom-right {
  position: absolute;
}

.fixed-top, .absolute-top {
  top: 0;
  left: 0;
  right: 0;
}

.fixed-right, .absolute-right {
  top: 0;
  bottom: 0;
  right: 0;
}

.fixed-bottom, .absolute-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.fixed-left, .absolute-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.fixed-top-left, .absolute-top-left {
  top: 0;
  left: 0;
}

.fixed-top-right, .absolute-top-right {
  top: 0;
  right: 0;
}

.fixed-bottom-left, .absolute-bottom-left {
  bottom: 0;
  left: 0;
}

.fixed-bottom-right, .absolute-bottom-right {
  bottom: 0;
  right: 0;
}

.fullscreen {
  z-index: 6000;
  max-width: 100vw;
  max-height: 100vh;
  border-radius: 0 !important;
}

body.q-ios-padding .fullscreen {
  padding-top: 20px !important;
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.absolute-full, .fullscreen, .fixed-full {
  inset: 0;
}

.fixed-center, .absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-top {
  vertical-align: top !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.on-left {
  margin-right: 12px;
}

.on-right {
  margin-left: 12px;
}

.q-position-engine {
  will-change: auto;
  visibility: collapse;
  margin-top: var(--q-pe-top, 0) !important;
  margin-left: var(--q-pe-left, 0) !important;
}

:root {
  --q-size-xs: 0;
  --q-size-sm: 600px;
  --q-size-md: 1024px;
  --q-size-lg: 1440px;
  --q-size-xl: 1920px;
}

.fit {
  width: 100% !important;
  height: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.window-height {
  height: 100vh !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.window-width {
  width: 100vw !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.q-pa-none {
  padding: 0;
}

.q-pl-none {
  padding-left: 0;
}

.q-pr-none {
  padding-right: 0;
}

.q-pt-none {
  padding-top: 0;
}

.q-pb-none {
  padding-bottom: 0;
}

.q-px-none {
  padding-left: 0;
  padding-right: 0;
}

.q-py-none {
  padding-top: 0;
  padding-bottom: 0;
}

.q-ma-none {
  margin: 0;
}

.q-ml-none {
  margin-left: 0;
}

.q-mr-none {
  margin-right: 0;
}

.q-mt-none {
  margin-top: 0;
}

.q-mb-none {
  margin-bottom: 0;
}

.q-mx-none {
  margin-left: 0;
  margin-right: 0;
}

.q-my-none {
  margin-top: 0;
  margin-bottom: 0;
}

.q-pa-xs {
  padding: 4px;
}

.q-pl-xs {
  padding-left: 4px;
}

.q-pr-xs {
  padding-right: 4px;
}

.q-pt-xs {
  padding-top: 4px;
}

.q-pb-xs {
  padding-bottom: 4px;
}

.q-px-xs {
  padding-left: 4px;
  padding-right: 4px;
}

.q-py-xs {
  padding-top: 4px;
  padding-bottom: 4px;
}

.q-ma-xs {
  margin: 4px;
}

.q-ml-xs {
  margin-left: 4px;
}

.q-mr-xs {
  margin-right: 4px;
}

.q-mt-xs {
  margin-top: 4px;
}

.q-mb-xs {
  margin-bottom: 4px;
}

.q-mx-xs {
  margin-left: 4px;
  margin-right: 4px;
}

.q-my-xs {
  margin-top: 4px;
  margin-bottom: 4px;
}

.q-pa-sm {
  padding: 8px;
}

.q-pl-sm {
  padding-left: 8px;
}

.q-pr-sm {
  padding-right: 8px;
}

.q-pt-sm {
  padding-top: 8px;
}

.q-pb-sm {
  padding-bottom: 8px;
}

.q-px-sm {
  padding-left: 8px;
  padding-right: 8px;
}

.q-py-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}

.q-ma-sm {
  margin: 8px;
}

.q-ml-sm {
  margin-left: 8px;
}

.q-mr-sm {
  margin-right: 8px;
}

.q-mt-sm {
  margin-top: 8px;
}

.q-mb-sm {
  margin-bottom: 8px;
}

.q-mx-sm {
  margin-left: 8px;
  margin-right: 8px;
}

.q-my-sm {
  margin-top: 8px;
  margin-bottom: 8px;
}

.q-pa-md {
  padding: 16px;
}

.q-pl-md {
  padding-left: 16px;
}

.q-pr-md {
  padding-right: 16px;
}

.q-pt-md {
  padding-top: 16px;
}

.q-pb-md {
  padding-bottom: 16px;
}

.q-px-md {
  padding-left: 16px;
  padding-right: 16px;
}

.q-py-md {
  padding-top: 16px;
  padding-bottom: 16px;
}

.q-ma-md {
  margin: 16px;
}

.q-ml-md {
  margin-left: 16px;
}

.q-mr-md {
  margin-right: 16px;
}

.q-mt-md {
  margin-top: 16px;
}

.q-mb-md {
  margin-bottom: 16px;
}

.q-mx-md {
  margin-left: 16px;
  margin-right: 16px;
}

.q-my-md {
  margin-top: 16px;
  margin-bottom: 16px;
}

.q-pa-lg {
  padding: 24px;
}

.q-pl-lg {
  padding-left: 24px;
}

.q-pr-lg {
  padding-right: 24px;
}

.q-pt-lg {
  padding-top: 24px;
}

.q-pb-lg {
  padding-bottom: 24px;
}

.q-px-lg {
  padding-left: 24px;
  padding-right: 24px;
}

.q-py-lg {
  padding-top: 24px;
  padding-bottom: 24px;
}

.q-ma-lg {
  margin: 24px;
}

.q-ml-lg {
  margin-left: 24px;
}

.q-mr-lg {
  margin-right: 24px;
}

.q-mt-lg {
  margin-top: 24px;
}

.q-mb-lg {
  margin-bottom: 24px;
}

.q-mx-lg {
  margin-left: 24px;
  margin-right: 24px;
}

.q-my-lg {
  margin-top: 24px;
  margin-bottom: 24px;
}

.q-pa-xl {
  padding: 48px;
}

.q-pl-xl {
  padding-left: 48px;
}

.q-pr-xl {
  padding-right: 48px;
}

.q-pt-xl {
  padding-top: 48px;
}

.q-pb-xl {
  padding-bottom: 48px;
}

.q-px-xl {
  padding-left: 48px;
  padding-right: 48px;
}

.q-py-xl {
  padding-top: 48px;
  padding-bottom: 48px;
}

.q-ma-xl {
  margin: 48px;
}

.q-ml-xl {
  margin-left: 48px;
}

.q-mr-xl {
  margin-right: 48px;
}

.q-mt-xl {
  margin-top: 48px;
}

.q-mb-xl {
  margin-bottom: 48px;
}

.q-mx-xl {
  margin-left: 48px;
  margin-right: 48px;
}

.q-my-xl {
  margin-top: 48px;
  margin-bottom: 48px;
}

.q-mt-auto, .q-my-auto {
  margin-top: auto;
}

.q-ml-auto {
  margin-left: auto;
}

.q-mb-auto, .q-my-auto {
  margin-bottom: auto;
}

.q-mr-auto {
  margin-right: auto;
}

.q-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.q-touch {
  -webkit-user-select: none;
  user-select: none;
  user-drag: none;
  -khtml-user-drag: none;
  -webkit-user-drag: none;
}

.q-touch-x {
  touch-action: pan-x;
}

.q-touch-y {
  touch-action: pan-y;
}

:root {
  --q-transition-duration: .3s;
}

.q-transition--slide-right-enter-active, .q-transition--slide-right-leave-active, .q-transition--slide-left-enter-active, .q-transition--slide-left-leave-active, .q-transition--slide-up-enter-active, .q-transition--slide-up-leave-active, .q-transition--slide-down-enter-active, .q-transition--slide-down-leave-active, .q-transition--jump-right-enter-active, .q-transition--jump-right-leave-active, .q-transition--jump-left-enter-active, .q-transition--jump-left-leave-active, .q-transition--jump-up-enter-active, .q-transition--jump-up-leave-active, .q-transition--jump-down-enter-active, .q-transition--jump-down-leave-active, .q-transition--fade-enter-active, .q-transition--fade-leave-active, .q-transition--scale-enter-active, .q-transition--scale-leave-active, .q-transition--rotate-enter-active, .q-transition--rotate-leave-active, .q-transition--flip-enter-active, .q-transition--flip-leave-active {
  --q-transition-duration: .3s;
  --q-transition-easing: cubic-bezier(.215, .61, .355, 1);
}

.q-transition--slide-right-leave-active, .q-transition--slide-left-leave-active, .q-transition--slide-up-leave-active, .q-transition--slide-down-leave-active, .q-transition--jump-right-leave-active, .q-transition--jump-left-leave-active, .q-transition--jump-up-leave-active, .q-transition--jump-down-leave-active, .q-transition--fade-leave-active, .q-transition--scale-leave-active, .q-transition--rotate-leave-active, .q-transition--flip-leave-active {
  position: absolute;
}

.q-transition--slide-right-enter-active, .q-transition--slide-right-leave-active, .q-transition--slide-left-enter-active, .q-transition--slide-left-leave-active, .q-transition--slide-up-enter-active, .q-transition--slide-up-leave-active, .q-transition--slide-down-enter-active, .q-transition--slide-down-leave-active {
  transition: transform var(--q-transition-duration) var(--q-transition-easing);
}

.q-transition--slide-right-enter-from {
  transform: translate3d(-100%, 0, 0);
}

.q-transition--slide-right-leave-to, .q-transition--slide-left-enter-from {
  transform: translate3d(100%, 0, 0);
}

.q-transition--slide-left-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.q-transition--slide-up-enter-from {
  transform: translate3d(0, 100%, 0);
}

.q-transition--slide-up-leave-to, .q-transition--slide-down-enter-from {
  transform: translate3d(0, -100%, 0);
}

.q-transition--slide-down-leave-to {
  transform: translate3d(0, 100%, 0);
}

.q-transition--jump-right-enter-active, .q-transition--jump-right-leave-active, .q-transition--jump-left-enter-active, .q-transition--jump-left-leave-active, .q-transition--jump-up-enter-active, .q-transition--jump-up-leave-active, .q-transition--jump-down-enter-active, .q-transition--jump-down-leave-active {
  transition: opacity var(--q-transition-duration), transform var(--q-transition-duration);
}

.q-transition--jump-right-enter-from, .q-transition--jump-right-leave-to, .q-transition--jump-left-enter-from, .q-transition--jump-left-leave-to, .q-transition--jump-up-enter-from, .q-transition--jump-up-leave-to, .q-transition--jump-down-enter-from, .q-transition--jump-down-leave-to {
  opacity: 0;
}

.q-transition--jump-right-enter-from {
  transform: translate3d(-15px, 0, 0);
}

.q-transition--jump-right-leave-to, .q-transition--jump-left-enter-from {
  transform: translate3d(15px, 0, 0);
}

.q-transition--jump-left-leave-to {
  transform: translateX(-15px);
}

.q-transition--jump-up-enter-from {
  transform: translate3d(0, 15px, 0);
}

.q-transition--jump-up-leave-to, .q-transition--jump-down-enter-from {
  transform: translate3d(0, -15px, 0);
}

.q-transition--jump-down-leave-to {
  transform: translate3d(0, 15px, 0);
}

.q-transition--fade-enter-active, .q-transition--fade-leave-active {
  transition: opacity var(--q-transition-duration) ease-out;
}

.q-transition--fade-enter-from, .q-transition--fade-leave-to {
  opacity: 0;
}

.q-transition--scale-enter-active, .q-transition--scale-leave-active {
  transition: opacity var(--q-transition-duration), transform var(--q-transition-duration) var(--q-transition-easing);
}

.q-transition--scale-enter-from, .q-transition--scale-leave-to {
  opacity: 0;
  transform: scale3d(0, 0, 1);
}

.q-transition--rotate-enter-active, .q-transition--rotate-leave-active {
  transition: opacity var(--q-transition-duration), transform var(--q-transition-duration) var(--q-transition-easing);
  transform-style: preserve-3d;
}

.q-transition--rotate-enter-from, .q-transition--rotate-leave-to {
  opacity: 0;
  transform: scale3d(0, 0, 1)rotate3d(0, 0, 1, 90deg);
}

.q-transition--flip-right-enter-active, .q-transition--flip-right-leave-active, .q-transition--flip-left-enter-active, .q-transition--flip-left-leave-active, .q-transition--flip-up-enter-active, .q-transition--flip-up-leave-active, .q-transition--flip-down-enter-active, .q-transition--flip-down-leave-active {
  transition: transform var(--q-transition-duration);
  backface-visibility: hidden;
}

.q-transition--flip-right-enter-to, .q-transition--flip-right-leave-from, .q-transition--flip-left-enter-to, .q-transition--flip-left-leave-from, .q-transition--flip-up-enter-to, .q-transition--flip-up-leave-from, .q-transition--flip-down-enter-to, .q-transition--flip-down-leave-from {
  transform: perspective(400px)rotate3d(1, 1, 0, 0);
}

.q-transition--flip-right-enter-from {
  transform: perspective(400px)rotate3d(0, 1, 0, -180deg);
}

.q-transition--flip-right-leave-to, .q-transition--flip-left-enter-from {
  transform: perspective(400px)rotate3d(0, 1, 0, 180deg);
}

.q-transition--flip-left-leave-to {
  transform: perspective(400px)rotate3d(0, 1, 0, -180deg);
}

.q-transition--flip-up-enter-from {
  transform: perspective(400px)rotate3d(1, 0, 0, -180deg);
}

.q-transition--flip-up-leave-to, .q-transition--flip-down-enter-from {
  transform: perspective(400px)rotate3d(1, 0, 0, 180deg);
}

.q-transition--flip-down-leave-to {
  transform: perspective(400px)rotate3d(1, 0, 0, -180deg);
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  min-width: 100px;
  min-height: 100%;
  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

h1 {
  letter-spacing: -.01562em;
  font-size: 6rem;
  font-weight: 300;
  line-height: 6rem;
}

h2 {
  letter-spacing: -.00833em;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 3.75rem;
}

h3 {
  letter-spacing: normal;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.125rem;
}

h4 {
  letter-spacing: .00735em;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 2.5rem;
}

h5 {
  letter-spacing: normal;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

h6 {
  letter-spacing: .0125em;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

p {
  margin: 0 0 16px;
}

.text-h1 {
  letter-spacing: -.01562em;
  font-size: 6rem;
  font-weight: 300;
  line-height: 6rem;
}

.text-h2 {
  letter-spacing: -.00833em;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 3.75rem;
}

.text-h3 {
  letter-spacing: normal;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3.125rem;
}

.text-h4 {
  letter-spacing: .00735em;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.text-h5 {
  letter-spacing: normal;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

.text-h6 {
  letter-spacing: .0125em;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
}

.text-subtitle1 {
  letter-spacing: .00937em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.text-subtitle2 {
  letter-spacing: .00714em;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.text-body1 {
  letter-spacing: .03125em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.text-body2 {
  letter-spacing: .01786em;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.text-overline {
  letter-spacing: .16667em;
  font-size: .75rem;
  font-weight: 500;
  line-height: 2rem;
}

.text-caption {
  letter-spacing: .03333em;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-strike {
  text-decoration: line-through;
}

.text-weight-thin {
  font-weight: 100;
}

.text-weight-light {
  font-weight: 300;
}

.text-weight-regular {
  font-weight: 400;
}

.text-weight-medium {
  font-weight: 500;
}

.text-weight-bold {
  font-weight: 700;
}

.text-weight-bolder {
  font-weight: 900;
}

small {
  font-size: 80%;
}

big {
  font-size: 170%;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.no-outline {
  outline: 0 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsis-2-lines, .ellipsis-3-lines {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.ellipsis-2-lines {
  -webkit-line-clamp: 2;
}

.ellipsis-3-lines {
  -webkit-line-clamp: 3;
}

.readonly {
  cursor: default !important;
}

.disabled, .disabled *, [disabled], [disabled] * {
  cursor: not-allowed !important;
  outline: 0 !important;
}

.disabled, [disabled] {
  opacity: .6 !important;
}

.hidden {
  display: none !important;
}

.invisible, .invisible * {
  visibility: hidden !important;
  transition: none !important;
  animation: none !important;
}

.transparent {
  background: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden-y {
  overflow-y: hidden !important;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.dimmed:after, .light-dimmed:after {
  content: "";
  position: absolute;
  inset: 0;
}

.dimmed:after {
  background: #0006 !important;
}

.light-dimmed:after {
  background: #fff9 !important;
}

.z-top {
  z-index: 7000 !important;
}

.z-max {
  z-index: 9998 !important;
}

body:not(.desktop) .desktop-only, body.desktop .desktop-hide, body:not(.mobile) .mobile-only, body.mobile .mobile-hide, body:not(.native-mobile) .native-mobile-only, body.native-mobile .native-mobile-hide, body:not(.cordova) .cordova-only, body.cordova .cordova-hide, body:not(.capacitor) .capacitor-only, body.capacitor .capacitor-hide, body:not(.electron) .electron-only, body.electron .electron-hide, body:not(.touch) .touch-only, body.touch .touch-hide, body:not(.within-iframe) .within-iframe-only, body.within-iframe .within-iframe-hide, body:not(.platform-ios) .platform-ios-only, body.platform-ios .platform-ios-hide, body:not(.platform-android) .platform-android-only, body.platform-android .platform-android-hide {
  display: none !important;
}

@media (orientation: portrait) {
  .orientation-landscape {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .orientation-portrait {
    display: none !important;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {
  .print-hide {
    display: none !important;
  }
}

@media (width <= 599.98px) {
  .xs-hide, .gt-xs, .sm, .gt-sm, .md, .gt-md, .lg, .gt-lg, .xl {
    display: none !important;
  }
}

@media (width >= 600px) and (width <= 1023.98px) {
  .sm-hide, .xs, .lt-sm, .gt-sm, .md, .gt-md, .lg, .gt-lg, .xl {
    display: none !important;
  }
}

@media (width >= 1024px) and (width <= 1439.98px) {
  .md-hide, .xs, .lt-sm, .sm, .lt-md, .gt-md, .lg, .gt-lg, .xl {
    display: none !important;
  }
}

@media (width >= 1440px) and (width <= 1919.98px) {
  .lg-hide, .xs, .lt-sm, .sm, .lt-md, .md, .lt-lg, .gt-lg, .xl {
    display: none !important;
  }
}

@media (width >= 1920px) {
  .xl-hide, .xs, .lt-sm, .sm, .lt-md, .md, .lt-lg, .lg, .lt-xl {
    display: none !important;
  }
}

.q-focus-helper, .q-focusable, .q-manual-focusable, .q-hoverable {
  outline: 0;
}

body.desktop .q-focus-helper {
  pointer-events: none;
  border-radius: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: background-color .3s cubic-bezier(.25, .8, .5, 1), opacity .4s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  top: 0;
  left: 0;
}

body.desktop .q-focus-helper:before, body.desktop .q-focus-helper:after {
  content: "";
  opacity: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  transition: background-color .3s cubic-bezier(.25, .8, .5, 1), opacity .6s cubic-bezier(.25, .8, .5, 1);
  position: absolute;
  top: 0;
  left: 0;
}

body.desktop .q-focus-helper:before {
  background: #000;
}

body.desktop .q-focus-helper:after {
  background: #fff;
}

body.desktop .q-focus-helper--rounded {
  border-radius: 4px;
}

body.desktop .q-focus-helper--round {
  border-radius: 50%;
}

body.desktop .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper, body.desktop .q-hoverable:hover > .q-focus-helper {
  opacity: .15;
  background: currentColor;
}

body.desktop .q-focusable:focus > .q-focus-helper:before, body.desktop .q-manual-focusable--focused > .q-focus-helper:before, body.desktop .q-hoverable:hover > .q-focus-helper:before {
  opacity: .1;
}

body.desktop .q-focusable:focus > .q-focus-helper:after, body.desktop .q-manual-focusable--focused > .q-focus-helper:after, body.desktop .q-hoverable:hover > .q-focus-helper:after {
  opacity: .4;
}

body.desktop .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper {
  opacity: .22;
}

body.body--dark {
  color: #fff;
  background: var(--q-dark-page);
}

.q-dark {
  color: #fff;
  background: var(--q-dark);
}

._body_x43jy_2 {
  margin: 0;
  padding: 0 10px;
}

._question_1vklq_2 {
  text-align: justify;
  margin-bottom: 5px;
  padding: 10px;
  font-size: 26px;
}

._answers_1vklq_10 {
  flex-flow: wrap;
  padding-top: 18px;
  padding-left: 26px;
  display: flex;
}

._answer_1vklq_10 {
  color: var(--answer-text-color);
  background-color: var(--answer-bg-color);
  text-align: center;
  box-shadow: 5px 3px 5px var(--answer-shadow-bg-color);
  border: 2px solid #0000;
  border-radius: 8px;
  min-width: 65px;
  margin: 5px;
  padding: 10px;
  position: relative;
}

i._answerIcon_1vklq_31 {
  color: green;
  display: none;
  position: absolute;
  top: -13px;
  right: -10px;
}

._selected_1vklq_39 {
  background-color: var(--answer-selected-bg-color);
  color: var(--answer-selected-text-color);
  border-color: var(--answer-selected-border-color);
}

i._visible_1vklq_45 {
  display: inline-block;
}

._answer_1vklq_10:hover {
  cursor: pointer;
  background-color: var(--answer-hover-bg-color);
  color: var(--answer-hover-text-color);
}

._actions_1vklq_55 {
  justify-content: space-around;
  margin-top: 20px;
  display: flex;
}

._page_1vklq_61 {
  padding: 10px;
}

._questionsArea_1vklq_65 {
  padding-top: 15px;
}

._questionsTable_1vklq_69 {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

._questionsTable_1vklq_69 td:nth-child(2n) {
  background-color: var(--table-strip-bg-color);
}

._questionsTable_1vklq_69 tr:hover td {
  background-color: var(--table-row-hover-bg-color) !important;
}

._questionsTable_1vklq_69 th {
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px 15px;
}

._questionsTable_1vklq_69 td {
  padding: 20px 0 20px 10px;
}

._questionsTable_1vklq_69 tr {
  border-bottom: solid 1px var(--border-color);
}

._questionColumn_1vklq_96 {
  text-align: justify;
  max-width: 800px;
  padding-right: 10px !important;
}

._answerCell_1vklq_102 {
  text-align: center;
}

._tableHeader_1vklq_106 {
  z-index: 999;
  background-color: #fff;
  padding: 20px;
  position: sticky;
  top: 0;
}

._done_1vklq_114 {
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  display: flex;
}

._body_1bvgx_2 {
  margin: 0;
  padding: 0;
}

._survey_1bvgx_7:hover {
  background-color: #0000001a;
}

._clickable_1bvgx_11 {
  cursor: pointer;
}

._area_1mdrh_2 {
  justify-content: center;
  align-items: center;
}

._title_1_ykddu_2 {
  font-size: 32px;
}

._title_2_ykddu_6 {
  font-size: 24px;
}

._title_3_ykddu_10 {
  font-size: 18px;
}

._editor_ykddu_14 {
  position: relative;
  top: 52px;
}

._question_ykddu_19 {
  background-color: #0000001a;
  border: 1px solid #00000080;
  border-radius: 8px;
  margin: 5px;
  padding: 10px;
  position: relative;
}

._actions_ykddu_28 {
  justify-content: space-between;
  gap: 0 5px;
  padding: 5px 0;
  display: flex;
}

._pages_ykddu_35 {
  flex-direction: row;
  align-items: flex-end;
  gap: 10px 0;
  display: flex;
}

._pagesColumn_ykddu_42 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

._pagesColumn_ykddu_42 ._page_ykddu_35 {
  max-width: inherit;
  width: 100%;
}

._page_ykddu_35 {
  border-color: var(--border-color);
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 1px 1px 0;
  max-width: 120px;
  height: 33px;
  padding: 5px 10px;
  overflow: hidden;
}

._page_ykddu_35._selected_ykddu_66 {
  max-width: inherit;
  height: 36px;
  font-weight: bold;
}

._pageActions_ykddu_72 {
  justify-content: flex-end;
  display: flex;
}

._pageTitle_ykddu_77 {
  flex: 1;
  max-width: 300px;
}

._selected_ykddu_66 {
  background-color: var(--select-bg-color) !important;
}

[aria-expanded="true"] {
  background-color: var(--select-bg-color);
}

._floatingToolbar_ykddu_90 {
  z-index: 9;
  background-color: #fff;
  width: 100%;
  position: fixed;
}

/*# sourceMappingURL=index.c8c991dc.css.map */
