.rotate-45
  transform: rotate(45deg) #{"/* rtl:ignore */"}
.rotate-90
  transform: rotate(90deg) #{"/* rtl:ignore */"}
.rotate-135
  transform: rotate(135deg) #{"/* rtl:ignore */"}
.rotate-180
  transform: rotate(180deg) #{"/* rtl:ignore */"}
.rotate-225
  transform: rotate(225deg) #{"/* rtl:ignore */"}
.rotate-270
  transform: rotate(270deg) #{"/* rtl:ignore */"}
.rotate-315
  transform: rotate(315deg) #{"/* rtl:ignore */"}
.flip-horizontal
  transform: scaleX(-1)
.flip-vertical
  transform: scaleY(-1)
