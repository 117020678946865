.q-responsive
  position: relative
  max-width: 100%
  max-height: 100%

  &__filler
    width: inherit
    max-width: inherit
    height: inherit
    max-height: inherit

  &__content
    border-radius: inherit

    > *
      width: 100% !important
      height: 100% !important
      max-height: 100% !important
      max-width: 100% !important
