$checkbox-transition: .22s cubic-bezier(0,0,.2,1) 0ms

.q-checkbox
  vertical-align: middle

  &__native
    width: 1px
    height: 1px

  &__bg,
  &__icon-container
    user-select: none

  &__bg
    top: 25%
    left: 25%
    width: 50%
    height: 50%
    border: 2px solid currentColor
    border-radius: 2px
    transition: background $checkbox-transition
    -webkit-print-color-adjust: exact

  &__icon
    color: currentColor
    font-size: .5em

  &__svg
    color: #fff

  &__truthy
    stroke: currentColor
    stroke-width: 3.12px
    stroke-dashoffset: 29.78334
    stroke-dasharray: 29.78334

  &__indet
    fill: currentColor
    transform-origin: 50% 50%
    transform: rotate(-280deg) scale(0)

  &__inner
    font-size: 40px
    width: 1em
    min-width: 1em
    height: 1em
    outline: 0
    border-radius: 50%
    color: rgba(0,0,0,.54)

    &--truthy, &--indet
      color: var(--q-primary)
      .q-checkbox__bg
        background: currentColor

    &--truthy
      path
        stroke-dashoffset: 0
        transition: stroke-dashoffset .18s cubic-bezier(.4,0,.6,1) 0ms

    &--indet
      .q-checkbox__indet
        transform: rotate(0) scale(1)
        transition: transform $checkbox-transition

  &.disabled
    opacity: .75 !important

  &--dark
    .q-checkbox__inner
      color: rgba(255,255,255,.7)
      &:before
        opacity: .32 !important
      &--truthy, &--indet
        color: var(--q-primary)

  &--dense

    .q-checkbox__inner
      width: .5em
      min-width: .5em
      height: .5em

    .q-checkbox__bg
      left: 5%
      top: 5%
      width: 90%
      height: 90%

    .q-checkbox__label
      padding-left: .5em

    &.reverse .q-checkbox__label
      padding-left: 0
      padding-right: .5em

body.desktop

  .q-checkbox:not(.disabled)
    .q-checkbox__inner:before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: 50%
      background: currentColor
      opacity: .12
      transform: scale3d(0, 0, 1)
      transition: transform $option-focus-transition

    &:focus,
    &:hover
      .q-checkbox__inner:before
        transform: scale3d(1, 1, 1)

  .q-checkbox--dense:not(.disabled)
    &:focus,
    &:hover
      .q-checkbox__inner:before
        transform: scale3d(1.4, 1.4, 1)
