/*
 * Animate.css additions
 * Adapted from: https://github.com/animate-css/animate.css/blob/6828621a01e145119db6194dc9b4d37325b48aa5/source/_base.css
 */

\:root
  --animate-duration: #{$animate-duration}
  --animate-delay: #{$animate-delay}
  --animate-repeat: #{$animate-repeat}

.animated
  animation-duration: var(--animate-duration)
  animation-fill-mode: both

  &.infinite
    animation-iteration-count: infinite

  // Last seen on v3.5.2
  &.hinge
    animation-duration: 2s

  &.repeat-1
    animation-iteration-count: var(--animate-repeat)

  &.repeat-2
    animation-iteration-count: calc(var(--animate-repeat) * 2)

  &.repeat-3
    animation-iteration-count: calc(var(--animate-repeat) * 3)

  &.delay-1s
    animation-delay: var(--animate-delay)

  &.delay-2s
    animation-delay: calc(var(--animate-delay) * 2)

  &.delay-3s
    animation-delay: calc(var(--animate-delay) * 3)

  &.delay-4s
    animation-delay: calc(var(--animate-delay) * 4)

  &.delay-5s
    animation-delay: calc(var(--animate-delay) * 5)

  &.faster
    animation-duration: calc(var(--animate-duration) / 2)

  &.fast
    animation-duration: calc(var(--animate-duration) * 0.8)

  &.slow
    animation-duration: calc(var(--animate-duration) * 2)

  &.slower
    animation-duration: calc(var(--animate-duration) * 3)

@media print, (prefers-reduced-motion: reduce)
  .animated
    animation-duration: 1ms !important
    transition-duration: 1ms !important
    animation-iteration-count: 1 !important

  .animated[class*='Out']
    opacity: 0

/*
 * Quasar animations
 */

.q-animate--scale
  animation: q-scale .15s
  animation-timing-function: cubic-bezier(.25,.8,.25,1)

@keyframes q-scale
  0%
    transform: scale(1)
  50%
    transform: scale(1.04)
  100%
    transform: scale(1)

.q-animate--fade
  animation: q-fade .2s #{"/* rtl:ignore */"}

@keyframes q-fade
  0%
    opacity: 0
  100%
    opacity: 1
