.q-scrollarea
  position: relative
  contain: strict

  &__bar,
  &__thumb
    opacity: .2
    transition: opacity .3s
    will-change: opacity
    cursor: grab

    &--v
      right: 0
      width: 10px

    &--h
      bottom: 0
      height: 10px

    &--invisible
      opacity: 0 !important
      pointer-events: none

  &__thumb
    background: #000
    border-radius: 3px

    &:hover
      opacity: .3
    &:active
      opacity: .5

  &__content
    min-height: 100%
    min-width: 100%

  &--dark .q-scrollarea__thumb
    background: #fff
