.q-loading-bar
  position: fixed
  z-index: $z-max
  transition: transform .5s cubic-bezier(0,0,.2,1), opacity .5s
  background: $red

  &--top
    left: 0 #{"/* rtl:ignore */"}
    right: 0 #{"/* rtl:ignore */"}
    top: 0
    width: 100%
  &--bottom
    left: 0 #{"/* rtl:ignore */"}
    right: 0 #{"/* rtl:ignore */"}
    bottom: 0
    width: 100%

  &--right
    top: 0
    bottom: 0
    right: 0
    height: 100%
  &--left
    top: 0
    bottom: 0
    left: 0
    height: 100%
