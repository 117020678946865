.q-img
  position: relative
  width: 100%
  display: inline-block
  vertical-align: middle
  overflow: hidden

  &__loading .q-spinner
    font-size: $img-loading-font-size

  &__container
    border-radius: inherit
    font-size: 0

  &__image
    border-radius: inherit
    width: 100%
    height: 100%
    opacity: 0

    &--with-transition
      transition: opacity .28s ease-in

    &--loaded
      opacity: 1

  &__content
    border-radius: inherit
    pointer-events: none

    > div
      pointer-events: all
      position: $img-content-position
      padding: $img-content-padding
      color: $img-content-color
      background: $img-content-background

  &--no-menu
    .q-img__image,
    .q-img__placeholder
      pointer-events: none
