.q-markup-table
  overflow: auto
  background: #fff

.q-table
  width: 100%
  max-width: 100%
  border-collapse: separate
  border-spacing: 0

  thead tr, tbody td
    height: 48px

  th
    font-weight: 500
    font-size: 12px
    user-select: none
    &.sortable
      cursor: pointer
      &:hover .q-table__sort-icon
        opacity: .64
    &.sorted .q-table__sort-icon
      opacity: .86 !important
    &.sort-desc .q-table__sort-icon
      transform: rotate(180deg)

  th, td
    padding: 7px 16px
    background-color: inherit

  thead, td, th
    border-style: solid
    border-width: 0

  tbody td
    font-size: 13px

  &__card
    color: #000
    background-color: #fff
    border-radius: $table-border-radius
    box-shadow: $table-box-shadow

    .q-table__middle
      flex: 1 1 auto

    .q-table__top,
    .q-table__bottom
      flex: 0 0 auto

  &__container
    position: relative

    > div:first-child
      border-top-left-radius: inherit
      border-top-right-radius: inherit

    > div:last-child
      border-bottom-left-radius: inherit
      border-bottom-right-radius: inherit

    > .q-inner-loading
      border-radius: inherit !important

  &__top
    padding: 12px 16px
    .q-table__control
      flex-wrap: wrap

  &__title
    font-size: 20px
    letter-spacing: .005em
    font-weight: 400

  &__separator
    min-width: 8px !important

  &__progress
    height: 0 !important
    th
      padding: 0 !important
      border: 0 !important
    .q-linear-progress
      position: absolute
      bottom: 0

  &__middle
    max-width: 100%

  &__bottom
    min-height: 50px
    padding: 4px 14px 4px 16px
    font-size: 12px

    .q-table__control
      min-height: 24px

  &__bottom-nodata-icon
    font-size: 200%
    margin-right: 8px

  &__bottom-item
    margin-right: 16px

  &__control
    display: flex
    align-items: center

  &__sort-icon
    transition: transform $table-transition
    opacity: 0
    font-size: 120%

    &--left, &--center
      margin-left: 4px
    &--right
      margin-right: 4px

  &--col-auto-width
    width: 1px

// positioning selector here
// so user-land overwrites with !important are not required
// and also for --flat/--grid to not require !important by default
.q-table__card--dark,
.q-table--dark
  box-shadow: $table-box-shadow-dark

.q-table

  &--flat
    box-shadow: none
  &--bordered
    border: 1px solid $table-border-color
  &--square
    border-radius: 0

  &__linear-progress
    height: 2px

  &--no-wrap
    th, td
      white-space: nowrap

  &--grid
    box-shadow: none
    border-radius: $generic-border-radius
    .q-table__top
      padding-bottom: 4px
    .q-table__middle
      min-height: 2px
      margin-bottom: 4px
      thead
        &, th
          border: 0 !important
    .q-table__linear-progress
      bottom: 0
    .q-table__bottom
      border-top: 0
    .q-table__grid-content
      flex: 1 1 auto

    &.fullscreen
      background: inherit

  &__grid-item-card
    vertical-align: top
    padding: 12px

    .q-separator
      margin: 12px 0

  &__grid-item-row + &__grid-item-row
    margin-top: 8px

  &__grid-item-title
    opacity: .54
    font-weight: 500
    font-size: 12px

  &__grid-item-value
    font-size: 13px

  &__grid-item
    padding: 4px
    transition: transform $table-transition

    &--selected
      transform: scale(.95)

/*
 * Separators
 */
.q-table--horizontal-separator, .q-table--cell-separator
  thead th, tbody tr:not(:last-child) > td
    border-bottom-width: 1px

.q-table--vertical-separator, .q-table--cell-separator
  td, th
    border-left-width: 1px
  thead tr:last-child th,
  &.q-table--loading tr:nth-last-child(2) th
    border-bottom-width: 1px
  td:first-child, th:first-child
    border-left: 0
  .q-table__top
    border-bottom: 1px solid $table-border-color

/*
 * dense size
 */

.q-table--dense
  .q-table__top
    padding: 6px 16px
  .q-table__bottom
    min-height: 33px

  .q-table__sort-icon
    font-size: 110%
  .q-table
    th, td
      padding: 4px 8px
    thead tr, tbody tr, tbody td
      height: 28px
    th:first-child, td:first-child
      padding-left: 16px
    th:last-child, td:last-child
      padding-right: 16px

  .q-table__bottom-item
    margin-right: 8px

  .q-table__select
    .q-field__control, .q-field__native
      min-height: 24px
      padding: 0
    .q-field__marginal
      height: 24px

/*
 * On light background
 */
.q-table__bottom // @stylint ignore
  border-top: 1px solid $table-border-color

.q-table // @stylint ignore
  thead, tr, th, td
    border-color: $table-border-color

  tbody
    td
      position: relative
      &:before, &:after
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        pointer-events: none
      &:before
        background: $table-hover-background
      &:after
        background: $table-selected-background

    tr.selected td:after
      content: ''

body.desktop .q-table > tbody > tr:not(.q-tr--no-hover):hover > td:not(.q-td--no-hover):before
  content: ''

/*
 * On dark background
 */
.q-table__card--dark,
.q-table--dark
  border-color: $table-dark-border-color

.q-table--dark

  .q-table__bottom, thead, tr, th, td
    border-color: $table-dark-border-color

  tbody
    td
      &:before
        background: $table-dark-hover-background
      &:after
        background: $table-dark-selected-background

  &.q-table--vertical-separator, &.q-table--cell-separator
    .q-table__top
      border-color: $table-dark-border-color
