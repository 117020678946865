.q-color-picker
  overflow: hidden
  background: #fff
  max-width: 350px
  vertical-align: top

  min-width: 180px

  border-radius: $generic-border-radius
  box-shadow: $shadow-2

  .q-tab
    padding: 0 !important

  &--bordered
    border: 1px solid $separator-color

  &__header

    &-tabs
      height: 32px
    &-banner
      height: 36px

    input
      line-height: 24px
      border: 0

    .q-tab
      min-height: 32px !important
      height: 32px !important

      &--inactive
        background: linear-gradient(to top, rgba(0,0,0,.3) 0%, rgba(0,0,0,.15) 25%, rgba(0,0,0,.1))

  &__error-icon
    bottom: 2px
    right: 2px
    font-size: 24px
    opacity: 0
    transition: opacity .3s ease-in

  &__header-content
    position: relative
    background: #fff

    &--light
      color: #000

    &--dark
      color: #fff
      .q-tab--inactive
        &:before
          content: ''
          position: absolute
          top: 0
          right: 0
          bottom: 0
          left: 0
          background: rgba(255,255,255,.2)

  &__header-banner
    height: 36px
  &__header-bg
    background: #fff
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAH0lEQVQoU2NkYGAwZkAFZ5G5jPRRgOYEVDeB3EBjBQBOZwTVugIGyAAAAABJRU5ErkJggg==') !important

  &__footer
    height: 36px

    .q-tab
      min-height: 36px !important
      height: 36px !important

      &--inactive
        background: linear-gradient(to bottom, rgba(0,0,0,.3) 0%, rgba(0,0,0,.15) 25%, rgba(0,0,0,.1))

  /* Saturation Tab */

  &__spectrum
    width: 100%
    height: 100%

  &__spectrum-tab
    padding: 0 !important

  &__spectrum-white
    background: linear-gradient(to right, #fff, rgba(255,255,255,0))

  &__spectrum-black
    background: linear-gradient(to top, #000, rgba(0,0,0,0))

  &__spectrum-circle
    width: 10px
    height: 10px
    box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0,0,0,.3), 0 0 1px 2px rgba(0,0,0,.4)
    border-radius: 50%
    transform: translate(-5px, -5px)

  &__hue .q-slider__track
    background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%) !important // @stylint ignore
    opacity: 1

  &__alpha
    .q-slider__track-container
      padding-top: 0
    .q-slider__track:before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      border-radius: inherit
      background: linear-gradient(90deg, rgba(255,255,255,0), $grey-7)

  &__sliders
    padding: 0 16px

    .q-slider__thumb
      color: $grey-9
      path
        stroke-width: 2px
        fill: transparent
    .q-slider--active path
      stroke-width: 3px

  /* Tune Tab */

  &__tune-tab

    .q-slider
      margin-left: 18px
      margin-right: 18px

    input
      font-size: 11px
      border: 1px solid $grey-4
      border-radius: $generic-border-radius
      width: 3.5em

  /* Palette Tab */

  &__palette-tab
    padding: 0 !important

  &__palette-rows
    &--editable .q-color-picker__cube
      cursor: pointer

  &__cube
    padding-bottom: 10%
    width: 10% !important

  /* Generic  */

  input
    color: inherit
    background: transparent
    outline: 0
    text-align: center
  .q-tabs
    overflow: hidden
  .q-tab--active
    box-shadow: 0 0 14px 3px rgba(0,0,0,.2)
    .q-focus-helper
      display: none
  .q-tab__indicator
    display: none
  .q-tab-panels
    background: inherit

  &--dark
    box-shadow: $dark-shadow-2

    .q-color-picker__tune-tab input
      border: 1px solid rgba(#fff, .3)

    .q-slider__thumb
      color: $grey-1
