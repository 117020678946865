.q-uploader
  box-shadow: $shadow-2
  border-radius: $generic-border-radius
  vertical-align: top
  background: #fff
  position: relative

  width: 320px
  max-height: 320px

  &--bordered
    border: 1px solid $separator-color

  &__input
    opacity: 0
    width: 100%
    height: 100%
    cursor: pointer !important
    z-index: 1 // needed when wrapped by QBtn with icon-right

    // force cursor pointer for native control
    &::-webkit-file-upload-button
      cursor: pointer

  &__file
    &:before
      content: ''
      border-top-left-radius: inherit
      border-top-right-radius: inherit
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      pointer-events: none
      background: currentColor
      opacity: .04

  &__header
    position: relative
    border-top-left-radius: inherit
    border-top-right-radius: inherit
    background-color: var(--q-primary)
    color: #fff
    width: 100%

  &__spinner
    font-size: 24px
    margin-right: 4px

  &__header-content
    padding: 8px

  &__dnd
    outline: 1px dashed currentColor
    outline-offset: -4px
    background: rgba(255,255,255,.6)

  &__overlay
    font-size: 36px
    color: #000
    background-color: rgba(255,255,255,.6)

  &__list
    position: relative
    border-bottom-left-radius: inherit
    border-bottom-right-radius: inherit
    padding: 8px
    min-height: 60px
    flex: 1 1 auto

  &__file
    border-radius: $generic-border-radius $generic-border-radius 0 0
    border: 1px solid $separator-color

    .q-circular-progress
      font-size: 24px

    &--img
      color: #fff
      height: 200px
      min-width: 200px
      background-position: 50% 50%
      background-size: cover
      background-repeat: no-repeat

      &:before
        content: none

      .q-circular-progress
        color: #fff

      .q-uploader__file-header
        padding-bottom: 24px
        background: linear-gradient(to bottom, rgba(0,0,0,.7) 20%, rgba(255,255,255,0))

    & + &
      margin-top: 8px

  &__file-header
    position: relative
    padding: 4px 8px
    border-top-left-radius: inherit
    border-top-right-radius: inherit

  &__file-header-content
    padding-right: 8px

  &__file-status
    font-size: 24px
    margin-right: 4px

  &__title
    font-size: 14px
    font-weight: bold
    line-height: 18px
    word-break: break-word
  &__subtitle
    font-size: 12px
    line-height: 18px

  &--disable
    .q-uploader__header, .q-uploader__list
      pointer-events: none

  &--dark
    border-color: $separator-dark-color
    box-shadow: $dark-shadow-2

    .q-uploader__file
      border-color: $separator-dark-color
    .q-uploader__dnd, .q-uploader__overlay
      background: rgba(255,255,255,.3)
    .q-uploader__overlay
      color: #fff
