.q-textarea

  .q-field__control
    min-height: 56px
    height: auto

  .q-field__control-container
    padding-top: 2px
    padding-bottom: 2px

  .q-field__shadow
    top: 2px
    bottom: 2px

  .q-field__native, .q-field__prefix, .q-field__suffix
    line-height: 18px

  .q-field__native
    resize: vertical
    padding-top: 17px
    min-height: 52px

  &.q-field--labeled
    .q-field__control-container
      padding-top: 26px
    .q-field__shadow
      top: 26px
    .q-field__native, .q-field__prefix, .q-field__suffix
      padding-top: 0
    .q-field__native
      min-height: 26px
      padding-top: 1px

  &--autogrow
    .q-field__native
      resize: none

  &.q-field--dense
    .q-field__control, .q-field__native
      min-height: 36px
    .q-field__native
      padding-top: 9px

    &.q-field--labeled
      .q-field__control-container
        padding-top: 14px
      .q-field__shadow
        top: 14px
      .q-field__native
        min-height: 24px
        padding-top: 3px
      .q-field__prefix, .q-field__suffix
        padding-top: 2px

body.mobile .q-textarea,
.q-textarea.disabled
  .q-field__native
    resize: none
