$chip-height: 2em

.q-chip
  vertical-align: middle
  border-radius: 16px
  outline: 0
  position: relative

  height: $chip-height
  max-width: 100%
  margin: 4px

  background: #e0e0e0
  color: rgba(0,0,0,.87)

  font-size: 14px
  padding: .5em .9em

  &--colored, &--dark
    .q-chip__icon
      color: inherit

  &--outline
    background: transparent !important
    border: 1px solid currentColor

  .q-avatar
    font-size: $chip-height
    margin-left: -.45em
    margin-right: .2em
    border-radius: 16px

  &--selected
    .q-avatar
      display: none

  &__icon
    color: rgba(0,0,0,.54)
    font-size: 1.5em
    margin: -.2em

    &--left
      margin-right: .2em
    &--right
      margin-left: .2em
    &--remove
      margin-left: .1em
      margin-right: -.5em
      opacity: .6
      outline: 0

      &:hover,
      &:focus
        opacity: 1

  &__content
    white-space: nowrap

  &--dense
    border-radius: 12px
    padding: 0 .4em
    height: 1.5em

    .q-avatar
      font-size: 1.5em
      margin-left: -.27em
      margin-right: .1em
      border-radius: 12px

    .q-chip__icon
      font-size: 1.25em
      &--left
        margin-right: .195em
      &--remove
        margin-right: -.25em

  &--square
    border-radius: $generic-border-radius
    .q-avatar
      border-radius: ($generic-border-radius - 1px) 0 0 ($generic-border-radius - 1px)

body.desktop
  .q-chip--clickable:focus
    box-shadow: $shadow-1
  &.body--dark
    .q-chip--clickable:focus
      box-shadow: $dark-shadow-1
