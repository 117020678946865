.q-rating
  color: $rating-grade-color
  vertical-align: middle

  &__icon-container
    height: 1em
    outline: 0

    & + &
      margin-left: 2px

  &__icon
    color: currentColor
    text-shadow: $rating-shadow
    position: relative
    opacity: .4
    transition: transform .2s ease-in, opacity .2s ease-in

    &--hovered
      transform: scale(1.3)

    &--active
      opacity: 1
    &--exselected
      opacity: .7

  &--no-dimming .q-rating__icon
    opacity: 1

  &--editable .q-rating__icon-container
    cursor: pointer
